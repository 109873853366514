import cx from 'classnames';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import styles from './index.styl';

function GradientButton({ children, theme }) {
  const {
    controls: {
      gradientButton: {
        type,
        background,
      },
    },
  } = theme;

  return (
    <button className={cx(styles.button, styles['_' + type])} type='button'>
      <style jsx>{`
        .${styles.button}
          color #fff
          position relative
          outline none
          border none
          margin 0
          background transparent
          cursor pointer
          border-radius 8px

        .${styles.border}
           &:before
             background-image ${background}

          .${styles._filled}
            background-image ${background}
      `}</style>
      {type === 'bordered' && <div className={styles.border} />}
      <div className={styles.content}>
        {children}
      </div>
    </button>
  );
}

GradientButton.propTypes = {
  theme: themePropTypes(`{
    controls: {
      gradientButton: {
        type,
        background,
      }
    }
  }`),
};

export default withTheme(GradientButton);
