import cx from 'classnames';

import styles from './index.styl';
import PropTypes from 'prop-types';

export default function Rating({ rating, className }) {
  if (!rating) return null;

  return <span className={cx(styles.rating, className)}>{rating}</span>;
}

Rating.propTypes = {
  /** Класс для дополнительной стилизации */
  className: PropTypes.string,
  /** Значение рейтинга */
  rating: PropTypes.number,
};
