
import resolve from 'core/resolver/resolve';
import { topicFetcher } from 'core/fetchers/topic';

import { denormalizeData } from 'core/utils/api';

import topicRelationships from './topicRelationships';


export async function fetchMovies(topic, bebopApi) {
  const { widgets } = topicRelationships(topic).content;

  const teaserTopicIds = widgets
    .filter(widget => widget.type === 'smartTeaser')
    .map(teaser => teaser.attributes.teaser_params.topic_id);

  if (!teaserTopicIds.length) return [];

  const movies = await bebopApi.getTopics({
    topic_type: 'movie',
    fields: 'headline,source_url',
    include: 'image,movie_meta',
    ids: teaserTopicIds.join(','),
  })
    .then(denormalizeData)
    .catch(() => []);

  // Может случиться, что тизер с id фильма пришёл,
  // а самого фильма с этим id в апи нет. Это приведёт
  // к рассинхрону нумерации между оглавлением и телом топика.
  // Чтобы этого избежать, формируем массив с
  // null на местах несуществующих фильмов.

  return teaserTopicIds.map(id => {
    return movies.find(movie => id === movie.id) || null;
  });
}


export default resolve({
  topicWithMovies: async props => {
    const topic = await topicFetcher()(props);

    if (!topic) return {};

    const positionSyncedMovies = await fetchMovies(topic, props.bebopApi);

    return {
      topic,
      movies: positionSyncedMovies,
    };
  },
});
