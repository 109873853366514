import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import Read from './components/Read';
import Watch from './components/Watch';
import ReadAndWatchDesktopWrapper from './Wrappers/Desktop';
import ReadAndWatchMobileWrapper from './Wrappers/Mobile';


function ReadAndWatch(props) {
  const {
    selections,
    movies,
    news,
    topics,
    isMobile,
  } = props;

  const Wrapper = isMobile ? ReadAndWatchMobileWrapper : ReadAndWatchDesktopWrapper;

  return (
    <Wrapper
      readElement={<Read news={news} topics={topics} />}
      watchElement={<Watch selections={selections} movies={movies} />}
    />
  );
}

ReadAndWatch.propTypes = {
  selections: PropTypes.array,
  movies: PropTypes.array,
  news: PropTypes.array,
  topics: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(ReadAndWatch);
