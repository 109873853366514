import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';

import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';
import PlayButtonIcon from 'site/icons/PlayButton.svg';
import styles from './index.styl';


const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'headline',
  'list_headline',
  'alternative_headline',
  'source_url',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function CardPlaylist(props) {
  const {
    content,
    theme,
    imageMaxWidth,
    imageRatio,
  } = props;

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    headline,
    alternative_headline: altHeadline,
    list_headline: listHeadline,
    source_url: sourceUrl,
  } = content.attributes;


  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          max-width ${imageMaxWidth}px
          background-color ${theme.colors.content}
      `}</style>
    </scope>
  );

  return (
    <div className={styles.wrapper}>
      <style jsx>{`
        .${styles.img}
          :global(.desktop) &
            transition ${theme.animations.scale}

        .${styles.headline}
        .${styles.altHeadline}
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}
          :global(.${styles.link}:hover) &
            color ${theme.colors.hoverHeadlineColor}

      `}</style>
      <Link
        to={sourceUrl ?? ''}
        type='secondary'
        className={scoped.wrapClassNames(styles.link)}
      >
        {originalCover && (
          <div className={styles.imageWrapper}>
            <PlayButtonIcon
              width={40}
              height={40}
              className={styles.playButtonIcon}
            />
            <div className={styles.img}>
              <SmartImage
                src={originalCover}
                previewSrc={previewCover}
                maxWidth={imageMaxWidth}
                aspectRatio={imageRatio}
              />
            </div>
          </div>
        )}

        <div className={styles.content}>
          <div className={styles.headline}>
            {listHeadline || headline}
          </div>
          {altHeadline && (
            <div className={styles.altHeadline}>
              {altHeadline}
            </div>
          )}
        </div>
      </Link>
      <scoped.styles />
    </div>
  );
}

CardPlaylist.propTypes = {
  /** Данные для карточки */
  content: modelPropTypes({
    headline: PropTypes.string,
    list_headline: PropTypes.string,
    alternative_headline: PropTypes.string,
    source_url: PropTypes.string,
  }),
  /** @ignore */
  theme: PropTypes.object,
  /** Ограничиваем размер картинки по ширине. */
  imageMaxWidth: PropTypes.number,
  /** Устанавливает соотношение сторон изображения */
  imageRatio: PropTypes.number,
};

CardPlaylist.defaultProps = {
  imageMaxWidth: 320,
  imageRatio: 2,
};

const Card = skip(withTheme(CardPlaylist));

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;


export default Card;

export { CardPlaylist as StorybookComponent };
