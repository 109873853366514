import Gallery from 'core/components/Gallery';

import PropTypes from 'prop-types';
import modelPropTypes, { photoGalleryAttributes } from 'core/utils/prop-types/model';


export default function PhotoGallery({ block, parentProps }) {
  const {
    isDesktop,
  } = parentProps;

  return (
    <Gallery
      photoGallery={block}
      disableReloadButton
      withSmoothHeightChange
      infoWithSideIndent={isDesktop}
      captionCreditsProps={{ creditsPrefix: '' }}
    />
  );
}

PhotoGallery.propTypes = {
  block: modelPropTypes(photoGalleryAttributes).isRequired,
  parentProps: PropTypes.object,
};
