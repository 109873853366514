import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import Link from 'core/components/Link';

import Okko from 'site/icons/Okko';

import styles from './index.styl';


function Shapka({ isMobile, theme }) {
  return (
    <div className={styles.shapka}>
      <style jsx>{`
        .${styles.age}
          font-family ${theme.fonts.likhie}
      `}</style>
      <Link
        type='blank'
        to='https://okko.tv'
        className={styles.logo}
      >
        <Okko width={isMobile ? 61 : 100} height={isMobile ? 22 : 36} />
      </Link>
      <div className={styles.company}>
        ООО “ОККО”, Г. САНКТ-ПЕТЕРБУРГ, {isMobile && <br />}ОГРН 1167847381130
      </div>
      <div className={styles.age}>18<div className={styles.plus}>+</div></div>
    </div>
  );
}

Shapka.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(Shapka));
