import PropTypes from 'prop-types';

import SocializatorCore from 'core/components/Socializator';
import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import styles from './index.styl';

const socializatorCore = <SocializatorCore buttons={['vkontakte', 'odnoklassniki', 'whatsapp', 'telegram', 'twitter', 'email', 'copy']} />;

function Socializator({ theme, isMobile, withoutDivider }) {
  const {
    colors: {
      grey1,
      grey2,
    },
    controls: {
      socializator: {
        iconsGap,
      },
    },
  } = theme;

  if (isMobile) {
    return socializatorCore;
  }

  return (
    <div className={styles.socializatorWrapper}>
      <style jsx>{`
        .${styles.socializatorWrapper}
          border-top ${withoutDivider ? 'none' : `1px solid ${grey1}`}
        .shareTitle
          color ${grey2}
          margin-right ${iconsGap}px
        `}</style>
      <span className='shareTitle'>Поделиться:</span>
      {socializatorCore}
    </div>
  );
}

Socializator.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Флаг, определяющий отображение разделителя */
  withoutDivider: PropTypes.bool,
};

const SocializatorWithHOCs = withTheme(withBreakpoint(Socializator));
SocializatorWithHOCs.displayName = 'Socializator';

export default SocializatorWithHOCs;

export { Socializator as StorybookComponent };
