import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import Button from '../Button';

import man1 from '../images/man1.jpg';
import man2 from '../images/man2.jpg';

import styles from './index.styl';

const interviews = [
  {
    id: 1,
    image: man1,
    description: 'Фотосессия и интервью Артёма Быстрова для журнала «Правила жизни». Поговорили с актёром о съёмках и не только',
    link: 'https://www.pravilamag.ru/hero/734151-menya-pret-kogda-ya-na-takih-oborotah-rabotayu-intervyu-s-akterom-artemom-bystrovym',
    photoBy: 'Фото: Анастасия Орлова',
  },
  {
    id: 2,
    image: man2,
    description: 'Режиссёр Юрий Быков рассказал журналу «КиноРепортер» про романтизацию бандитизма, борьбу с собственным тщеславием и приоритеты в любви.',
    link: 'https://kinoreporter.ru/yuri-bykov-interview',
    photoBy: 'Фото: Катерина Козленко',
  },
];

function PhotoInterview({ theme }) {
  return (
    <div className={styles.photoInterview}>
      <style jsx>{`
        .${styles.toRead}
          font-family ${theme.fonts.likhie}
      `}</style>
      {interviews.map(({ id, image, description, link, photoBy }) => (
        <div key={id} className={styles.wrapper}>
          <img
            src={image}
            className={styles.image}
            alt={`Интервью с ${description}`}
          />
          <div className={styles.info}>
            <div className={styles.description}>{description}</div>
            <div className={styles.photoBy}>{photoBy}</div>
            <Button link={link} size='s'>
              Читать материал
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}
PhotoInterview.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(PhotoInterview);
