import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad/Adfox';

export const Inpage = bindProps({
  name: 'Inpage',
  ownerId: 1511503,
  params: {
    pp: 'g',
    ps: 'gyle',
    p2: 'iqvc',
  },
})(Ad);

export const Fullscreen = bindProps({
  name: 'Fullscreen',
  ownerId: 1511503,
  params: {
    p1: 'czxfy',
    p2: 'iqvh',
  },
})(Ad);
