import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint, Desktop } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';
import { Indent } from 'core/components/Wrappers';

import TopicToplineSkeleton from 'core/components/TopicTopline/Skeleton';
import TopicHeaderTextsSkeleton from 'core/components/TopicHeaderTexts/Skeleton';
import SocializatorSkeleton from 'core/components/Socializator/Skeleton';

import { NarrowWrapper } from 'site/components/Wrappers';

import styles from './index.styl';


function Common({ isMobile, theme }) {
  const {
    colors: {
      divider: dividerColor,
    },
  } = theme;
  const indent = isMobile ? 30 : 24;

  return (
    <NarrowWrapper>
      <style jsx>{`
        .${styles.socializator}
          border-top 1px solid ${dividerColor}
      `}</style>
      <TopicToplineSkeleton height={isMobile ? 22 : 26} />
      <Indent top={indent} />
      <TopicHeaderTextsSkeleton
        titleHeight={isMobile ? 50 : 80}
        leadHeight={isMobile ? 100 : 150}
      />
      <Indent top={indent} />
      <Desktop>
        <div className={styles.socializator}>
          <div className={cx('lb-skeleton', styles.subscribe)} />
          <SocializatorSkeleton />
        </div>
      </Desktop>
      <Indent bottom={isMobile ? 24 : 36}>
        <div className={cx('lb-skeleton', styles.cover)} />
      </Indent>
      <div className={cx('lb-skeleton', styles.text)} />
    </NarrowWrapper>
  );
}

Common.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(Common));
