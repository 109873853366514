import { host } from 'site/constants';

const likhieImage = require('../images/title.png');

const ogImage = `https://${host}/icons/og-image-likhie.jpg`;

const webPageSchema = {
  '@context': 'https://schema.org',
  '@type': 'NewsArticle',
  'mainEntityOfPage': {
    '@type': 'WebPage',
    '@id': 'https://blog.okko.tv/likhie',
  },
  'headline': 'Лихие 90-е. Главная драма страны',
  'image': [
    `https://${host}/${likhieImage}`,
  ],
  'datePublished': '2024-10-30T15:48:31+03:00',
  'dateModified': '2024-10-30T15:48:31+03:00',
  'author': {
    '@type': 'Person',
    'name': 'ОККОЛОКИНО',
  },
  'publisher': {
    '@type': 'Organization',
    'name': 'ОККОЛОКИНО',
    'logo': {
      '@type': 'ImageObject',
      'url': ogImage,
    },
  },
  'description': 'Хабаровский край. Павел Лиховцев, старший егерь и охотник на медведей, держит в тайге пасеку и воспитывает старшего сына Женьку настоящим мужиком. Но с приходом девяностых пасека разоряется. Павел с семьёй вынужден ехать в Хабаровск в поисках лучшей жизни.\n\nВ это время в Хабаровске вся власть и все ресурсы переходят к ОПГ «Общак» — банде, которая вскоре станет крупнейшей криминальной группировкой в мире.\n\nУстроившись на работу водителем к Юре Крабу, бывшему соседу по посёлку и крёстному Женьки, Павел быстро попадает в эпицентр бандитских разборок.\n\nДвенадцатилетний Женя восхищается отцом, полностью ему доверяет и с открытым ртом глядит на крутых хозяев жизни — братков. Взрослея, Женя вливается в ОПГ Краба, который решился бросить вызов всесильному лидеру «Общака», и готов снова начать криминальную войну на Дальнем Востоке.',
};

const webPageSchemaJSON = JSON.stringify(webPageSchema);

export default webPageSchemaJSON;
