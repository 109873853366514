import { getEnv, resolve } from 'core/utils/env';

import { host } from 'site/constants';

export default (apiParams = {}) => ({ bebopApi }) => {
  const externalBebopApi = resolve({
    '*': `https://${getEnv('SITE_HOST') || host}/external/okko/main`,
    'dev': 'https://s2.okkomedia.rambler.ru/external/okko/main',
  });

  return bebopApi
    .getExternal(externalBebopApi, apiParams)
    .catch(() => ({}));
};
