import PropTypes from 'prop-types';

function modelPropTypes(attributesType, extraTypes) {
  return PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string.isRequired,
    attributes: attributesType.isRequired,
    ...!!extraTypes && {
      extra: extraTypes.isRequired,
    },
  });
}

export const movieAttributes = {
  title: PropTypes.string,
  link: PropTypes.string,
  rating: PropTypes.number,
  releaseDate: PropTypes.number,
  duration: PropTypes.number,
  seasonsCount: PropTypes.number,
  episodesCount: PropTypes.number,
  genres: PropTypes.arrayOf(PropTypes.string),
  price: PropTypes.number,
  coverUrls: PropTypes.shape({
    landscape: PropTypes.string,
    portrait: PropTypes.string,
  }),
};

export const tvChannelAttributes = {
  title: PropTypes.string,
  link: PropTypes.string,
  coverUrls: PropTypes.shape({
    logo: PropTypes.string,
  }),
};

export const collectionAttributes = {
  title: PropTypes.string,
  link: PropTypes.string,
  coverUrls: PropTypes.shape({
    logo: PropTypes.string,
  }),
};

export const okkoFeedAttributes = PropTypes.shape({
  movies: PropTypes.shape({
    freeMovies: PropTypes.arrayOf(modelPropTypes(movieAttributes)),
    freeSeries: PropTypes.arrayOf(modelPropTypes(movieAttributes)),
    shopPopulars: PropTypes.arrayOf(modelPropTypes(movieAttributes)),
  }),
  tvChannels: PropTypes.arrayOf(modelPropTypes(tvChannelAttributes)),
  collections: PropTypes.arrayOf(modelPropTypes(collectionAttributes)),
});

export default modelPropTypes;
