import alexander from '../images/alexander.jpg';
import artur from '../images/artur.jpg';
import artem from '../images/artem.jpg';
import saveliy from '../images/saveliy.jpg';

const heroesData = [
  {
    id: 1,
    image: artur,
    fullName: 'Артур\nИванов',
    aboutHero: 'Евгений Васин (он же Джем) родился 10 ноября 1951 года. Семья Васина рано переехала в Комсомольск-на-Амуре. Рос Евгений без отца. Уже в 15 лет Васин получил два года колонии за драку.\n\nВ 1973-м Евгений снова сел в колонию. Теперь за избиение милиционера, но уже на пять лет. Тогда же он получил своё прозвище Джем — производное от Евгений — Жека — Джек — Джем. В 1987-м году Васин вернулся в Комсомольск-на-Амуре уже в статусе «вора в законе». Там он и начинает объединять людей вокруг себя.\n\nДжем поделил город на зоны, чтобы лучше контролировать население и собирать с них деньги в общак. Васин первый придумал организовывать лагеря для несовершеннолетних, с целью растить будущих членов ОПГ.',
  },
  {
    id: 2,
    image: alexander,
    fullName: 'Александр\nГолубев',
    aboutHero: 'Юрий Масленников по кличке «Краб». Бывший учитель физкультуры в 2001 году стал «смотрящим» за Хабаровском по указу «Джема». Предыдущего «смотрящего» за городом убили, когда он ехал в своём автомобиле.\n\nВ 2004-м члены ОПГ обвинили Масленникова в том, что он не всегда выполнял воровские поручения и стал меньше денег отдавать в «общак». «Краба» сместили с его должности, что положило начало большого конфликта в ОПГ «Общак».\n\nЛюдей, которые поддержали Масленникова, стали называть «крабовскими». Они начали большой конфликт с «Общаком», в результате которого многих сторонников «Краба» убили или ограбили',
  },
  {
    id: 3,
    image: artem,
    fullName: 'Артём\nБыстров',
    aboutHero: 'Павел Лиховцев егерь в Хабаровском крае. В 1991-м он по приглашению своего друга Юрия Масленникова переезжает в город. Там Лиховцев надеется построить лучшую жизнь для своей семьи.\n\nВ Хабаровске Лиховцев начинает сотрудничать с местной ОПГ, хотя сам этого не желает. Впервые Павел помогает «Общаку» с атакой на спортсменов. Он привозит бандитов к месту разборки. Но в результате нападения один из спортсменов спасается бегством.\n\nПавлу в «общаке» приказывают убить беглеца. Тогда Лиховцев решает привлечь к этому одного из своих детей, старшего Женю. Больше бывшему егерю довериться некому, а ему нужен надежный напарник. Павел воспитывает в ребенке мужчину и учит его тому, что мир несправедлив, а за счастье надо бороться. Иногда даже такими ужасными способами',
  },
  {
    id: 4,
    image: saveliy,
    fullName: 'Савелий\nКудряшов',
    aboutHero: 'Маленький Женя вместе с семьёй переезжает в Хабаровск. На дворе лихие 90-е, и город не встретил Женю с его братом добротой.\n\nНад ребятами уже в первые дни начинают издеваться учащиеся их школы. А отец, на просьбы помочь требует их защищать друг друга.\n\nВскоре Женя сближается с другом семьи и его крестным Юрием «Крабом» Масленниковым. Мальчик впитывает взгляды на мир и жестокость старшего товарища и сам желает стать членом крупнейшей ОПГ.',
  },
];

export default heroesData;
