import Indent from 'core/components/Wrappers/Indent';

import styles from '../index.styl';

function MailingRules() {
  return (<>
    <p>Вступили в силу 22 мая 2023 года.</p>
    <Indent bottom={40} >
      <i>Настоящие правила рассылок (Правила) определяют общие условия рассылки Сообщений Пользователям, осуществляемой Провайдером и/или Партнерами.</i>
    </Indent>
    <ol>
      <li>
        <h3 className={styles.subtitle}>Термины</h3>
        <ol>
          <li><b>Провайдер</b> – юридическое лицо, предоставляющее Пользователям Ресурс. Наименование Провайдера размещено на Ресурсе, на котором Пользователь совершает Подписку на рассылку.</li>
          <li><b>Ресурс</b> – сайт, проект, сервис и/или иной продукт в сети Интернет, предоставляемый Провайдером. </li>
          <li><b>Пользователь</b> – физическое лицо, использующее Ресурс и обладающее необходимой дееспособностью для принятия Правил.</li>
          <li><b>Сообщения</b> – информационные сообщения, в том числе новостного и/или рекламного характера, связанные с Ресурсом и/или тематикой Ресурса, а также с товарами/услугами Провайдера и/или Партнеров.</li>
          <li><b>Подписка на рассылку</b> – действия Пользователя при использовании Ресурса, направленные на систематическое получение Сообщений от Провайдера и/или Партнеров.</li>
          <li><b>Партнеры</b> – третьи лица, выступающие в качестве поставщиков товаров/услуг, информация о которых доступна посредством Ресурсов</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Действие правил</h3>
        <ol>
          <li>Правила являются публичной офертой в соответствии со ст. 437 Гражданского кодекса РФ. Подписка на рассылку означает полный и безоговорочный акцепт Правил Пользователем.</li>
          <li>В случае несогласия Пользователя с каким-либо из положений Правил, Пользователь обязан воздержаться от Подписки на рассылку.</li>
          <li>Правила являются неотъемлемой частью Условий использования Ресурса.</li>
          <li>Правила и отношения Пользователя и Провайдера и/или Партнеров, возникающие в связи с Подпиской на рассылку, регулируются законодательством РФ.</li>
          <li>Правила могут быть изменены Провайдером в одностороннем внесудебном порядке. Анонсирование изменений и публикация измененных Правил производятся на Ресурсе. Используя Ресурс и/или продолжая получать Сообщения после изменения Правил, Пользователь принимает Правила полностью и безоговорочно в измененном виде.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Условия рассылки сообщений</h3>
        <ol>
          <li>
            <p>Принимая Правила, Пользователь выражает свое согласие на получение Сообщений от Провайдера и/или Партнеров по адресу электронной почты, указанному Пользователем при Подписке на рассылку.</p>
            <ol>
              <li>В случае, если при использовании Ресурса Пользователь указывает информацию о номере его мобильного телефона, Пользователь выражает свое согласие на получение SMS-Сообщений от Провайдера и/или Партнеров по указанному номеру.</li>
            </ol>
          </li>
          <li>Если при осуществлении Подписки на рассылку Пользователь предоставляет персональные данные, то принимая условия Правил, Пользователь дает согласие Провайдеру и Партнерам на обработку (включая сбор, запись, систематизацию, накопление, хранение, уточнение, извлечение, использование, предоставление, доступ, обезличивание, блокирование, удаление и уничтожение) предоставленных персональных данных в целях получения Сообщений и продвижения Ресурсов, продуктов (услуг) Провайдера и Партнеров. Согласие действует с момента предоставления данных Пользователем до достижения целей их обработки и может быть отозвано путем направления Провайдеру письменного заявления.</li>
          <li>Провайдер исходит из того, что Подписка на рассылку осуществляется непосредственно Пользователем, а также, что адрес электронной почты и, если применимо, номер мобильного телефона, указанные Пользователем при Подписке на рассылку, являются корректными и принадлежат Пользователю. Ответственность за правомерность предоставления сведений при Подписке на рассылку и их достоверность несет исключительно Пользователь.</li>
          <li>Пользователь вправе в любое время отказаться от получения Сообщений путем перехода по соответствующей гиперссылке, содержащейся в Сообщении.</li>
        </ol>
      </li>
    </ol>
  </>
  );
}

export default MailingRules;
