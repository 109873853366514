import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import Button from '../Button';

import irina from '../images/irina.jpg';
import anton from '../images/anton.jpg';

import styles from './index.styl';

const interviews = [
  {
    id: 1,
    image: irina,
    actor: 'Ирина Смелая',
  },
  {
    id: 2,
    image: anton,
    actor: 'Антон Токарев',
  },
];


function Interview({ theme }) {
  return (
    <div className={styles.interview}>
      <style jsx>{`
        .${styles.blockTitle}
        .${styles.toRead}
          font-family ${theme.fonts.likhie}
      `}</style>

      <div className={styles.blockTitle}>
        <div className={styles.stars}>
          Знаменитости <br />про тяжёлое
        </div>
        <div className={styles.childhood}>детство</div>
      </div>

      <div className={styles.people}>
        <div className={styles.article}>
          Знаменитые музыканты сравнили своё детство с детством главного героя сериала «Лихие»
        </div>
        <div className={styles.actors}>
          {interviews.map(({ image, actor, id }) => (
            <div key={id} className={styles.wrapper}>
              <div className={styles.imageWrapper}>
                <img
                  src={image}
                  className={styles.image}
                  alt='actor'
                />
                <div className={styles.actor}>{actor}</div>
              </div>
              <Button size='s' link='https://srsly.ru/article/show/36822'>
                Читать материал
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

Interview.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(Interview);
