import PropTypes from 'prop-types';

import CoreLink from 'core/components/Link';

export default function Link({ to, target, encode, ...otherProps }) {
  const encodedLink = encode ? `/redirect/${btoa(to)}` : to;
  const isToOkko = /^(http|https):\/\/okko.tv/.test(to);

  return (
    <CoreLink
      title={to}
      {...(encode || target) && { target: encode ? '_blank' : target }}
      {...!otherProps.followExternal && encode && { rel: 'nofollow' }}
      to={encodedLink}
      {...otherProps}
      followExternal={isToOkko || otherProps.followExternal}
    />
  );
}


Link.propTypes = {
  /**
   * Этот параметр меняет поведение ссылки.
   * Ссылка открывается в новом окне и перебрасывает через редирект.
   * Используется для того, чтобы блокировщик рекламы
   * не резал ссылки на другие ресурсы и контент в них
   */
  encode: PropTypes.bool,
  /** Атрибут target базового компонента ссылки */
  target: PropTypes.string,
  /** Адрес перехода при клике на ссылку */
  to: PropTypes.string.isRequired,
  /** @ignore */
  children: PropTypes.node,
};
