import PropTypes from 'prop-types';
import Poster from './Poster';
import styles from './index.styl';

function Overlay(props) {
  const {
    content,
    isMobile,
  } = props;

  if (!content) return null;

  const maxCount = isMobile ? 9 : 12;

  const items = content.reduce((result, item) => {
    return result.concat(item.topics);
  }, []);
  return (
    <div className={styles.overlay}>
      {items.slice(0, maxCount).map((item, index) => (
        <Poster
          content={item}
          key={index}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
}

Overlay.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
  isMobile: PropTypes.bool,
};

export default Overlay;
