import PropTypes from 'prop-types';
import cx from 'classnames';

import bindProps from 'core/components/bindProps';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import Link from 'core/components/Link';
import Lightning from 'core/components/GameCompare/Lightning';

import { topicDateFormat } from 'core/utils/dates';

import mapTypes from './types';

import styles from './index.styl';


const requiredPayloadFields = [
  'link',
  'headline',
  'listHeadline',
  'published_at',
];

const requiredPayloadImports = [
  'content',
];
const relationships = resolveRelationships(requiredPayloadImports, {}, {
  content: {},
});

const sizes = {
  l: {
    imageMaxWidth: 582,
    height: 387,
  },
  m: {
    imageMaxWidth: 550,
    height: 213,
  },
  s: {
    imageMaxWidth: 437,
    height: 252,
  },
  xs: {
    imageMaxWidth: 392,
    height: 215,
  },
};


function CompareCard(props) {
  const {
    content,
    theme: {
      fonts,
      colors,
    },
    type,
    size,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    listHeadline,
    published_at: publishedAt,
  } = content.attributes;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  if (!widgets || widgets?.length === 0) return null;

  const {
    accented,
    vertical,
    progressType,
  } = mapTypes[type] || {};

  return (
    <div
      className={cx(
        styles.card,
        accented && styles._accented,
        vertical && styles._vertical,
        styles[`_size_${size}`])}
    >
      <style jsx>{`
        .${styles.card}
          background-color ${colors.layout}
          &.${styles._accented}
            background-color #321069

        .${styles.headline}
          font-family ${fonts.display}
          color ${colors.primary}
          .${styles.card}:hover &
            color ${colors.active1}
          .${styles._accented} &
            color ${colors.primary}

        .${styles.date}
          font 400 12px/15px ${fonts.display}
          color ${colors.grey2}
          .${styles._accented} &
            color ${colors.primary}
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={styles.wrapper}
      >
        <Lightning
          widget={widgets[0]}
          imageMaxWidth={sizes[size].imageMaxWidth}
          height={sizes[size].height}
          progressType={progressType}
          borderRadius='8px'
          lightningColor={colors.primary}
          progressInside
        />
        <div className={styles.body}>
          <div className={styles.titles}>
            <time className={styles.date}>
              {topicDateFormat(publishedAt)}
            </time>
            {(listHeadline || headline) && (
              <div className={styles.headline}>
                {listHeadline || headline}
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}

CompareCard.propTypes = {
  /** Данные для карточки */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  theme: PropTypes.object,
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2, 3, 4]),
  /** Размер карточки */
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
};

CompareCard.defaultProps = {
  type: 0,
  size: 'l',
};

const Card = skip(withTheme(withBreakpoint(CompareCard)));

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

// desktop
export const CompareCardL = bindProps({ type: 0, size: 'l' })(Card);
export const CompareCardAccentedL = bindProps({ type: 1, size: 'l' })(Card);
export const CompareCardVerticalS = bindProps({ type: 2, size: 's' })(Card);

// mobile
export const CompareCardAccentedVerticalM = bindProps({ type: 3, size: 'm' })(Card);
export const CompareCardVerticalM = bindProps({ type: 2, size: 'm' })(Card);

// leaderBoard
export const CompareCardS = bindProps({ type: 4, size: 'xs' })(Card);

export default Card;

export { CompareCard as StorybookComponent };
