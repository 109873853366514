import PropTypes from 'prop-types';

import WideWrapper from 'site/components/Wrappers/WideWrapper';

import DesktopWrapper from '../Wrappers/Desktop';
import MobileWrapper from '../Wrappers/Mobile';

import ReadSkeleton from '../components/Read/Skeleton';
import WatchSkeleton from '../components/Watch/Skeleton';


const wrapperMap = {
  mobile: (props) => (
    <WideWrapper>
      <MobileWrapper {...props} />
    </WideWrapper>
  ),
  default: DesktopWrapper,
};

function ReadAndWatchSkeleton({ breakpoint }) {
  const Wrapper = wrapperMap[breakpoint] || wrapperMap.default;

  return (
    <Wrapper
      readElement={<ReadSkeleton />}
      watchElement={<WatchSkeleton />}
    />
  );
}

ReadAndWatchSkeleton.propTypes = {
  breakpoint: PropTypes.string,
};

export default ReadAndWatchSkeleton;
