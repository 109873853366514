import React, {  useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ModalCore from 'core/components/Modal';
import Link from 'core/components/Link';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { getCurrentWord, checkCrosswordCompleted } from '../utils';

import Close from '../../images/Close';

import styles from './index.styl';


export default function Modal(props) {
  const {
    isModalOpen,
    handleClose,
    setIsModalOpen,
    currentQuestionIndex,
    data,
    crosswordData,
    currentUserAnswer,
    isCorrectAnswer,
    setCurrentUserAnswer,
    setCrosswordData,
    setCorrectAnswer,
    modalRef,
    setIsAllAnswersFilled,
    isAllAnswersFilled,
    setCurrentQuestionIndex,
  } = props;

  const scopedStyles = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .modalOverlay
          background-color transparent
        .modalWindow
          background-color transparent
          max-width 670px
          width 100%
          box-sizing border-box
          overflow visible
          :global(.mobile) &
            max-width 80%
      `}</style>
    </scope>
  );

  const inputRefs = useRef([]);
  const isAnswerDisabled = !currentUserAnswer.length || currentUserAnswer.some(answer => !answer);

  // Находим первую пустую ячейку и перемещаем фокус
  useEffect(() => {
    if (isModalOpen) {
      const firstEmptyIndex = currentUserAnswer.findIndex((answer) => !answer);

      const focusIndex = firstEmptyIndex === -1 ? inputRefs.current.length - 1 : firstEmptyIndex;

      inputRefs.current[focusIndex]?.current?.focus();
    }
  }, [isModalOpen, currentUserAnswer]);

  // Устанавливаем кол-во inputRefs столько, сколько букв в ответе
  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, data[currentQuestionIndex]?.answer.length || 0);
  }, [currentQuestionIndex, data]);

  // Верные/неверные ответы пользователя
  const handleAnswer = () => {
    const {
      start: {
        row,
        col,
      },
      answer,
      direction,
    } = data[currentQuestionIndex];
    const userAnswer = currentUserAnswer.join('').toUpperCase();

    if (userAnswer === answer.toUpperCase()) {
      const updatedCrosswordData = [...crosswordData];

      let counter = 0;
      for (let i = 0; i < answer.length; i++) {
        const currentRow = direction === 'horizontal' ? row : row + i;
        const currentCol = direction === 'horizontal' ? col + i : col;
        updatedCrosswordData[currentRow][currentCol].letter = currentUserAnswer[counter];
        counter++;
      }

      setCrosswordData(updatedCrosswordData);
      setIsModalOpen(false);
      setCorrectAnswer(true);
      setCurrentUserAnswer([]);
      setCurrentQuestionIndex(null);

      const allFilled = checkCrosswordCompleted(crosswordData);
      setIsAllAnswersFilled(allFilled);
    } else {
      setCorrectAnswer(false);

      setTimeout(() => {
        setCorrectAnswer(null);
      }, 500);
    }
  };

  // Проверяем все ли ответы заполнены
  useEffect(() => {
    const isCompleted = checkCrosswordCompleted(crosswordData);
    setIsAllAnswersFilled(isCompleted);
  }, [crosswordData, setIsAllAnswersFilled]);

  const handleKeyDown = (event, currentIndex) => {
    const { key } = event;

    // Перемещение вправо
    if (key === 'ArrowRight' && currentIndex < inputRefs.current.length - 1) {
      inputRefs.current[currentIndex + 1]?.current?.focus();
      event.preventDefault();
    }

    // Перемещение влево
    if (key === 'ArrowLeft' && currentIndex > 0) {
      inputRefs.current[currentIndex - 1]?.current?.focus();
      event.preventDefault();
    }

    // Удаление и перемещение фокуса назад
    if (key === 'Backspace') {
      const newCurrentAnswer = [...currentUserAnswer];
      const word = getCurrentWord(data[currentQuestionIndex], crosswordData);

      // если буква отгадана ранее, ее нельзя удалить
      if (word[currentIndex]) {
        event.preventDefault();
        return;
      }

      if (currentUserAnswer[currentIndex]) {
        newCurrentAnswer[currentIndex] = '';
      } else if (currentIndex > 0 && !word[currentIndex - 1]) {
        inputRefs.current[currentIndex - 1]?.current?.focus();
        newCurrentAnswer[currentIndex - 1] = '';
      }

      setCurrentUserAnswer(newCurrentAnswer);
      event.preventDefault();
    }

    // Отправка ответа по Enter
    if (key === 'Enter' && !isAnswerDisabled) {
      handleAnswer();
      event.preventDefault();
    }
  };

  const handleInputChange = (value, currentIndex) => {
    const word = getCurrentWord(data[currentQuestionIndex], crosswordData);

    const newCurrentAnswer = [...currentUserAnswer];
    newCurrentAnswer[currentIndex] = word[currentIndex] ? word[currentIndex] : value.toUpperCase()[value.length - 1] || '';
    setCurrentUserAnswer(newCurrentAnswer);

    if (value.trim()) {
      const nextIndex = currentIndex + 1;
      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex]?.current?.focus();
      }
    }
  };

  useEffect(() => {
    if (isAllAnswersFilled) {
      setIsModalOpen(true); // Открываем модалку с промо, если все ответы заполнены
    }
  }, [isAllAnswersFilled, setIsModalOpen]);

  return (
    <ModalCore
      isOpen={isModalOpen}
      onClose={handleClose}
      scopedStyles={scopedStyles}
      closeIcon={Close}
    >
      <div
        className={cx(styles.modal, isCorrectAnswer === false && styles._inCorrectAnswer)}
        ref={modalRef}
      >
        {!isAllAnswersFilled && (
          <>
            <div className={styles.questionNumber}>{currentQuestionIndex}</div>
            <div className={styles.question}>{data[currentQuestionIndex]?.question}</div>
            <div className={styles.inputs}>
              {data[currentQuestionIndex]?.answer?.split('').map((_, answerIndex) => {
                const positions = data[currentQuestionIndex]?.start;

                let currentRow = positions.row;
                let currentCol = positions.col;
                if (data[currentQuestionIndex]?.direction === 'horizontal') {
                  currentCol += answerIndex;
                } else {
                  currentRow += answerIndex;
                }
                const value = crosswordData[currentRow][currentCol]?.letter || currentUserAnswer[answerIndex] || '';

                if (!inputRefs.current[answerIndex]) {
                  inputRefs.current[answerIndex] = React.createRef();
                }

                return (
                  <input
                    key={answerIndex}
                    type='text'
                    className={styles.input}
                    pattern='[A-Za-zА-Яа-я]'
                    value={value}
                    ref={inputRefs.current[answerIndex]}
                    onChange={(e) => handleInputChange(e.target.value, answerIndex)}
                    onKeyDown={(e) => handleKeyDown(e, answerIndex)}
                  />
                );
              })
              }
            </div>

            <button
              className={styles.toAnswer}
              onClick={() => handleAnswer()}
              disabled={isAnswerDisabled}
            >
              Ответить
            </button>
          </>
        )}
        {isAllAnswersFilled && (
          <>
            <div className={styles.title}>Отлично!</div>
            <div className={styles.text}>
              Ты помог Николаю Николаевичу и Полине понять друг друга.
              В благодарность, они дарят тебе промокод на просмотр сериала <Link to='https://okko.tv/serial/mamonty?utm_source=okkolokino&utm_medium=site&utm_campaign=mamont'>Мамонты</Link> в Okko:</div>
            <div className={styles.promo}>CROSSWORD</div>
            <Link to='https://okko.tv/serial/mamonty?utm_source=okkolokino&utm_medium=site&utm_campaign=mamont'>
              <button className={styles.toAnswer}>Ура! Бегу смотреть</button>
            </Link>
            <a
              className={styles.rulesLink}
              target='_blank'
              href='https://terms.okko.tv/PROMO_PRIME_60_MAMONTY_CROSSWORD_2024_12.pdf'
            >
              Правила акции
            </a>
          </>
        )}
      </div>
    </ModalCore>
  );
}

Modal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  setIsModalOpen: PropTypes.func,
  currentQuestionIndex: PropTypes.number,
  data: PropTypes.object,
  crosswordData: PropTypes.array,
  currentUserAnswer: PropTypes.array,
  inputRefs: PropTypes.object,
  isCorrectAnswer: PropTypes.bool,
  setCurrentUserAnswer: PropTypes.func,
  setCrosswordData: PropTypes.func,
  setCorrectAnswer: PropTypes.func,
  modalRef: PropTypes.object,
  theme: PropTypes.object,
  isAllAnswersFilled: PropTypes.bool,
  setIsAllAnswersFilled: PropTypes.func,
  setCurrentQuestionIndex: PropTypes.func,
};
