import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './index.styl';

const DictionaryItem = ({ word, definition, mark }) => {
  return (<>
    <dt className={styles.word}>{word}</dt>
    <dd className={cx(styles.definition, mark && styles[`${mark}`])}>{definition}</dd>
  </>
  );
};


DictionaryItem.propTypes = {
  word: PropTypes.string,
  definition: PropTypes.string,
  mark: PropTypes.string,
};

export default DictionaryItem;
