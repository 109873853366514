import isNotEmptyReactElementContent from 'core/utils/content';

import styles from './index.styl';

export default function H2Main({ children }) {
  if (isNotEmptyReactElementContent(children)) {
    return <h2 className={styles.h2Main}>{children}</h2>;
  }

  return null;
}
