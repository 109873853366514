import { host } from 'site/constants';

const likhieImage = require('../images/title.png');

const ogImage = `https://${host}/icons/og-image-likhie.jpg`;

const tvSeries = {
  '@context': 'https://schema.org',
  '@type': 'TVSeries',
  'name': 'Лихие',
  'description': 'Хабаровский край. Павел Лиховцев, старший егерь и охотник на медведей, держит в тайге пасеку и воспитывает старшего сына Женьку настоящим мужиком. Но с приходом девяностых пасека разоряется. Павел с семьёй вынужден ехать в Хабаровск в поисках лучшей жизни.',
  'image': `https://${host}/${likhieImage}`,
  'url': 'https://blog.okko.tv/likhie',
  'datePublished': '2024-10-30',
  'genre': ['Криминальная драма', 'Драма'],
  'creator': {
    '@type': 'Person',
    'name': 'Юрий Быков',
  },
  'aggregateRating': {
    '@type': 'AggregateRating',
    'ratingValue': '8.1',
    'bestRating': '10',
    'ratingCount': '13 795',
  },
  'actor': [
    {
      '@type': 'Person',
      'name': 'Артём Быстров',
    },
    {
      '@type': 'Person',
      'name': 'Егор Кенжаметов',
    },
    {
      '@type': 'Person',
      'name': 'Савелий Кудряшов',
    },
    {
      '@type': 'Person',
      'name': 'Евгений Ткачук',
    },
    {
      '@type': 'Person',
      'name': 'Елена Николаевна',
    },
    {
      '@type': 'Person',
      'name': 'Максим Карушев',
    },
    {
      '@type': 'Person',
      'name': 'Глеб Кулаков',
    },
    {
      '@type': 'Person',
      'name': 'Анна Завтур',
    },
    {
      '@type': 'Person',
      'name': 'Полина Максимова',
    },
  ],
  'productionCompany': {
    '@type': 'Organization',
    'name': 'ОККОЛОКИНО',
    'logo': {
      '@type': 'ImageObject',
      'url': ogImage,
    },
  },
  'trailer': {
    '@type': 'VideoObject',
    'name': 'Трейлер сериала «Лихие»',
    'embedUrl': 'https://vk.com/video-47393707_456244538',
    'thumbnailUrl': likhieImage,
    'description': 'Трейлер сериала «Лихие» о событиях 90-х годов.',
    'uploadDate': '2024-10-30T15:48:31+03:00',
  },
};

const tvSeriesJSON = JSON.stringify(tvSeries);

export default tvSeriesJSON;
