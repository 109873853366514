import styles from './index.styl';
import themeSwitcher from 'core/components/themeSwitcher';

function NoiseWrapper({ children }) {
  return (
    <div className={styles.layout}>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

export default themeSwitcher('dark')(NoiseWrapper);
