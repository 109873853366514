import { PropTypes } from 'prop-types';


function Redirect({ match, history, location }) {
  try {
    history.replace(atob(match.params.link));
  } catch {
    location.pathname = match.params.link || '/';
  }

  return null;
}

Redirect.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default Redirect;
