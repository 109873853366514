import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';

import GridFeed from 'core/components/GridFeed';

import { Indent } from 'core/components/Wrappers';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import H2Main from 'site/components/H2Main';
import WideWrapper from 'site/components/Wrappers/WideWrapper';

import CardTasty from 'site/cards/CardTasty';


const CardMobile = bindProps({
  imageMaxWidth: 480,
})(CardTasty);
const CardDesktop = bindProps({
  imageMaxWidth: 240,
})(CardTasty);


export default function Tasty(props) {
  const {
    topics,
    listTitle,
    isMobile,
  } = props;

  if (!topics.length) return null;

  const topicIndexWithSourceUrl = topics.findIndex(topic => topic.attributes.source_url);

  return (
    <>
      <H2Main>{listTitle}</H2Main>
      <Indent bottom={isMobile ? 20 : 30} />
      <WideWrapper>
        <GridFeed
          content={topics}
          card={isMobile ? CardMobile : CardDesktop}
          gap={isMobile ? '20px' : '30px'}
          itemsInlineStyle={{
            [topicIndexWithSourceUrl]: { display: 'flex', order: topics.length - 1 },
          }}
          {...!isMobile && { gridTemplateColumns: 'repeat(2, 1fr)' }}
        />
      </WideWrapper>
    </>
  );
}

Tasty.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  listTitle: PropTypes.string,
  isMobile: PropTypes.bool,
};
