import PropTypes from 'prop-types';
import withTheme from 'core/components/theme';
import TopicFooter from 'core/components/TopicFooter';
import Socializator from 'site/components/Socializator';
import styles from '../index.styl';
import SocialMedia from './SocialMedia';
import resolveRelationships from 'core/utils/relationships';

const relationships = resolveRelationships(...[
  ['social_network'],
  {},
  { tags: [], social_network: {} },
]);

function TopicFooterWrapper(props) {
  const {
    content,
    isQuiz,
  } = props;

  const {
    social_network: socialNetwork,
  } = relationships(content);


  if (isQuiz) {
    return (
      <div className={styles.quizFooter}>
        <Socializator withoutDivider />
      </div>
    );
  }

  return (
    <TopicFooter
      content={content}
      hrefBuilder={slug => `/label/${slug}`}
      disableComments
      disableAuthors
      CustomSocialMedia={socialNetwork.slug === 'telegram' ? SocialMedia : null}
    />
  );
}

TopicFooterWrapper.propTypes = {
  content: PropTypes.object,
  isQuiz: PropTypes.bool,
};

export default withTheme(TopicFooterWrapper);
