import { Fragment, useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import H3 from 'core/components/H3';
import Scroller from 'core/components/Scroller';
import { Indent } from 'core/components/Wrappers';
import { withBreakpoint } from 'core/components/breakpoint';
import ArrowRightRound from 'core/components/Icon/ArrowRightRound.svg';

import getAfisha from 'site/resolver/afisha';
import getOkko from 'site/resolver/okko';

import Collections from '../Collections';
import Afisha from '../Afisha';
import Lists from '../Lists';
import TvChannels from '../TvChannels';
import Skeleton from '../Skeleton';

import {
  CardExternalMovieType6,
  CardExternalMovieType7,
} from 'site/cards/CardExternalMovie';


import styles from '../index.styl';


function Render({ compactView, isDesktop }, { bebopApi }) {
  const [isOpened, setIsOpened] = useState(false);
  const [content, setContent] = useState({ afisha: [], okko: {} });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let ignore = false;
    Promise.allSettled([
      getAfisha()({ bebopApi }),
      getOkko()({ bebopApi }),
    ])
      .then(([afisha, okko]) => {
        if (!ignore) {
          setContent({
            afisha: afisha.value || [],
            okko: okko.value || {},
          });
          setLoading(false);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setLoading(false);
        }
      });

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Skeleton compactView={compactView} />;

  const {
    afisha,
    okko,
  } = content;

  const {
    tvChannels,
    collections,
    movies,
  } = okko || {};

  const {
    shopPopulars,
  } = movies || {};


  const PopularMoviesCard = isDesktop ? CardExternalMovieType6 : CardExternalMovieType7;

  if (compactView) {
    return (
      <div className={cx(styles.compact, { [styles.opened]: isOpened })}>
        <Lists movies={movies} compactView={compactView} />
        <Indent top={30} />
        <Collections collections={collections} />
        {shopPopulars?.length && (
          <Fragment>
            <Indent top={30} />
            <H3>Магазин популярных фильмов</H3>
            <Indent top={15} />
            <Scroller listClassName={styles.cards}>
              {shopPopulars.map(item => (
                <div key={item.id} className={styles.card}>
                  <PopularMoviesCard content={item} />
                </div>
              ))}
            </Scroller>
            <Indent bottom={30} />
          </Fragment>
        )}
        <Indent top={30} />
        <Afisha afisha={afisha} />
        <Indent top={30} />
        <TvChannels tvChannels={tvChannels} />
        {!isOpened && (
          <div className={styles.fade}>
            <ArrowRightRound
              width={20}
              height={20}
              className={styles.arrowIcon}
              onClick={() => setIsOpened(true)}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <Fragment>
      <Collections collections={collections} />
      <Indent top={30} />
      <Afisha afisha={afisha} />
      <Indent top={30} />
      <Lists movies={movies} />
      <Indent top={30} />
      <TvChannels tvChannels={tvChannels} />
    </Fragment>
  );
}

Render.propTypes = {
  compactView: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

Render.contextTypes = {
  bebopApi: PropTypes.object,
};

export default withBreakpoint(Render);
