export default {
  0: {
    progressType: 5,
  },
  1: {
    accented: true,
    progressType: 5,
  },
  2: {
    vertical: true,
    progressType: 6,
  },
  3: {
    vertical: true,
    accented: true,
    progressType: 6,
  },
  4: {
    progressType: 6,
  },
};
