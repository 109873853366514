import { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SmartImage from 'core/components/SmartImage';

import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

const relationships = resolveRelationships(
  ['image'], {}, { image: { versions: {} } }
);

function Poster(props) {
  const {
    content,
    isMobile,
  } = props;

  if (!content) return null;

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const {
    image: {
      versions: {
        portrait,
      },
    },
  } = relationships(content);

  const posterLeft = `${randomIntFromInterval(-20, 120)}%`;
  const posterTop = `${randomIntFromInterval(-20, 90)}%`;
  const blurFactor = `${randomIntFromInterval(2, 6)}px`;
  const timing = randomIntFromInterval(6, 12);
  const zoomFactor = randomIntFromInterval(
    0.5 * (isMobile ? 1 : 2),
    0.8 * (isMobile ? 1 : 3)
  );

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .layer
          position absolute
          width 100%
          height 100%
          @media (prefers-reduced-motion: no-preference)
            transform translateX(0%)
            animation run ${timing}s linear infinite
    
        .poster
          position absolute
          left ${posterLeft}
          top ${posterTop}
          filter blur(${blurFactor}) brightness(.5)

        @keyframes run
          0%
            opacity 0
            transform translateX(0%)
          10%,90%
            opacity 1
          100%
            opacity 0
            transform translateX(-30%)
      `}</style>
    </scope>
  );

  return (
    <div className={cx(scoped.wrapClassNames('layer'))}>
      <div className={cx(scoped.wrapClassNames('poster'))}>
        <SmartImage
          src={portrait}
          width={140 * zoomFactor}
          aspectRatio={0.7}
          instant
        />
      </div>
      <scoped.styles />
    </div>
  );
}

Poster.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default memo(Poster);
