import PropTypes from 'prop-types';
import cx from 'classnames';

import bindProps from 'core/components/bindProps';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { useFetchAndUpdateWidgets } from 'core/components/GameCompare/utils';

import { datePattern } from 'site/constants';
import { dateFormat  } from 'core/utils/dates';
import { imageVersionExists } from 'site/utils';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';

import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';
import Lightning from 'core/components/GameCompare/Lightning';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import { imageMaxWidthValidator } from 'site/utils/prop-types';

import styles from './index.styl';


const requiredPayloadImports = [
  'tags',
  'image',
  'rubric',
  'search_result',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'list_headline',
  'topic_type',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
  search_result: {
    title: '',
    body: '',
  },
});


function CardHorizontal(props, { bebopApi }) {
  const {
    content,
    size,
    theme,
    imageMaxWidth,
    imageRatio,
    imageVersion,
    imageWrapperWidth,
    lockImageWidth,
  } = props;

  const {
    link,
    headline,
    alternative_headline: altHeadline,
    published_at: publishedAt,
    list_headline: listHeadline,
  } = content?.attributes || {};

  const {
    image: {
      versions: {
        [imageVersion]: preCover,
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric,
    tags,
    search_result: {
      title: searchTitle,
      body: searchBody,
    },
  } = relationships(content) || {};

  const widgets = useFetchAndUpdateWidgets(bebopApi, content);

  const categoryText = (tags?.[0]?.attributes || rubric)?.title;
  const imgFlex = lockImageWidth ? `0 0 ${imageMaxWidth}px` : `1 0 ${imageWrapperWidth}`;
  const cover = imageVersionExists(preCover) ? preCover : originalCover;
  const withImage = (cover || widgets?.length > 0) && imageMaxWidth !== 0;

  return (
    <Link
      to={link}
      className={cx(
        styles.link,
        styles[`_size_${size}`],
      )}
      type='secondary'
    >
      <style jsx>{`
        .${styles.img}
          flex ${imgFlex}

        .${styles.body}
          color ${theme.colors.primary}

        .${styles.headline}
        .${styles.altHeadline}
          font-family ${theme.fonts.display}
          :global(.${styles.link}:hover) &
            color ${theme.colors.hoverHeadlineColor}
          :global(b)
            color ${theme.colors.accent}
        .${styles.rubric}
        .${styles.time}
        .${styles.tags}
          font 12px/15px ${theme.fonts.text}

        .${styles.rubric}
          color ${theme.colors.rubricColor}

        .${styles.time}
          color ${theme.colors.timeTextColor}

        .${styles.tags}
          color ${theme.colors.tagsTextColor}

        `}</style>
      {withImage && (
        <div className={styles.img}>
          {widgets?.length > 0 ?
            <Lightning
              imageMaxWidth={imageMaxWidth}
              widget={widgets[0]}
              progressType={4}
              lightningColor={theme.colors.primary}
              borderRadius='0'
              height={160}
            />
            :
            <SmartImage
              src={cover}
              previewSrc={previewCover}
              maxWidth={imageMaxWidth}
              aspectRatio={imageRatio}
            />
          }
        </div>
      )}
      <div className={styles.body}>
        <div className={styles.infoLine}>
          {publishedAt && <span className={styles.time}>{dateFormat(publishedAt, datePattern)}</span>}
          {categoryText && <span className={tags?.length > 0 ? styles.tags : styles.rubric}>{categoryText}</span>}
        </div>
        {(listHeadline || headline) && (
          <div className={styles.headline}>
            <MarkdownWrapper children={searchTitle || listHeadline || headline} inline />
          </div>
        )}
        {(searchBody || altHeadline) && (
          <div className={styles.altHeadline}>
            <MarkdownWrapper children={searchBody || altHeadline} inline />
          </div>
        )}
      </div>
    </Link>
  );
}

CardHorizontal.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),
  /** Размер карточки */
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  /** @ignore */
  theme: PropTypes.object,

  /** Устанавливает максимальную ширину изображения */
  imageMaxWidth: imageMaxWidthValidator,

  /** Устанавливает соотношение сторон изображения */
  imageRatio: PropTypes.number,

  /**
   * Устанавливает ширину изображения,
   * в процентах от родительского элемента
   *
  */
  imageWrapperWidth: PropTypes.string,
  /**
   * Используется для вариантов
   * с фиксированными размерами картинки
   */
  lockImageWidth: PropTypes.bool,

  /** Переобложка изображения,
   * которая будет использоваться в карточке вместо `original` */
  imageVersion: PropTypes.string,
};

CardHorizontal.contextTypes = {
  bebopApi: PropTypes.object,
};

CardHorizontal.defaultProps = {
  size: 's',
  imageRatio: 1,
  imageWrapperWidth: '21.9%',
  imageVersion: 'original',
};

const Card = skip(withTheme(CardHorizontal));

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;


export { Card as CardHorizontalS };
export const CardHorizontalXS = bindProps({ size: 'xs' })(Card);
export const CardHorizontalM = bindProps({ size: 'm' })(Card);
export const CardHorizontalL = bindProps({ size: 'l' })(Card);

export default Card;

export { CardHorizontal as StorybookComponent };
