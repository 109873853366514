import PropTypes from 'prop-types';

import {
  tagFetcher,
  topicsFetcher,
} from 'core/fetchers/tagTopics';

import resolve from 'core/resolver/resolve';

import { Helmet } from 'core/libs/helmet';

import H1 from 'core/components/H1';
import TagTopics from 'core/components/TagTopics';
import { Indent } from 'core/components/Wrappers';
import { withBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import { WideWrapper } from 'site/components/Wrappers';
import Boroda from 'site/components/Boroda';
import RubricTopics from 'site/components/RubricTopics';
import Pagination from 'site/components/Pagination';


function TagPage(props) {
  const {
    tag,
    topics: tagTopics,
    isDesktop,
  } = props;

  return (
    <>
      <Indent top={30} bottom={20}>
        <TagTopics
          tag={tag}
          topics={tagTopics}
          limit={isDesktop ? 16 : 9}
          headerSpacing={20}
          titleComponent={({ children }) => (
            <WideWrapper>
              <H1 dataQa='page-title'>{children}</H1>
            </WideWrapper>
          )}
          pagination={paginationProps => (
            <WideWrapper>
              <Pagination {...paginationProps} />
            </WideWrapper>
          )}
        >
          {({ topics, title, descriptionMeta }) => (
            <>
              <Helmet>
                <meta name='description'
                  content={descriptionMeta || `Все статьи, новости и подборки про ${title}. Собрали самые интересные и популярные факты про ${title} для вас.`}
                />
              </Helmet>
              <RubricTopics topics={topics} />
            </>
          )}
        </TagTopics>
      </Indent>
      <Boroda />
    </>
  );
}

TagPage.propTypes = {
  tag: PropTypes.object,
  topics: PropTypes.object,
  isDesktop: PropTypes.bool,
};

const dataProvider = resolve({
  tag: tagFetcher(),
  topics: props => topicsFetcher({
    limit: props.isDesktop ? 16 : 9,
    fields: 'list_headline,headline,published_at,link',
    include: 'image',
  })(props),
});

export default withBreakpoint(withPageHocs(dataProvider)(TagPage));
