import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'site/components/Link';
import SmartImage from 'core/components/SmartImage';
import Scroller from 'core/components/Scroller';
import EmptyWrapper from 'core/components/EmptyWrapper';

import { withBreakpoint } from 'core/components/breakpoint';

import styles from './index.styl';

function Selections({ selections, isMobile }) {
  if (!selections || selections.length === 0) return null;

  const SelectionWrapper = isMobile ? Scroller : EmptyWrapper;

  return (
    <SelectionWrapper>
      <div className={styles.selections}>
        {selections.map((selection, selectionIndex) => {
          const {
            posters,
            attributes: {
              source_url: sourceUrl,
              headline,
            },
          } = selection;

          return (
            <Link
              key={selection.id}
              to={sourceUrl || ''}
              className={cx(styles.selection, styles['_selection_' + (selectionIndex + 1)])}
            >
              {posters && (
                <div className={styles.posters}>
                  {posters.map((poster, posterIndex) => {
                    /**
                     * Трюк для CSS анимации по z-index.
                     */
                    const mustDuplicate = selectionIndex === 1 && posterIndex === 1;
                    const posterBlock = (
                      <div className={styles.poster}>
                        <SmartImage
                          src={poster}
                          maxWidth={100}
                          aspectRatio={0.6}
                        />
                      </div>
                    );

                    return (
                      <Fragment key={poster.rel_url}>
                        {posterBlock}
                        {(mustDuplicate && !isMobile) && posterBlock}
                      </Fragment>
                    );
                  })}
                </div>
              )}
              <div className={styles.headline}>
                {headline}
              </div>
            </Link>
          );
        })}
      </div>
    </SelectionWrapper>
  );
}

Selections.propTypes = {
  selections: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Selections);
