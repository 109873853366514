import { Fragment } from 'react';

import H3 from 'core/components/H3';
import Link from 'site/components/Link';
import Image from 'core/components/SmartImage';
import Scroller from 'core/components/Scroller';
import { Indent } from 'core/components/Wrappers';

import withTheme from 'core/components/theme';
import themePropTypes from 'core/utils/prop-types/theme';

import styles from './index.styl';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import PropTypes from 'prop-types';

function TvChannels({ theme, tvChannels }) {
  if (!tvChannels?.length) return null;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.channel}
          background ${theme.colors.content}
          transition ${theme.animations.scale}
      `}</style>
    </scope>
  );

  return (
    <Fragment>
      <H3>Телеканалы</H3>
      <Indent top={20} />
      <Scroller listClassName={styles.channels}>
        {tvChannels.map(tvChannel => {
          const { link, coverUrls, title } = tvChannel.attributes;

          return (
            <Link
              key={tvChannel.id}
              type='secondary'
              to={link}
              className={scope.wrapClassNames(styles.channel)}
            >
              <Image
                alt={title}
                url={coverUrls.logo + '?presetId=1152&width=140&scale=2&quality=80&mediaType=jpeg'}
                width={70}
                height={70}
                instant
              />
            </Link>
          );
        })}
      </Scroller>
      <scope.styles />
    </Fragment>
  );
}

TvChannels.propTypes = {
  tvChannels: PropTypes.array,
  theme: themePropTypes(`{
    animations: {
      scale,
    }
  }`),
};

export default withTheme(TvChannels);
