import PropTypes from 'prop-types';
import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { GradientBackground, WideWrapper } from 'site/components/Wrappers';

import Render from './Render';


// TODO: Вернуть скелетон
function WatchSuggestions({ theme, compactView }) {
  const {
    colors: {
      primary: primaryColor,
    },
  } = theme;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .watchSuggestions
          color ${primaryColor}
          padding 30px 0 40px
          position relative

          :global(.mobile) &
            padding 30px 0 30px
      `}</style>
    </scope>
  );

  return (
    <GradientBackground className={scope.wrapClassNames('watchSuggestions')}>
      <WideWrapper leftOnly>
        <Render compactView={compactView} />
      </WideWrapper>
      <scope.styles />
    </GradientBackground>
  );
}

WatchSuggestions.propTypes = {
  /**
   * Флаг, определяющий, сворачивать ли блок с отображением кнопки раскрытия
   * или показывать полный вид
   */
  compactView: PropTypes.bool,
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      primary,
    },
  }`),
};

const WatchSuggestionsWithHOCs = withTheme(WatchSuggestions);
WatchSuggestionsWithHOCs.displayName = 'WatchSuggestions';

export default WatchSuggestionsWithHOCs;

export { WatchSuggestions as StorybookComponent };
