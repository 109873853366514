import PropTypes from 'prop-types';
import cx from 'classnames';

import ThemeSwitch from 'core/components/ThemeSwitch';

import Shapka from 'site/components/Shapka';

import styles from './index.styl';

function Shapkies({ mode }) {
  return (
    <div className={cx(styles.shapkies, styles['_' + mode])}>
      <div className={styles.shapkaRead}>
        <Shapka />
      </div>
      <ThemeSwitch name='dark'>
        <div className={styles.shapkaWatch}>
          <Shapka />
        </div>
      </ThemeSwitch>
    </div>
  );
}

Shapkies.propTypes = {
  mode: PropTypes.string,
};

export default Shapkies;
