import { Fragment } from 'react';
import cx from 'classnames';

import styles from './index.styl';

const EMOTIONS = [
  { title: 'Испугаться' },
  { title: 'Посмеяться' },
  { title: 'Экшн' },
  { title: 'Всей семьей' },
  { title: 'Грустно' },
  { title: 'Романтика' },
];

export default function Skeleton() {
  return (
    <div className={styles.content}>
      <div className={styles.emotions}>
        {EMOTIONS.map((item, index) => (
          <Fragment key={`Emotion_${index}`}>
            <div className={cx(styles.emotion, 'lb-skeleton')} />
          </Fragment>
        ))}
      </div>
    </div>
  );
}
