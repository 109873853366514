import { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withoutSSR from 'core/components/withoutSSR';

import types from './types';

import styles from './index.styl';

/**
 * Подключаем SVGATOR по документации:
 * https://www.svgator.com/help/getting-started/add-animated-svgs-to-react-website
 */
function Mascot({ type, className, width, ...otherProps }) {
  const { icon: Icon, script = () => null } = types[type] || {};

  useEffect(() => {
    script();
  }, [script]);

  if (!Icon) return null;

  return (
    <div className={cx(styles.mascot, className)} {...otherProps}>
      <Icon width={width} />
    </div>
  );
}

Mascot.propTypes = {
  /** Обработчик клика по маскоту */
  handleClick: PropTypes.func,
  /** Класс для стилизации враппера маскота */
  className: PropTypes.string,
  /** Тип маскота */
  type: PropTypes.oneOf(Object.keys(types)),
  /** Ширина изображения */
  width: PropTypes.number,
};

Mascot.defaultProps = {
  width: 160,
};

const MascotWithHOCs = withoutSSR(Mascot);

export default MascotWithHOCs;

export { Mascot as StorybookComponent };
