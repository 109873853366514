import PropTypes from 'prop-types';

import MarkdownWrapper from 'core/components/MarkdownWrapper';

import WidgetSideWrapper from 'site/components/WidgetSideWrapper';

import Quotes from 'site/icons/Quotes.svg';

import modelPropTypes, { opinionAttributes } from 'core/utils/prop-types/model';

import styles from './index.styl';


export default function Expert(props) {
  const {
    block,
    parentProps: { theme },
  } = props;

  if (!block) return null;

  const {
    attributes: {
      body,
      author,
      placement,
    },
  } = block;

  const {
    name,
    avatar,
    job_title: jobTitle,
  } = author.attributes;

  const {
    attributes: {
      versions,
    },
  } = avatar;

  return (
    <WidgetSideWrapper
      imageVersions={versions}
      placement={placement}
      caption={body}
      credits={name}
    >
      <style jsx>{`
        .${styles.body}
          font 700 18px/27px ${theme.fonts.display}

          :global(.mobile) &
            font 700 16px/24px ${theme.fonts.display}

        .name
          font 400 14px/21px ${theme.fonts.display}
          color ${theme.colors.grey2}

        .job
          font 400 14px/21px ${theme.fonts.display}
          color ${theme.colors.grey2}
      `}</style>
      <div className={styles.body}>
        <Quotes
          className={styles.icon}
          color={theme.colors.active2}
          width={18}
          height={14}
        />
        <MarkdownWrapper interitemSpacing={10}>
          {body}
        </MarkdownWrapper>
      </div>
      {(name || jobTitle) && (
        <div className={styles.author}>
          {name && <div className='name'>{name}</div>}
          {jobTitle && <div className='job'>{jobTitle}</div>}
        </div>
      )}
    </WidgetSideWrapper>
  );
}

Expert.propTypes = {
  block: modelPropTypes(opinionAttributes).isRequired,
  parentProps: PropTypes.object,
};
