import PropTypes from 'prop-types';
import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';
import withTheme from 'core/components/theme';
import Avatar from 'core/components/Avatar';

import styles from './index.styl';

function CardAuthorOpinion(props) {
  const {
    content,
    theme,
  } = props;

  if (!content) return null;

  const {
    name,
    job_title: jobTitle,
    avatar,
    alt,
  } = content.attributes;

  const avatarSrc = avatar && avatar.attributes.versions.original;

  return (
    <div className={styles.container}>
      <style jsx>{`
        .name
          font 700 14px/21px ${theme.fonts.display}
          color ${theme.colors.primary}

        .${styles.job}
          font 400 14px/21px ${theme.fonts.display}
          color ${theme.colors.gray}
      `}</style>
      <div className={styles.avatar}>
        <Avatar
          src={avatarSrc}
          size={60}
          alt={alt}
        />
      </div>
      <div className={styles.body}>
        <div className='name'>{name}</div>
        <div className={styles.job}>{ jobTitle }</div>
      </div>
    </div>
  );
}

CardAuthorOpinion.propTypes = {
  /** Данные автора, соответствующие модели `person` swagger-схемы. */
  content: modelPropTypes(personAttributes).isRequired,
  /** @ignore */
  theme: PropTypes.object,
};

export { CardAuthorOpinion as StorybookComponent };

export default withTheme(CardAuthorOpinion);
