import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './index.styl';

export default function TextBlock({ children, className }) {
  return (
    <div className={cx(styles.textBlock, className)}>
      {children}
    </div>
  );
}

TextBlock.propTypes = {
  className: PropTypes.string,
};
