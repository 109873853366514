import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes from 'core/utils/prop-types/model';

import skip from 'core/resolver/skip';

import SmartImage from 'core/components/SmartImage';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'announce',
  'source',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});


function FactCard(props) {
  if (!props.content) return null;

  const {
    content,
  } = props;
  const {
    announce,
    source,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  return (
    <div className={styles.factCard}>
      <SmartImage
        src={originalCover}
        previewSrc={previewCover}
        width={140}
        height={140}
        placement='contain'
      />
      <div className={styles.announce}>{announce}</div>
      <div className={styles.source}>{source}</div>
    </div>
  );
}

FactCard.propTypes = {
  /** Данные для карточки */
  content: modelPropTypes(PropTypes.shape({
    announce: PropTypes.string,
    source: PropTypes.string,
  })),
};

const Card = skip(FactCard);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export default Card;

export { FactCard as StorybookComponent };
