import { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint } from 'core/components/breakpoint';
import Swipe, { Slide } from 'core/components/Swipe';
import EmptyWrapper from 'core/components/EmptyWrapper';

import Emotion from '../components/Emotion';
import Overlay from '../components/Overlay';
import Poster from '../components/Poster';

import styles from './index.styl';


function Render(props) {
  const {
    isMobile,
    selections,
  } = props;

  const [active, setActive] = useState(null);
  const [posterData, setPosterData] = useState([]);

  const isActive = active !== null;

  let posterTopics = [];
  let swipeInstance;

  const handleNextClick = useCallback(() => {
    const data = [...posterData];
    data.push(data.shift());
    setPosterData(data);
  }, [posterData]);

  const onClickHandler = index => {
    setActive(prevState => prevState === index ? null : index);
    if (active !== index) {
      setPosterData(posterTopics[index]);
    }
    swipeInstance && swipeInstance.goto(index);
  };

  const Wrapper = isMobile ? Slide : EmptyWrapper;

  const Emotions = useMemo(
    () => selections.map((item, index) => (
      <Wrapper key={`emo_item_${index}`} additionalClass={styles.emotionWrapper}>
        <Emotion
          selection={item}
          isActive={active === index}
          isHidden={isActive && (active !== index)}
          onClick={onClickHandler.bind(null, index)}
          onNextClick={handleNextClick}
        />
      </Wrapper>
    )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active, handleNextClick]
  );

  if (!selections && selections.length === 0) return null;

  posterTopics = selections.map(selection => selection.topics);

  return (
    <div className={cx(styles.content, isActive && styles._active)}>
      {posterData[0] && (
        <Poster
          content={posterData[0]}
          isActive={isActive}
          key={posterData[0].id}
        />
      )}
      {isActive && isMobile
        ? (
          <Swipe
            containerClass={styles.swipe}
            defaultItemIndex={active || 0}
            ref={instance => {swipeInstance = instance;}}
            centeredSlides
          >
            {Emotions}
          </Swipe>
        ) : (
          <div className={styles.emotions}>
            {Emotions}
          </div>
        )
      }
      <Overlay content={selections} isMobile={isMobile} />
    </div>
  );
}

Render.propTypes = {
  isMobile: PropTypes.bool,
  selections: PropTypes.arrayOf(PropTypes.object),
};

export default withBreakpoint(Render);
