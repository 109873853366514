import GradientButton from 'site/components/GradientButton';
import ArrowRightThin from 'site/icons/ArrowRightThin.svg';

import styles from './index.styl';


function GameCompareButton({ children }) {
  return (
    <GradientButton>
      <div className={styles.content}>
        <div className={styles.text}>
          {children}
        </div>
        <ArrowRightThin />
      </div>
    </GradientButton>
  );
}


export default GameCompareButton;
