import PropTypes from 'prop-types';

import { ImageWithText } from 'core/components/TopicContent/blocks/image';
import CaptionCredits from 'core/components/CaptionCredits';
import SmartImage from 'core/components/Image';

import WidgetSideWrapper from 'site/components/WidgetSideWrapper';

import styles from './index.styl';


const ImageComponent = (props) => (
  <div className={styles.imgWrapper}>
    <SmartImage {...props} />
  </div>
);

export default function Image({ block, parentProps }) {
  const { topic, theme, isDesktop } = parentProps;
  const {
    content: {
      attributes: {
        headline,
      },
    },
  } = topic || { attributes: {} };

  const {
    align,
  } = block.attributes;

  const captionCreditsStyles = {
    creditsStyles: {
      font: `${isDesktop ? '14px/21px' : '12px/15px'} ${theme.fonts.text}`,
      ...!isDesktop && { margin: '10px 0 0' },
    },
    captionStyles: {
      margin: 'auto 0',
      font: `${isDesktop ? '18px/27px' : '16px/24px'} ${theme.fonts.text}`,
    },
  };

  if (!align || align === 'center') {
    return (
      <ImageWithText
        block={block}
        creditsPrefix=''
        imageComponent={ImageComponent}
        topicTitle={headline}
      />
    );
  }

  const {
    alt,
    caption,
    credits,
    credits_url: creditsUrl,
    url,
    versions,
  } = block.attributes;

  const altText = alt || caption;

  return (
    <WidgetSideWrapper
      imageVersions={versions}
      placement={align}
      alt={altText}
      url={url}
      caption={caption}
      credits={credits}
    >
      <CaptionCredits {...{
        className: styles.captionCredits,
        caption,
        credits,
        creditsUrl,
        creditsPrefix: '',
        ...captionCreditsStyles,
      }}
      />
    </WidgetSideWrapper>
  );
}

Image.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
