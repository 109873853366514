import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Indent } from 'core/components/Wrappers';
import ThemeSwitch from 'core/components/ThemeSwitch';

import GradientRoundControl from 'site/components/GradientRoundControl';

import ArrowMultiple from 'site/icons/ArrowMultiple';
import { WideWrapper } from 'site/components/Wrappers';

import Shapkies from 'site/pages/main/ReadAndWatch/components/Shapkies';
import Title from 'site/pages/main/ReadAndWatch/components/Title';
import Background from 'site/pages/main/ReadAndWatch/components/Background';
import Overlay from 'site/pages/main/ReadAndWatch/components/Overlay';

import { modes } from 'site/pages/main/ReadAndWatch/constants';

import styles from './index.styl';


function ReadAndWatchDesktopWrapper(props) {
  const [mode, setMode] = useState(modes.watch);

  const handleMode = () => {
    const newMode = mode === modes.read ? modes.watch : modes.read;
    setMode(newMode);
  };

  const {
    watchElement,
    readElement,
  } = props;

  return (
    <div className={cx(styles.readAndWatch, styles['_' + mode])}>
      <Shapkies mode={mode} />
      <div className={styles.content}>
        <div className={styles.readBlock}>
          <WideWrapper>
            <div className={styles.readContent}>
              <Title isActive={mode === modes.read} handleClick={handleMode}>Читать</Title>
              <Indent bottom={20} />
              <Overlay
                type={modes.read}
                isActive={mode === modes.read}
                handleClick={handleMode}
              >
                {readElement}
              </Overlay>
            </div>
          </WideWrapper>
        </div>
        <ThemeSwitch name='dark'>
          <GradientRoundControl
            handleClick={handleMode}
            icon={ArrowMultiple}
            className={styles.control}
            withMascot
          />
        </ThemeSwitch>
        <div className={styles.watchBlock}>
          <ThemeSwitch name='dark'>
            <Background isActive={mode === modes.watch}>
              <WideWrapper>
                <div className={styles.watchContent}>
                  <div className={styles.watchTitle}>
                    <Title isActive={mode === modes.watch} handleClick={handleMode}>Смотреть</Title>
                  </div>
                  <Indent bottom={20} />
                  <Overlay
                    type={modes.watch}
                    isActive={mode === modes.watch}
                    handleClick={handleMode}
                  >
                    {watchElement}
                  </Overlay>
                </div>
              </WideWrapper>
            </Background>
          </ThemeSwitch>
        </div>
      </div>
    </div>
  );
}

ReadAndWatchDesktopWrapper.propTypes = {
  watchElement: PropTypes.node,
  readElement: PropTypes.node,
};

export default ReadAndWatchDesktopWrapper;
