import Paginatron from 'core/components/Paginatron';

export default function Pagination(props) {
  return (
    <div className='pagination'>
      <style jsx>{`
        .pagination
          margin 0 -10px
      `}</style>
      <Paginatron {...props} />
    </div>
  );
}
