import PropTypes from 'prop-types';

import { Fragment } from 'react';
import { Position } from 'core/components/Grid';

import { Indent } from 'core/components/Wrappers';

function NothingFound({ text, title }) {
  return (
    <Fragment>
      <Indent bottom={101} />
      <Position center>
        <span>{title}</span>
      </Position>
      {text.length > 0 && (
        <Fragment>
          <Indent bottom={30} />
          <Position center>
            {text}
          </Position>
        </Fragment>
      )}
      <Indent bottom={120} />
    </Fragment>
  );
}

NothingFound.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

NothingFound.defaultProps = {
  title: 'Ничего не найдено',
  text: '',
};

export default NothingFound;
