import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import {
  authorFetcher,
  topicsFetcher,
} from 'core/fetchers/authorPage';

import withPageHocs from 'core/components/withPageHocs';
import AuthorPage from 'core/components/AuthorPage';

import AuthorPageCard from 'site/cards/CardAuthor/AuthorPageCard';

import PublicationsFeed from 'site/components/PublicationsFeed';
import Pagination from 'site/components/Pagination';
import { WideWrapper } from 'site/components/Wrappers';


const descriptionBuilder = author => 'Профиль автора. ' + (author?.attributes?.name || '');
const pagination = props => (
  <WideWrapper>
    <Pagination {...props} />
  </WideWrapper>
);

function Author({ topics, author }) {
  return (
    <AuthorPage
      topics={topics}
      author={author}
      feedRenderer={PublicationsFeed}
      interitemSpacing={20}
      descriptionBuilder={descriptionBuilder}
      pagination={pagination}
      authorCard={AuthorPageCard}
      hideTopicsTitle
    />
  );
}

Author.propTypes = {
  topics: PropTypes.object,
  author: PropTypes.object,
};

const dataProvider = resolve({
  author: authorFetcher(),
  topics: topicsFetcher({
    limit: 16,
    include: 'rubric,image',
    fields: 'link,headline,published_at',
  }),
});

export default withPageHocs(dataProvider)(Author);
