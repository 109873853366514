import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import Drum from 'core/components/Drum';

import Card from './Card';

import styles from './index.styl';


const commentsList = [
  {
    id: 1,
    comment: 'Потому что это реально мощное высказывание и одна из лучших работ Быкова. Причем Юрий не зря постоянно подчеркивает, что эта история – не про бандитов, а про семью. Этот акцент очень чувствуется. Что не мешает даже на уровне антуража показать 1990-е так, что тему можно закрывать. Дальнейшие попытки зайти на эту территорию будут неизбежно поверяться «Лихими». И тягаться с ними будет ой как непросто.',
    author: '/ Ещё то кино',
  },
  {
    id: 2,
    comment: 'Судя по первым сериям, Быков остается верен себе прежнему: захват зрителя в первые же минуты. В спортивной борьбе это называется зажим или замок. В общем - клинч и сразу. Так было, например, в «Майоре». Дальше - тоже «качели»: между «лихими 90-ми» и нашими днями: и там, и там - непросто.',
    author: '/ Alperina',
  },
  {
    id: 3,
    comment: '«ЛИХИЕ» — это новый слоняра российских сериалов, стопроцентный хит, который будет раскачивать публику. Люди будут бешено качать и ждать новых серий — он имеет ровно то же качество, что и «СЛОВО ПАЦАНА» или «БРИГАДА»: витальную драматургию героев, с самых первых минут приговоренных к расплате за кровавую драму страны от «жить было страшно» до «как же жить теперь».',
    author: '/ Русское кино в топе',
  },
  {
    id: 4,
    comment: 'Ниша умного, комплексного сериала, осмысляющего 90-е, у нас долгое время пустовала, и «Лихие» наконец-то ее заполнил.',
    author: '/ Сити',
  },
  {
    id: 5,
    comment: 'В любом случае «Лихие» совсем не только про 1990-е. Быков и Маловичко пишут хронику семьи, разрушение которой, по их версии, началось в 1990-е и продолжается прямо сейчас. В этой саге нет ни намека на романтику простых мужиков. Лиховцевы падают в бездну прозаично и без шика.',
    author: '/ Кинопоиск',
  },
  {
    id: 6,
    comment: '«Лихие» прямо говорят о главном парадоксе 90-х, который прочие сериалы предпочитают игнорировать: уже кажется, что всё это нафталиновая быличка и случилось не с нами. Преступления Краба и Джема (личностей вполне реальных) воспринимаются таким же отголоском прошлого, как налеты «попрыгунчиков» Ивана Бальгаузена, кошмарившего Петербург столетие назад.',
    author: '/ Кино-Театр.РУ',
  },
  {
    id: 7,
    comment: 'В «Лихих» нет персонажей, которым хочется сопереживать, отсутствует романтизация бандитской жизни, а мемным цитатам и модным клиффхэнгерам места не нашлось. Сериал Быкова — это отечественное кино, снятое по азиатским лекалам, из-за чего оно ближе к творчеству Такеси Китано, но с особенным русским флером о чести и мести.',
    author: '/ gamemag',
  },
  {
    id: 8,
    comment: 'У зрителя едва ли возникнет желание быть похожим на кого-либо из героев: персонажи — это люди, которые выбрали дорогу зла, и они знают об этом. И спасибо Быков-Маловичко, что они честно показывают их теми, кем они и являются — преступниками, для которых нормы морали с каждым новым трупом становятся все прозрачнее.',
    author: '/ 7дней',
  },
  {
    id: 9,
    comment: 'В сериале нас не столько знакомят с бандитским миром, сколько показывают, откуда растет насилие и как его можно воспитать. «ЛИХИЕ» — далеко не ностальгия по прошлому и разборки лихих пацанов, это тонкая драма об отцах и детях, о поиске себя и моральном сломе.',
    author: '/ кино про тебя',
  },
  {
    id: 10,
    comment: '«Лихие» – это не просто криминальная драма, а буквально гангстерская сага. С кучей второстепенных персонажей, звездным кастом и сюжетом, затрагивающим несколько десятилетий.',
    author: '/ Палач',
  },
  {
    id: 11,
    comment: 'Кинопродукт у режиссера Юрия Быкова получился вполне неплохой и местами динамичный. Есть и психология, и черный юмор в диалогах, и драки, и убийства, и семейные разборки. Имеются удачные со всех точек зрения эпизоды, как например, история с крещением Евгения.',
    author: '/ Новости Хабаровска',
  },
  {
    id: 12,
    comment: 'Юрий Быков снимает наотмашь, почти радикально, — иначе не умеет. В оптике Быкова криминальные 90-е — время, когда все подставляли всех, а родители уничтожали свои семьи. ',
    author: '/ Лайфхакер',
  },
];


function Comments({ theme }) {
  return (
    <div className={styles.comments}>
      <style jsx>{`
        .${styles.title}
          font-family ${theme.fonts.likhie}
      `}</style>
      <div className={styles.title}>Критики <br /> о «Лихих»</div>
      <Drum
        content={commentsList}
        card={Card}
        spaceBetween={30}
        itemWidth={317}
        hideArrows
        showHeaderArrows
      />
    </div>
  );
}

Comments.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(Comments);
