import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Scroller from 'core/components/Scroller';
import GridFeed from 'core/components/GridFeed';
import bindProps from 'core/components/bindProps';
import { withBreakpoint } from 'core/components/breakpoint';

import CardMovie, { CardMovieSizeMobile } from 'site/cards/CardMovie';

import Selections from './Selections';

import styles from './index.styl';


const WatchScroller = bindProps({ listClassName: styles.watchList })(Scroller);

function Watch({ movies, selections, isMobile }) {
  const WatchWrapper = isMobile ? WatchScroller : Fragment;
  const FeedCard = isMobile ? CardMovieSizeMobile : CardMovie;

  return (
    <div className={styles.watch}>
      {movies && (
        <WatchWrapper>
          <GridFeed
            content={movies}
            gridTemplateColumns='repeat(4, 1fr)'
            gap={isMobile ? '20px' : '28px'}
            card={FeedCard}
            cardsReplacement={{
              0: bindProps({ highPriorityImage: true })(FeedCard),
            }}
          />
        </WatchWrapper>
      )}
      <Selections selections={selections} />
    </div>
  );
}

Watch.propTypes = {
  movies: PropTypes.array,
  selections: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Watch);
