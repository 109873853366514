import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import EmptyWrapper from 'core/components/EmptyWrapper';
import Link from 'core/components/Link';
import ShapkaMenuItems from 'core/components/ShapkaMenuItems';
import Scroller from 'core/components/Scroller';

import { withRouter } from 'core/libs/router';
import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { getCurrentMenuMatches } from 'core/utils/url-helper';

import PopcornButton from 'site/components/PromoButton';
import { WideWrapper } from 'site/components/Wrappers';

import styles from './index.styl';


function ShapkaCustom({ theme, menu, isErrorPage, isMobile, location }) {
  const Wrapper = isMobile && isErrorPage ? Scroller : EmptyWrapper;
  const mainMenuItems = menu?.data?.attributes?.menu_items;

  const shapkaStyles = theme.controls.shapka;
  const {
    search: SearchIcon,
    logo: LogoIcon,
    whiteLogo: WhiteLogoIcon,
  } = theme.icons;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.logo}
          max-width ${shapkaStyles.logo.width}px
        .${styles.search}
          padding ${shapkaStyles.link.padding}
          color ${shapkaStyles.link.idle.color}

          &:hover
            color ${shapkaStyles.link.hover.color}

          &:active
            color ${shapkaStyles.link.active.color}

          :global(svg)
            stroke currentColor
      `}</style>
    </scope>
  );

  const currentMatches = useMemo(
    () => getCurrentMenuMatches(mainMenuItems || [], location),
    [location, mainMenuItems]
  );

  return (
    <WideWrapper>
      <header className={cx(styles.shapka, isErrorPage && styles.error)}>
        <style jsx>{`
          .${styles.shapka}
            padding ${shapkaStyles.padding}
            padding-left 0
            padding-right 0
        `}</style>
        <Link
          to='/'
          className={scope.wrapClassNames(styles.logo)}
          type='blank'
        >
          {isErrorPage ? <WhiteLogoIcon /> : <LogoIcon />}
        </Link>
        <Wrapper>
          <div className={styles.nav}>
            <div className={styles.menu}>
              <ShapkaMenuItems
                items={mainMenuItems || []}
                currentItem={currentMatches[0]}
                isNavItem
              />
              <Link
                to='/search'
                className={scope.wrapClassNames(styles.search)}
                type='blank'
              >
                <SearchIcon />
              </Link>
            </div>
            {!isErrorPage && <PopcornButton className={styles.button} />}
          </div>
        </Wrapper>
        <scope.styles />
      </header>
    </WideWrapper>
  );
}

ShapkaCustom.propTypes = {
  menu: PropTypes.object,
  theme: PropTypes.object,
  isErrorPage: PropTypes.bool,
  isMobile: PropTypes.bool,
  location: PropTypes.object.isRequired,
};

export default withTheme(withBreakpoint(withRouter(ShapkaCustom)));
