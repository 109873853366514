import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import H3 from 'core/components/H3';
import { Indent } from 'core/components/Wrappers';

import listsData from '../constants';

import styles from './index.styl';

const BLOCKS = [
  { className: styles.collections },
  { ...listsData.afisha, className: styles.afisha },
  { ...listsData.freeMovies, className: styles.freeMovies, compactView: true },
  { ...listsData.freeSeries, className: styles.freeSeries, compactView: true },
  { ...listsData.shopPopulars, className: styles.shopPopulars },
  { ...listsData.tvChanels, className: styles.tvChanels },
];


function Skeleton({ compactView }) {
  return (
    <Fragment>
      {BLOCKS.map(({ className, title, compactView: blockCompactView }, index) => {
        if (compactView === true && compactView !== blockCompactView) return null;

        return (
          <Fragment key={className}>
            {title && <H3 className={styles.title}>{title}</H3>}
            <div className={cx(className, styles.section, 'lb-skeleton')} />
            {index !== BLOCKS.length - 1 && <Indent bottom={30} />}
          </Fragment>
        );
      })}
    </Fragment>
  );
}

Skeleton.propTypes = {
  compactView: PropTypes.bool,
};

export default Skeleton;
