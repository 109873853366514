import PropTypes from 'prop-types';

import H3 from 'core/components/H3';
import Scroller from 'core/components/Scroller';
import { Indent } from 'core/components/Wrappers';
import { withBreakpoint } from 'core/components/breakpoint';

import {
  CardExternalMovieType4,
  CardExternalMovieType5,
  CardExternalMovieType6,
  CardExternalMovieType7,
} from 'site/cards/CardExternalMovie';

import listsData from '../constants';
import styles from '../index.styl';

export const CARDS_SCALE_OFFSET = 5;


function Lists({ movies, isDesktop, compactView }) {
  if (!movies) return null;

  const {
    freeMovies,
    freeSeries,
    shopPopulars,
  } = movies;

  const FirstCard = isDesktop ? CardExternalMovieType4 : CardExternalMovieType5;
  const SecondCard = isDesktop ? CardExternalMovieType6 : CardExternalMovieType7;

  return (
    <div className='content'>
      {[
        { ...listsData.freeMovies, list: freeMovies, card: FirstCard, showInCompact: true },
        { ...listsData.freeSeries, list: freeSeries, card: FirstCard, showInCompact: true },
        { ...listsData.shopPopulars, list: shopPopulars, card: SecondCard, showInCompact: false },
      ].map(({ title, list, card: Card, showInCompact }) => {
        if (list.length === 0) return null;
        if (!showInCompact && compactView) return null;
        return (
          <div className={styles.list} key={title}>
            <H3>{title}</H3>
            <Indent top={20 - CARDS_SCALE_OFFSET} />
            <Scroller listClassName={styles.cards}>
              {list.map(item => (
                <div key={item.id} className={styles.card}>
                  <Card content={item} />
                </div>
              ))}
            </Scroller>
          </div>
        );
      })}
    </div>
  );
}

Lists.propTypes = {
  isDesktop: PropTypes.bool,
  movies: PropTypes.object,
  compactView: PropTypes.bool,
};

export default withBreakpoint(Lists);
