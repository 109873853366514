import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';
import Modal from 'core/components/Modal';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Button from '../Button';

import Arrow from '../images/Arrow';
import polkiVideo from '../images/polki.mp4';

import styles from './index.styl';


function Polki({ theme, isMobile }) {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);
  const videoRef = useRef(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const scopedStyles = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .modalWindow
          background-color rgba(0, 0, 0, 0.5)
          width 100%
          height 100%
          color #fff
      `}</style>
    </scope>
  );

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';

    if (videoRef.current) {
      if (isOpen) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }

    const handleClickOutside = (event) => {
      if (isOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleEscapeKeyDown = (event) => {
      if (isOpen && event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKeyDown);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKeyDown);
    };
  }, [isOpen]);

  return (
    <div className={styles.polki}>
      <style jsx>{`
        .${styles.polki}
          color ${theme.colors.primary}

        .${styles.title}
        .${styles.toWatch}
          font-family ${theme.fonts.likhie}
      `}</style>
      <div className={styles.videoWrapper}>
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          width='100%'
        >
          <source src={polkiVideo} type='video/mp4' />
        </video>
      </div>

      <div className={styles.text}>
        <div className={styles.title}>Пустые <br /> полки</div>
        <div className={styles.about}>
          <div>
            Мы склонны романтизировать эпоху
            90-х. Но не стоит забывать, что это были
            по-настоящему драматичные, тяжелые времена – как для страны в целом так и для ее жителей в частности.
          </div>
          <div>
            Мы воссоздали атмосферу эпохи и переоборудовали действующий продуктовый магазин, опустошив все полки с едой, и открыли двери для всех прохожих.
          </div>
          <div>
            Вот, как на это отреагировали посетители
          </div>

          <Button onClick={handleOpen} iconBefore={<Arrow />}>
            Смотреть видео
          </Button>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        scopedStyles={scopedStyles}
      >
        <div className={styles.iframeWrapper}>
          <iframe
            ref={modalRef}
            src='https://vk.com/video_ext.php?oid=-47393707&id=456244625&hash=bc80a8fe1db247e6'
            width={isMobile ? '100%' : '940'}
            frameBorder='0'
            allowFullScreen='1'
            allow='autoplay; encrypted-media; fullscreen; picture-in-picture'
          />
        </div>
      </Modal>
    </div>
  );
}

Polki.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(withTheme(Polki));
