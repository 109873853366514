import PropTypes from 'prop-types';

import Shapka from 'core/components/Shapka';

import PopcornButton from 'site/components/PromoButton';

import styles from './index.styl';

export default function ShapkaMobile({ menu }) {
  return (
    <Shapka
      menu={menu}
      extra={<PopcornButton className={styles.popcorn} />}
    />
  );
}

ShapkaMobile.propTypes = {
  menu: PropTypes.object,
};
