import PropTypes from 'prop-types';
import cx from 'classnames';

import ThemeSwitch from 'core/components/ThemeSwitch';

import styles from './index.styl';

export default function RubricAccent(props) {
  const { className, ...otherProps } = props;
  return (
    <ThemeSwitch name='dark'>
      <div className={cx(styles.rubricAccent, className)} {...otherProps} />
    </ThemeSwitch>
  );
}

RubricAccent.propTypes = {
  className: PropTypes.string,
};
