import PropTypes from 'prop-types';

import H3 from 'core/components/H3';
import Scroller from 'core/components/Scroller';
import { Indent } from 'core/components/Wrappers';
import { withBreakpoint } from 'core/components/breakpoint';

import {
  CardExternalMovieType1,
  CardExternalMovieType2,
  CardExternalMovieType3,
} from 'site/cards/CardExternalMovie';

import styles from './index.styl';

export const CARDS_SCALE_OFFSET = 5;

function Afisha({ afisha, isMobile }) {
  if (!afisha || afisha.length === 0) return null;

  return (
    <div className='afisha'>
      <H3>Хочу в кино</H3>
      <Indent top={20 - CARDS_SCALE_OFFSET} />
      <Scroller listClassName={styles.cards}>
        {afisha.map((item, index) => {
          const CardDesktop = index === 0 ? CardExternalMovieType1 : CardExternalMovieType2;
          const Card = isMobile ? CardExternalMovieType3 : CardDesktop;

          return (
            <div key={item.id} className={styles.card}>
              <Card content={item} />
            </div>
          );
        })}
      </Scroller>
    </div>
  );
}

Afisha.propTypes = {
  afisha: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Afisha);
