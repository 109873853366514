import PropTypes from 'prop-types';

import styles from './index.styl';

export default function Card(props) {
  const {
    content: {
      comment,
      author,
    },
  } = props;

  return (
    <>
      <div className={styles.comment}>{comment}</div>
      <div className={styles.author}>{author}</div>
    </>
  );
}

Card.propTypes = {
  content: PropTypes.object,
};
