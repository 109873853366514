import cx from 'classnames';

import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import WideWrapper from 'site/components/Wrappers/WideWrapper';

import skeletonStyle  from './index.styl';
import styles from '../index.styl';

function PlaylistsSkeleton({ theme, isMobile }) {
  const cardWidth = isMobile ? '276px' : '1fr';
  return (
    <div className='wrapper'>
      <style jsx>{`
        .${styles.card}
          width ${cardWidth}
        .wrapper
          background-color ${theme.colors.playlistBgColor}
          @media screen and (max-width: ${theme.layout.minWidth})
            min-width ${theme.layout.minWidth}
            margin-left  -${theme.layout.indents.left}px
            left 0

        .${skeletonStyle.skeletonWrapper}
          grid-template-columns ${cardWidth} ${cardWidth} ${cardWidth} ${cardWidth}
          justify-content ${isMobile ? 'flex-start' : 'center' }
          padding-left ${isMobile ? ' 20px' : '0'}
          gap ${isMobile ? '10px' : '30px'}
          margin 0 auto
      `}</style>
      <WideWrapper>
        <div className={cx(styles.gridWrapper, skeletonStyle.skeletonPlaylist)}>
          <div className={skeletonStyle.skeletonWrapper}>
            <div className={cx(skeletonStyle.card, 'lb-skeleton')} />
            <div className={cx(skeletonStyle.card, 'lb-skeleton')} />
            <div className={cx(skeletonStyle.card, 'lb-skeleton')} />
            <div className={cx(skeletonStyle.card, 'lb-skeleton')} />
          </div>
        </div>
      </WideWrapper>
    </div>
  );
}

PlaylistsSkeleton.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};


const Skeleton = withBreakpoint(withTheme(PlaylistsSkeleton));

export default Skeleton;
