import cx from 'classnames';
import PropTypes from 'prop-types';
import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';
import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';

import ArrowRight from 'core/components/Icon/ArrowRightSocial';
import styles from './index.styl';

const socialSlug = [
  'telegram',
];


function SocialMedia(props, context) {
  const {
    isDesktop,
    theme,
    socialNetwork: {
      message,
      slug,
    },
  } = props;

  const { socials } = context;

  const network = socials.find(val => val.name.toLowerCase() === slug);

  if (slug && network) {
    return  (
      <YandexMetrikaGoalSender
        click={slug + '_clicked'}
        show={slug + '_viewed'}
        disabled={!isDesktop}
      >
        <a
          href={network.link}
          className={styles.link}
          target='_blank'
        >
          <style jsx>{`
            .${styles.socialMedia}
              font-family ${theme.fonts.display}
          `}</style>
          <div className={cx(styles.socialMedia, styles[slug])}>
            <div className={styles.wrapper}>

              <div className={styles.logoTextWrapper}>
                <span className={styles.logoText}>Okko</span><br />
                <span className={styles.logoText}>в Телеграм</span>
              </div>

              <p className={styles.title}>{message}</p>
              <span className={styles.subscribe} href={network.link}>
                {isDesktop && 'Подпишись!'}
                <span className={styles.icon}>
                  <ArrowRight />
                </span>
              </span>
            </div>
          </div>
        </a>
      </YandexMetrikaGoalSender>
    );
  }
  return null;
}

SocialMedia.propTypes = {
  /** Внешний вид отображения плашки */
  /** @ignore */
  isDesktop: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
  /** Соцсеть, для которой отображаем плашку */
  socialNetwork: PropTypes.shape({
    message: PropTypes.string,
    slug: PropTypes.oneOf(socialSlug),
  }),
};

SocialMedia.contextTypes = {
  socials: PropTypes.array,
};

const SocialMediaWithHOCs = withBreakpoint(withTheme(SocialMedia));
SocialMediaWithHOCs.displayName = 'SocialMedia';

export { SocialMedia as StorybookComponent };
export default SocialMediaWithHOCs;
