import color from 'color';
import deepmerge from 'core/libs/deepmerge';
import bindProps from 'core/components/bindProps';

import { NegativeMobile } from 'core/components/Wrappers';

import Logo from 'site/icons/Logo';
import WhiteLogo from 'site/icons/WhiteLogo';
import Search from 'site/icons/Search';
import ArrowLeft from 'site/icons/ArrowLeft';
import ArrowRight from 'site/icons/ArrowRight';
import Winner from 'site/icons/Winner.svg';
import Progress from 'site/icons/Progress';

import GameCompareButton from 'site/components/GameCompareButton';
import { NarrowWrapper } from 'site/components/Wrappers';

import { CardVerticalM } from '../cards/CardVertical';
import { CompareCardS } from 'site/cards/CompareCard';

import ErrorPageHeader from 'site/components/ErrorPageHeader';

import { SIDE_INDENT } from 'site/constants';
import fonts from './fonts';

export const CardVertical = bindProps({ imageMaxWidth: 250 })(CardVerticalM);

const ArrowLeft40 = bindProps({ width: 40, height: 40 })(ArrowLeft);
const ArrowRight40 = bindProps({ width: 40, height: 40 })(ArrowRight);

const layout = {
  maxWidth: '10000px',
  minWidth: '1280px',
  contentColumnMaxWidth: '800px',
  boxShadow: 'none',
  indents: {
    top: 0,
    right: 40,
    bottom: 0,
    left: 40,
  },
};

const icons = {
  logo: Logo,
  whiteLogo: WhiteLogo,
  search: Search,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
};

export default function baseTheme(atoms) {
  const animations = {
    scale: 'transform .4s ease-out',
  };

  const texts = {
    lead: {
      font: `400 18px/27px ${fonts.display}`,
      color: atoms.colors.grey2,
    },

    body: {
      font: `400 18px/27px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    bodySmall: {
      font: `12px/15px ${fonts.display}`,
      color: atoms.colors.grey2,
    },

    incut: {
      font: `700 18px/27px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    h1: {
      font: `500 32px/36px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    h2: {
      font: `700 24px/28px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    h3: {
      font: `700 21px/27px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    get display1() {
      return this.h1;
    },

    get display2() {
      return this.h2;
    },

    get display3() {
      return this.h3;
    },

    get title1() {
      return this.h1;
    },

    title2: {
      font: `700 24px/28px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    get title3() {
      return this.h3;
    },
  };

  const scooter = {
    padding: `${SIDE_INDENT}px`,
    font: `normal 12px/15px ${fonts.display}`,
    border: 'none',
    logo: {
      width: 245,
      height: 34,
    },
    menuLink: {
      font: `500 14px/1 ${fonts.display}`,
      idle: {
        color: atoms.colors.primary,
        decoration: 'none',
      },
      hover: {
        color: atoms.colors.accent,
        decoration: 'none',
      },
      active: {
        color: atoms.colors.active,
        decoration: 'none',
      },
    },
    menuItem: {
      pinnedSideItems: true,
    },
  };

  const shapka = {
    sticky: false,
    padding: `30px ${SIDE_INDENT}px`,
    link: {
      font: `500 16px/21px ${fonts.display}`,
      padding: '6px 20px',
    },
    logo: {
      width: 287,
    },
  };

  const drum = {
    arrow: {
      width: '50',
      height: '50',

      fade: {
        outer: 'transparent',
        inner: 'transparent',
      },
    },
  };

  const link = {
    article: {
      idle: {
        decoration: 'underline',
      },

      hover: {
        decoration: 'none',
      },
    },

    primary: {
      idle: {
        color: atoms.colors.primary,
        decoration: 'none',
      },

      hover: {
        color: atoms.colors.active1,
        decoration: 'none',
      },
    },

    footer: {
      visited: {
        color: texts.bodySmall.color,
      },
    },
  };

  const inputSizes = {
    small: {
      font: `500 16px/24px ${fonts.text}`,
      width: '140px',
      height: '38px',
      padding: '7px 10px',
    },
  };

  const buttonSizes = {
    small: {
      font: `14px/18px ${fonts.text}`,
      width: '87px',
      height: '28px',
      padding: '5px 10px',
    },

    medium: {
      font: `600 14px/18px ${fonts.text}`,
      width: '104px',
      height: '44px',
      padding: '10px',
    },
  };

  const input = {
    const: {
      sizes: inputSizes,
      radius: '3px',
      color: atoms.colors.primary,
    },

    basic: {
      idle: {
        background: atoms.colors.input,
        border: `1px solid ${atoms.colors.gray1}`,
        boxShadow: 'none',
        placeholderColor: atoms.colors.gray1,
      },

      hover: {
        background: atoms.colors.input,
        border: `1px solid ${atoms.colors.gray1}`,
        boxShadow: 'none',
      },

      focused: {
        background: atoms.colors.input,
        border: `1px solid ${atoms.colors.active1}`,
        boxShadow: 'none',
      },

      disabled: {
        background: atoms.colors.gray1,
        border: `1px solid ${atoms.colors.gray1}`,
        boxShadow: 'none',
      },

      error: {
        background: atoms.colors.input,
        border: `1px solid ${atoms.colors.error}`,
        boxShadow: 'none',
      },
    },
  };

  const button = {
    const: {
      sizes: buttonSizes,
      radius: '8px',
    },

    primaryGray: {
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.gray2,
        border: 'none',
      },

      hover: {
        color: atoms.colors.content,
        background: 'none',
        border: 'none',
      },
    },

    secondary: {
      idle: {
        color: atoms.colors.primary,
        background: 'transparent',
        border: `1px solid ${atoms.colors.primary}`,
      },

      hover: {
        color: atoms.colors.primary,
        background: 'transparent',
        border: `1px solid ${atoms.colors.primary}`,
      },

      active: {
        color: atoms.colors.primary,
        background: 'transparent',
        border: `1px solid ${atoms.colors.primary}`,
      },

      disabled: {
        color: atoms.colors.primary,
        background: 'transparent',
        border: `1px solid ${atoms.colors.primary}`,
      },
    },

    tab: {
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.gray2,
        border: 'none',
      },

      hover: {
        color: atoms.colors.content,
        background: color(atoms.colors.gray2).alpha(0.7).string(),
        border: 'none',
      },

      active: {
        color: atoms.colors.content,
        background: atoms.colors.gray2,
        border: 'none',
      },

      disabled: {
        color: color(atoms.colors.content).alpha(0.9).string(),
        background: color(atoms.colors.gray2).alpha(0.7).string(),
        border: 'none',
      },
    },

    tertiary: {
      idle: {
        color: atoms.colors.active1,
        background: atoms.colors.accent2,
        border: '1px solid rgba(93, 14, 245, 0.2)',
      },

      hover: {
        color: atoms.colors.active1,
        background: atoms.colors.accent2,
        border: `1px solid ${atoms.colors.active1}`,
      },

      active: {
        color: atoms.colors.active1,
        background: atoms.colors.accent2,
        border: `1px solid ${atoms.colors.active1}`,
      },

      disabled: {
        color: atoms.colors.active1,
        background: atoms.colors.accent2,
        border: `1px solid ${atoms.colors.active1}`,
      },
    },
  };

  const topicTopline = {
    font: `12px/18px ${fonts.display}`,
    color: 'rgba(0, 0, 0, .6)',
    rubric: {
      font: `12px/18px ${fonts.display}`,
      color: atoms.colors.active1,
      border: 'none',
      borderRadius: 0,
      padding: 0,
      background: 'transparent',
      decoration: 'none',
      hover: {
        color: atoms.colors.active1,
        background: 'transparent',
        decoration: 'none',
      },
    },
  };

  const topicFooter = {
    links: {
      type: 'footer',
      showComma: true,
      borderBottom: `1px ${atoms.colors.gray2} solid`,
      font: texts.bodySmall.font,
      color: texts.bodySmall.color,
      title: {
        font: texts.bodySmall.font,
        color: texts.bodySmall.color,
      },
      hover: {
        borderBottom: 'none',
      },
      marginLeft: '5px',
    },
    socialMedia: {
      type: 'flat',
    },
    block: {
      interitemSpacing: 0,
      spacing: 20,
    },
  };

  const topicTags = {
    font: texts.bodySmall.font,
    color: texts.bodySmall.color,
    showHash: true,
  };

  const topicContent = {
    widgetsMaxWidth: 800,
    widgetsWithMaxWidth: {
      'expert': true,
    },
    tableOfContents: {
      listStyle: 'none',
      color: atoms.colors.accent,
      listPadding: '0',
      title: {
        color: atoms.colors.primary,
        font: `22px ${fonts.text}`,
      },
      link: {
        borderBottom: '1px dashed',
        color: atoms.colors.accent,
        hover: {
          color: atoms.colors.primary,
        },
      },
    },
  };

  const captionCredits = {
    textAlign: 'left',
    background: 'none',
    padding: 0,
    caption: {
      font: `16px/20px ${fonts.text}`,
      color: atoms.colors.caption,
    },
    credits: {
      font: `12px/18px ${fonts.text}`,
      color: atoms.colors.credits,
      margin: 0,
    },
  };

  const vikontSpoiler = {
    block: {
      unbordered: true,
      background: atoms.colors.accent2,
      innerIndent: '20px',
      interitemSpacing: 10,
      borderRadius: '8px',
    },
    body: {
      padding: '0 20px 20px',
    },
    title: {
      ...texts.incut,
    },
  };

  const vikontSideBySide = {
    image: {
      leftImage: {
        borderRadius: '8px',
      },
      rightImage: {
        borderRadius: '8px',
      },
    },
    caption: {
      margin: '15px 0 0 0',
      sideColor: atoms.colors.grey2,
    },
  };

  const vikontImage = {
    captionMargin: '15px 0 0 0',
  };

  const vikontReadMore = {
    block: {
      padding: '20px',
      borderRadius: 8,
      borderTop: `1px solid ${atoms.colors.grey1}`,
      borderBottom: `1px solid ${atoms.colors.grey1}`,
      borderLeft: `1px solid ${atoms.colors.grey1}`,
      borderRight: `1px solid ${atoms.colors.grey1}`,
    },
    title: {
      color: atoms.colors.primary,
      font: texts.bodySmall.font,
      marginBottom: '20px',
    },
    feed: {
      interitemSpacing: 0,
    },
    drum: {
      arrowsShiftVertical: 77,
      leftIcon: ArrowLeft40,
      rightIcon: ArrowRight40,
      spaceBetween: 20,
      sideIndent: 20,
    },
  };

  const vikontIncut = {
    borderWidth: '0',
    padding: '0',
  };

  const errorPage = {
    background: 'transparent',
    padding: '0',
    title: {
      font: `400 32px/41px ${fonts.display}`,
      color: atoms.colors.primary,
    },
    subtitle: {
      font: `400 22px/28px ${fonts.text}`,
      color: atoms.colors.primary,
    },
    buttonType: 'secondary',
  };

  const pages = {
    error: {
      ...errorPage,
      subtitle: {
        ...errorPage.subtitle,
        name: 'Что-то пошло не так',
      },
    },
    notFound: {
      ...errorPage,
      title: {
        ...errorPage.title,
        name: <ErrorPageHeader />,
      },
      subtitle: {
        ...errorPage.subtitle,
        name: 'Кина не будет, страница не найдена',
      },
    },
  };

  const wantToWatch = {
    headline: {
      color: '#fff',
      padding: '50px',
      font: `400 32px/1 ${fonts.display}`,
    },
  };

  const paginatron = {
    separationText: '/',
    round: false,
    withOuterButtons: false,
    btnType: 'primary',
    withoutBorder: true,
    NextPage: () => <span style={{ fontWeight: 500 }}>Вперед</span>,
    PrevPage: () => <span style={{ fontWeight: 500 }}>Назад</span>,
    text: {
      font: `16px/21.6px ${fonts.text}`,
      color: atoms.colors.primary,
    },
    radius: button.const.radius,
    justifyContent: 'space-between',
  };

  const gameCompare = {
    progressbar: {
      font: `400 12px/17px ${fonts.text}`,
      separationText: '/',
      strokeColor: 'transparent',
      background: 'linear-gradient(0deg, rgba(93, 14, 245, 0.3), rgba(93, 14, 245, 0.3)), radial-gradient(43.08% 110.17% at 3.56% 0.01%, #6C00FF 0%, #2D086A 100%)',
      iconFill: atoms.colors.layout,
      progressColor: atoms.colors.layout,
      vs: Progress,
      textColor: atoms.colors.layout,
      progressInside: true,
    },
    announce: {
      font: `400 18px/27px ${fonts.text}`,
      color: atoms.colors.grey2,
    },
    compare: {
      borderRadius: 10,
      progressType: 3,
      margin: '0 -57px',
      caption: {
        color: atoms.colors.layout,
        background: atoms.colors.primary,
      },
      credits: {
        color: atoms.colors.grey2,
      },
      draw: {
        backgroundColor: '#f6f1ff',
        backgroundText: 'linear-gradient(45deg, #5d03da, #2e096b)',
      },
      text: {
        background: '#efe7fe',
      },
    },
    tooltip: {
      buttonType: 'tertiary',
      scheme: {
        innerColor: '#ceb7fc',
        outerColor: atoms.colors.active1,
        titleFont: `700 18px/30px ${fonts.text}`,
        textFont: `400 16px/24px ${fonts.text}`,
      },
    },
    share: {
      color: atoms.colors.grey2,
      text: 'Поделиться:',
      font: `400 12px/15px ${fonts.display}`,
      showBorder: true,
      marginTop: '50px',
    },
    leaderBoard: {
      card: CompareCardS,
      table: {
        borderColor: atoms.colors.divider,
        background: atoms.colors.layout,
        activeBackground: atoms.colors.accent2,
        indent: NegativeMobile,
      },
      header: {
        color: atoms.colors.grey8,
        font: `400 12px/14px ${fonts.display}`,
        questionBackground: atoms.colors.grey5,
        questionFill: atoms.colors.grey2,
      },
      count: {
        background: atoms.colors.grey4,
        font: `700 15px/18px ${fonts.display}`,
        color: atoms.colors.primary,
        activeBackground: atoms.colors.layout,
        activeColor: atoms.colors.primary,
      },
      progressbar: {
        height: '1px',
        progressHeight: '3px',
        background: atoms.colors.grey6,
        defaultProgress: atoms.colors.primary,
        finalProgress: atoms.colors.primary,
        activeBackground: atoms.colors.grey6,
        font: `300 12px/17px ${fonts.text}`,
      },
      button: {
        siteButton: GameCompareButton,
      },
      caption: `400 16px/20px ${fonts.text}`,
      ratings: `500 15px/17px ${fonts.display}`,
      draw: {
        background: 'var(--radial, radial-gradient(64.64% 118.29% at 3.56% 0.01%, #6C00FF 0%, #2D086A 100%))',
      },
    },
    concidences: {
      font: `400 18px/26px ${fonts.text}`,
      margin: '0 0 15px',
      highlight: false,
    },
    winner: {
      icon: Winner,
      font: `900 26px/35px ${fonts.text}`,
      background: 'linear-gradient(to left top, #000000, #180735, #3f069a, #200a43, #000)',
      borderRadius: '4px',
      imageTop: '30px',
      strokeColor: atoms.colors.layout,
      padding: '30px',
      viewBox: '0 0 870 610',
      iconStep: 120,
      fillColor: atoms.colors.content,
    },
    indent: NarrowWrapper,
  };

  const smartTeaser = {
    gameCompare: {
      progressType: 5,
      border: {
        showBorder: true,
        borderValue: '1px solid #dddddd',
      },
      padding: '20px 0 32px',
      headline: {
        font: `500 24px/31px ${fonts.display}`,
        background: atoms.colors.accent2,
        center: true,
      },
      background: atoms.colors.content,
      readMore: {
        font: `400 12px/15px ${fonts.text}`,
        text: 'Читайте также:',
      },
    },
  };

  const interview = {
    question: {
      name: {
        color: atoms.colors.active1,
      },
    },
  };

  const authorPage = {
    heading: {
      font: `500 36px/47px ${fonts.display}`,
    },
  };

  const promoButton = {
    type: 'black',
  };


  return deepmerge({
    layout,
    fonts,
    icons,
    pages,
    texts,
    animations,
    controls: {
      link,
      shapka,
      scooter,
      drum,
      input,
      button,
      topicContent,
      topicTopline,
      topicFooter,
      topicTags,
      captionCredits,
      vikontSpoiler,
      vikontSideBySide,
      vikontImage,
      vikontReadMore,
      vikontIncut,
      gameCompare,
      wantToWatch,
      paginatron,
      smartTeaser,
      authorPage,
      interview,
      promoButton,
    },
  }, atoms);
}
