import PropTypes from 'prop-types';

import withTabs from 'core/components/withTabs';
import ThemeSwitch from 'core/components/ThemeSwitch';
import { NegativeMobile } from 'core/components/Wrappers';

import Background from 'site/pages/main/ReadAndWatch/components/Background';

import { modes } from 'site/pages/main/ReadAndWatch/constants';


function TabsData(props) {
  const {
    mode,
    readElement,
    watchElement,
  } = props;

  if (mode === modes.read) {
    return readElement;
  }

  return (
    <ThemeSwitch name='dark'>
      <NegativeMobile>
        <Background isActive>
          {watchElement}
        </Background>
      </NegativeMobile>
    </ThemeSwitch>
  );
}

TabsData.propTypes = {
  mode: PropTypes.string,
  readElement: PropTypes.node,
  watchElement: PropTypes.node,
};

function ReadAndWatchMobileWrapper(props) {
  const tabs = [
    { mode: modes.watch, title: 'Смотреть' },
    { mode: modes.read, title: 'Читать' },
  ];

  const Tabs = withTabs({
    tabs,
    justifyContent: 'center',
    activeButtonType: 'tab',
  })(TabsData);

  return <Tabs {...props} />;
}

export default ReadAndWatchMobileWrapper;
