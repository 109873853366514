import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import resolveRelationships from 'core/utils/relationships';
import Image from 'core/components/Image';
import Link from 'site/components/Link';
import styles from './index.styl';

import emptyPoster from 'site/images/empty-poster.png';

const [IDLE, LOADING, FAIL] = [
  'idle',
  'loading',
  'fail',
];

const relationships = resolveRelationships(
  ['image'], {}, { image: { versions: {} } }
);
const utm = '?utm_medium=smm&utm_source=blog&utm_campaign=mood';

const Poster = ({ content, isActive }) => {
  const [status, setStatus] = useState(LOADING);

  const {
    attributes: {
      source_url: sourceUrl,
    },
  } = content;

  const {
    image: {
      versions: {
        original: cover,
      },
    },
  } = relationships(content);

  const loading = status === LOADING;

  return (
    <>
      {loading && <div className={styles.spinner} />}
      <div className={cx(
        styles.poster,
        loading && styles.hidden,
        isActive && styles._active,
        !isActive && styles._inactive
      )}
      >
        <Link
          to={sourceUrl + utm}
        >
          {status !== FAIL
            ? (
              <Image
                src={cover}
                placement='cover'
                onImageLoadStart={() => setStatus(LOADING)}
                onImageLoaded={() => setStatus(IDLE)}
                onImageLoadingError={() => setStatus(FAIL)}
              />
            ) : (
              <Image
                src={{ rel_url: emptyPoster }}
                placement='cover'
              />
            )}
        </Link>
      </div>
    </>
  );
};

Poster.propTypes = {
  isActive: PropTypes.bool,
  content: PropTypes.object,
};

export default Poster;
