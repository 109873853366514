import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { denormalizeData } from 'core/utils/api';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import bindProps from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { Indent } from 'core/components/Wrappers';
import Feed from 'core/components/Feed';
import { SimpleLoader } from 'core/components/Loader';

import { WideWrapper } from 'site/components/Wrappers';

import Card, { CardHorizontalXS } from 'site/cards/CardHorizontal';


const CardWithImageDesktop = bindProps({
  size: 'xs',
  imageMaxWidth: 120,
  lockImageWidth: true,
  imageVersion: 'square',
})(Card);

const CardWithImageMobile = bindProps({
  size: 'xs',
  imageMaxWidth: 60,
  lockImageWidth: true,
  imageVersion: 'square',
})(Card);


function BorodaTopics(props, { bebopApi }) {
  const {
    isMobile,
    topic,
  } = props;

  const [content, setContent] = useState({ read: [], news: [] });
  const [loading, setLoading] = useState(true);

  const currentTopicId = topic?.content?.id;

  useEffect(() => {
    let ignore = false;

    Promise.allSettled([
      currentTopicId ? bebopApi
        .getTopics({
          limit: isMobile ? 1 : 3,
          list: 'read',
          sort: 'list',
          include: 'image,rubric',
          excluded_ids: currentTopicId,
        })
        .then(denormalizeData) : [],

      bebopApi
        .getTopics({
          limit: isMobile ? 3 : 9,
          include: 'tags',
          topic_type: 'news',
        })
        .then(denormalizeData),
    ])
      .then(([read, news]) => {
        if (!ignore) {
          setContent({
            read: read.value || [],
            news: news.value || [],
          });
          setLoading(false);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setLoading(false);
        }
      });

    return () => {
      ignore = true;
    };
  }, [currentTopicId, bebopApi, isMobile]);

  if (loading) return <SimpleLoader />;

  const {
    read,
    news,
  } = content;

  const hasNews = news.length > 0;
  const hasReadItems = read.length > 0;

  return (
    <WideWrapper>
      {hasNews && (
        <Indent bottom={isMobile ? 15 : 40}>
          <Feed
            limit={isMobile ? 3 : 9}
            card={CardHorizontalXS}
            content={news}
            interitemSpacing={30}
            columns={isMobile ? 1 : 3}
          />
        </Indent>
      )}
      {hasReadItems && (
        <Indent bottom={30}>
          <Feed
            card={isMobile ? CardWithImageMobile : CardWithImageDesktop}
            content={read}
            interitemSpacing={30}
            columns={isMobile ? 1 : 3}
          />
        </Indent>
      )}
    </WideWrapper>
  );
}

BorodaTopics.propTypes = {
  isMobile: PropTypes.bool,
  topic: modelPropTypes(topicAttributes),
};

BorodaTopics.contextTypes = {
  bebopApi: PropTypes.object,
};

export default withBreakpoint(BorodaTopics);
