import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import SmartImage from 'core/components/SmartImage';
import CaptionCredits from 'core/components/CaptionCredits';

import resolveRelationships from 'core/utils/relationships';

import {
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import styles from './index.styl';

const relationships = resolveRelationships(
  ['image'],
  {},
  { image: { versions: {} } },
);


function TopicHeaderImage({ content, isDesktop, theme }) {
  const {
    image: {
      versions,
      caption,
      credits,
      credits_url: creditsUrl,
      alt,
    },
  } = relationships(content);

  let maxWidth = parseFloat(theme.layout.maxWidth) - 2 * SIDE_INDENT_MOBILE;
  let maxHeight = 400;
  const { headline } = content.attributes;
  const titleText = caption || headline;
  const altText = alt || titleText;

  if (isDesktop) {
    maxWidth = parseFloat(theme.layout.contentColumnMaxWidth);
    maxHeight = 600;
  }

  return (
    <div className={styles.topicHeaderImage}>
      <style jsx>{`
        .caption
          margin 10px 0 0 0
      `}
      </style>
      <div className={styles.image}>
        <SmartImage
          versions={versions}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          placement='contain'
          alt={altText}
          title={titleText}
          fetchPriority='high'
          instant
        />
      </div>
      <div className='caption'>
        <CaptionCredits
          credits={credits}
          creditsUrl={creditsUrl}
        />
      </div>
    </div>
  );
}

TopicHeaderImage.propTypes = {
  content: PropTypes.object,
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(TopicHeaderImage));
