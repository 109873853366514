import PropTypes from 'prop-types';

import MarkdownWrapper from 'core/components/MarkdownWrapper';

import styles from './index.styl';


export default function Number(props) {
  const {
    block,
    parentProps,
  } = props;

  const {
    theme: {
      texts: {
        incut: incutAtoms,
        title2: title2Atoms,
      },
    },
  } = parentProps;

  if (!block) return null;

  const {
    unit,
    value,
    body,
  } = block.attributes;

  return (
    <div className={styles.wrapper}>
      <style jsx>{`
        .${styles.unit}
          color ${title2Atoms.color}
          font ${title2Atoms.font}
        .${styles.value}
          font ${incutAtoms.font}
      `}
      </style>
      <div className={styles.unit}>
        {value}{unit}
      </div>
      <div className={styles.value}>
        <MarkdownWrapper>
          {body}
        </MarkdownWrapper>
      </div>
    </div>
  );
}

Number.propTypes = {
  parentProps: PropTypes.object,
  block: PropTypes.object,
};
