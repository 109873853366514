import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';
import cx from 'classnames';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import resolveRelationships from 'core/utils/relationships';

import SmartImage from 'core/components/SmartImage';
import NextMovie from './Arrow';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
  ['read_more'],
  'tags',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  read_more: {
    topic_ids: [],
  },
});

function Emotion(props) {
  const {
    selection,
    isActive,
    isHidden,
    isMobile,
    onClick,
    onNextClick,
  } = props;

  if (!selection) return null;

  const {
    image: {
      versions: {
        emotion: emotion,
      },
    },
  } = relationships(selection);

  const handleMouseMove = e => {
    const elem = e.target;
    const rect = elem.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    elem.style.setProperty('--x', x + 'px');
    elem.style.setProperty('--y', y + 'px');
  };

  const handleMouseLeave = e => {
    const elem = e.target;
    elem.style.setProperty('--x', '0');
    elem.style.setProperty('--y', '0');
  };

  const handleNextClick = e => {
    e.stopPropagation();
    onNextClick();
  };

  const {
    headline,
  } = selection.attributes;

  return (
    <div className={cx(
      styles.wrapper,
      isActive && styles._active,
      isHidden && styles._hidden,
    )}
    >
      <div
        className={styles.emotion}
        onClick={onClick}
      >
        <div className={styles.image}>
          <SmartImage
            width={isMobile ? 140 : 240}
            src={emotion}
            placement='contain'
          />
        </div>
        <div
          className={styles.title}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          {headline}
          <div
            className={styles.nextMovie}
            onClick={handleNextClick}
          >
            <NextMovie />
          </div>
          <div className={styles.border} />
        </div>
      </div>
    </div>
  );
}

Emotion.propTypes = {
  isMobile: PropTypes.bool,
  isActive: PropTypes.bool,
  isHidden: PropTypes.bool,
  selection: PropTypes.object,
  onClick: PropTypes.func,
  onNextClick: PropTypes.func,
};

export default compose(
  withBreakpoint,
)(Emotion);
