import PropTypes from 'prop-types';
import { withBreakpoint } from 'core/components/breakpoint';

import Button from 'site/components/LandingButton';

import Arrow from '../../images/Arrow';
import Okko from 'site/icons/Okko';

import styles from '../index.styl';


const WatchButton = withBreakpoint(props => {
  const {
    isMobile,
    link,
  } = props;

  const OkkoIcon = (
    <Okko
      width={isMobile ? 47 : 74}
      height={isMobile ? 17 : 27}
      className={styles.okkoIcon}
    />
  );

  const ArrowIcon = (
    <Arrow
      width={isMobile ? '13.6' : '17'}
      height={isMobile ? '16' : '20'}
    />
  );

  return (
    <Button
      link={link}
      iconBefore={ArrowIcon}
      iconAfter={OkkoIcon}
      type='mamonty'
    >
      Смотреть в
    </Button>
  );
});

WatchButton.propTypes = {
  isMobile: PropTypes.bool,
  link: PropTypes.string,
};


export default WatchButton;
