import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.styl';


export default function Skeleton({ isDesktop }) {
  const paddingTopDesktop = `${(100 / (216 / 121))}%`;

  const movie = (
    <div className={styles.movie}>
      <div className={cx(styles.movieImage, 'lb-skeleton')} style={{ paddingTop: isDesktop ? paddingTopDesktop : 0 }} />
      <div className={cx(styles.movieInfo, 'lb-skeleton')} />
    </div>
  );

  const selection = (
    <div className={styles.selection}>
      <div className={cx(styles.selectionImage, 'lb-skeleton')} />
      <div className={cx(styles.selectionTitle, 'lb-skeleton')} />
    </div>
  );

  return (
    <div className={styles.watch}>
      <div className={styles.movies}>
        {movie}
        {movie}
        {movie}
        {movie}
      </div>
      <div className={styles.selections}>
        {selection}
        {selection}
      </div>
    </div>
  );
}

Skeleton.propTypes = {
  isDesktop: PropTypes.bool,
};
