import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import Rating from 'site/components/Rating';

import { getMovieDurationWithSuffix } from 'site/utils';

import styles from './index.styl';


function MovieInfo({ movieMeta = {}, classNames, theme }) {
  const {
    rating,
    release_date: releaseDate,
    duration,
    seasonsCount,
    episodesCount,
    genres,
  } = movieMeta;

  if (Object.keys(movieMeta).length === 0) return null;

  const {
    colors: {
      placeholder,
    },
    fonts: {
      text,
    },
  } = theme;

  const date = releaseDate && new Date(releaseDate);
  const year = date && date.getFullYear();
  const genresFormatted = Array.isArray(genres) ? genres.join(', ') : genres;
  const durationFormatted = getMovieDurationWithSuffix({ duration, seasonsCount, episodesCount });

  const items = [
    year,
    genresFormatted,
    durationFormatted,
  ].filter(Boolean);

  return (
    <div className={cx(styles.info, classNames)}>
      <style jsx>{`
        .${styles.info}
          color ${placeholder}
          font 12px/17px ${text}
      `}</style>
      {!!rating && <span><Rating className={styles.rating} rating={rating} /></span>}
      {items.join(', ')}
    </div>
  );
}

MovieInfo.propTypes = {
  theme: themePropTypes(`{
    colors: {
      placeholder,
    },
    fonts: {
      text,
    }
  }`),
  classNames: PropTypes.string,
  movieMeta: PropTypes.shape({
    rating: PropTypes.number,
    release_date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    genres: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    country: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    seasonsCount: PropTypes.number,
    episodesCount: PropTypes.number,
  }),
};

export default withTheme(MovieInfo);
