import deepmerge from 'core/libs/deepmerge';
import bindProps from 'core/components/bindProps';

import Arrows from 'core/components/Paginatron/Arrows';

import { CompareCardVerticalM } from 'site/cards/CompareCard';

import fonts from './fonts';

import { SIDE_INDENT_MOBILE } from 'site/constants';


const layout = {
  indents: {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20,
  },
};

export default function baseMobileTheme(atoms) {
  const texts = {
    lead: {
      font: `400 18px/27px ${fonts.display}`,
      color: atoms.colors.grey2,
    },

    body: {
      font: `400 16px/24px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    bodySmall: {
      font: `12px/15px ${fonts.display}`,
      color: atoms.colors.grey2,
    },

    incut: {
      font: `700 16px/24px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    h1: {
      font: `500 22px/28px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    h2: {
      font: `700 19px/25px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    h3: {
      font: `700 16px/24px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    get display1() {
      return this.h1;
    },

    get display2() {
      return this.h2;
    },

    get display3() {
      return this.h3;
    },

    get title1() {
      return this.h1;
    },

    get title2() {
      return this.h2;
    },

    get title3() {
      return this.h3;
    },
  };

  const captionCredits = {
    textAlign: 'left',
    padding: '10px 0 0',
    caption: {
      font: `12px/15px ${fonts.display}`,
      color: atoms.colors.caption,
    },
    credits: {
      font: `10px/12px ${fonts.display}`,
      color: atoms.colors.credits,
    },
  };

  const vikontSpoiler = {
    title: {
      ...texts.incut,
    },
    block: {
      innerIndent: '20px',
      outerIndent: '0 -20px',
      borderRadius: 0,
    },
    body: {
      font: texts.body.font,
    },
  };

  const vikontSideBySide = {
    image: {
      interimageSpacing: 0,
      useVerticalImages: false,
    },
    caption: {
      useVerticalCaptions: false,
      margin: '10px 20px 0',
    },
  };

  const vikontImage = {
    captionMargin: '0',
  };

  const vikontIncut = {
    padding: '0',
  };

  const shapkaMobileLink = {
    padding: '15px 10px',
    font: `500 16px/22px ${fonts.display}`,
  };

  const shapka = {
    padding: `15px ${SIDE_INDENT_MOBILE}px 4px`,
    height: 65,
    logo: {
      width: 180,
      height: 25,
      marginTop: '-7px',
    },
    menu: {
      centered: true,
      itemDivider: 'none',
    },
    link: shapkaMobileLink,
    dropdown: {
      link: shapkaMobileLink,
    },
  };

  const paginatron = {
    PrevPage: bindProps({ side: 'left' })(Arrows),
    NextPage: bindProps({ side: 'right' })(Arrows),
  };


  const scooter = {
    padding: `${SIDE_INDENT_MOBILE}px`,
    logo: {
      width: 180,
      height: 25,
    },
    menuLink: {
      font: `500 16px/1 ${fonts.display}`,
    },
  };

  const popcornButton = {
    popcornOffset: '-6px',
  };

  const vikontReadMore = {
    block: {
      margin: 0,
      padding: '20px 0',
      borderRadius: 0,
      borderTop: `1px solid ${atoms.colors.grey1}`,
      borderBottom: `1px solid ${atoms.colors.grey1}`,
      borderLeft: 'none',
      borderRight: 'none',
    },
    title: {
      marginBottom: '20px',
    },
    drum: {
      sideIndent: 0,
    },
  };

  const wantToWatch = {
    headline: {
      color: '#fff',
      padding: '25px 10px 10px',
      font: `400 24px/31px ${fonts.display}`,
    },
  };

  const gameCompare = {
    compare: {
      margin: 0,
      progressType: 3,
    },
    leaderBoard: {
      caption: `300 13px/19px ${fonts.text}`,
      modalWindow: {
        color: atoms.colors.primary,
      },
      card: CompareCardVerticalM,
    },
    winner: {
      borderRadius: 0,
      viewBox: '0 0 870 1200',
      padding: '20px 15px',
    },
    share: {
      marginTop: 0,
    },
  };

  const smartTeaser = {
    gameCompare: {
      headline: {
        font: `450 17px/21px ${fonts.display}`,
      },
      progressType: 6,
    },
  };

  const authorPage = {
    heading: {
      font: `500 22px/28px ${fonts.display}`,
    },
  };

  const topicContent = {
    widgetsWithNegativeMobileIndent: {
      image: false,
      readMore: false,
    },
  };

  const socializator = {
    fixedPosition: 'left',
  };

  return deepmerge({
    layout,
    texts,
    controls: {
      shapka,
      scooter,
      popcornButton,
      captionCredits,
      vikontSpoiler,
      vikontSideBySide,
      vikontImage,
      vikontReadMore,
      vikontIncut,
      gameCompare,
      wantToWatch,
      paginatron,
      smartTeaser,
      authorPage,
      topicContent,
      socializator,
    },
  }, atoms);
}
