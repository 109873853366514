import PropTypes from 'prop-types';

import modelPropTypes from 'core/utils/prop-types/model';

import Drum from 'core/components/Drum';

import CardFact from 'site/cards/CardFact';

import styles from './index.styl';


export default function Facts({ facts }) {
  if (!facts.length) return null;

  return (
    <div className={styles.facts}>
      <Drum
        content={facts}
        card={CardFact}
        itemWidth='100%'
        itemWidthMobile='100%'
        loop
      />
    </div>
  );
}

Facts.propTypes = {
  facts: PropTypes.arrayOf(
    modelPropTypes(
      PropTypes.shape({
        announce: PropTypes.string,
        source: PropTypes.string,
      })
    )
  ),
};
