import PropTypes from 'prop-types';

import Image from 'core/components/Image';
import Link from 'site/components/Link';

import Button from 'core/components/Button';
import bindProps from 'core/components/bindProps';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';
import pluralize from 'core/utils/pluralize';

import MovieInfo from './MovieInfo';

import styles from './index.styl';


function CardExternalMovie(props) {
  const {
    content,
    theme,
    imageWidth,
    imageHeight,
    movieContext,
    hideButton,
    coverName,
  } = props;

  const {
    attributes: {
      coverUrls,
      rating,
      title,
      link,
      releaseDate,
      genres,
      price,
      duration,
      seasonsCount,
      episodesCount,
    },
  } = content;

  const {
    colors: {
      placeholder,
    },
    fonts: {
      display,
      text,
    },
    animations,
  } = theme;

  const isAfisha = movieContext === 'afisha';
  const isOkko = movieContext === 'okko';

  let buttonText;
  const showPrice = price && !hideButton;
  if (showPrice) {
    if (isAfisha) buttonText = `Билеты в кино от ${price} ${pluralize(price, ['рубля', 'рублей', 'рублей'])}`;
    if (isOkko) buttonText = `Смотреть за ${price} ${pluralize(price, ['рубль', 'рубля', 'рублей'])}`;
  }

  const cover = coverUrls[coverName];
  const imageUrl = isOkko
    ? cover + `?width=${imageWidth * 2}&scale=2&quality=80&mediaType=jpeg`
    : cover;

  return (
    <div className={styles.card}>
      <style jsx>{`
        .${styles.card}
          width ${imageWidth}px

        .title
          font 500 13px/16px ${display}

        .${styles.info}
          color ${placeholder}
          font 12px/15px ${text}

        .${styles.image}
          transition ${animations.scale}

      `}</style>
      <Link
        to={link}
        type='primary'
      >
        <div className={styles.image}>
          <Image
            url={imageUrl}
            width={imageWidth}
            height={imageHeight}
            instant
          />
          {showPrice && (
            <div className={styles.buy}>
              <Button>{buttonText}</Button>
            </div>
          )}
        </div>
        <div className={styles.content}>
          <div className='title'>{title}</div>
          <MovieInfo
            classNames={styles.info}
            movieMeta={{
              rating,
              release_date: releaseDate,
              duration,
              seasonsCount,
              episodesCount,
              genres,
            }}
          />
        </div>
      </Link>
    </div>
  );
}

CardExternalMovie.defaultProps = {
  imageWidth: 407,
  imageHeight: 228,
  coverName: 'landscape',
};

CardExternalMovie.propTypes = {
  /**
   * Данные для отрисовки карточки
   */
  content: PropTypes.object,
  /** @ignore */
  theme: themePropTypes(`{
    fonts: {
      display,
      text,
    },
    colors: {
      placeholder,
    },
    animations: {
      scale,
    }
  }`),

  /**
   * Ширина картинки
   */
  imageWidth: PropTypes.number,

  /**
   * Высота картинки
   */
  imageHeight: PropTypes.number,

  /**
   * Данные от какой апишки рисует карточка.
   */
  movieContext: PropTypes.oneOf(['okko', 'afisha']),

  /**
   * Скрыть кнопку покупки
   */
  hideButton: PropTypes.bool,

  coverName: PropTypes.oneOf(['portrait', 'landscape']),
};

const Card = withTheme(CardExternalMovie);

export { CardExternalMovie as StorybookComponent };

export const CardExternalMovieType1 = bindProps({ movieContext: 'afisha' })(Card);

export const CardExternalMovieType2 = bindProps({
  imageWidth: 152,
  imageHeight: 228,
  movieContext: 'afisha',
  hideButton: true,
  coverName: 'portrait',
})(Card);

export const CardExternalMovieType3 = bindProps({
  imageWidth: 280,
  imageHeight: 168,
  movieContext: 'afisha',
})(Card);

export const CardExternalMovieType4 = bindProps({ imageWidth: 275, imageHeight: 155, movieContext: 'okko', hideButton: true })(Card);

export const CardExternalMovieType5 = bindProps({ imageWidth: 216, imageHeight: 121, movieContext: 'okko', hideButton: true })(Card);

export const CardExternalMovieType6 = bindProps({ imageWidth: 407, imageHeight: 228, movieContext: 'okko' })(Card);

export const CardExternalMovieType7 = bindProps({ imageWidth: 280, imageHeight: 168, movieContext: 'okko' })(Card);

export default Card;
