import PropTypes from 'prop-types';

import AboutCore from 'core/components/About';

import { aboutFetcher } from 'core/fetchers/about';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';

import { NarrowWrapper } from 'site/components/Wrappers';


function About({ content }) {
  return (
    <NarrowWrapper>
      <AboutCore
        content={content}
        mapSrc='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1244.9407473844149!2d30.20158288505679!3d59.98658873823275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469636620d5d67e7%3A0x5d405f2ec4b4c3ab!2sOkko!5e0!3m2!1sen!2skz!4v1686745802976!5m2!1sen!2skz'
      />
    </NarrowWrapper>
  );
}

About.propTypes = {
  content: PropTypes.object,
};

const dataProvider = resolve('content', aboutFetcher());

export default withPageHocs(dataProvider)(About);
