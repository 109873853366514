const palette = {
  black: '#000',
  white: '#fff',

  violet900: '#1c0d41',
  violet800: '#291c57',
  violet700: '#302563',
  violet600: '#382e6d',
  violet500: '#3e3575',
  violet400: '#565188',
  violet300: '#706e9b',
  violet200: '#9796b8',
  violet100: '#bfbfd5',
  violet50: '#e5e5ee',

  electroPurple900: '#190171',
  electroPurple800: '#2A0292',
  electroPurple700: '#3B06B3',
  electroPurple600: '#4C0AD4',
  electroPurple500: '#5D0EF5',
  electroPurple400: '#744EF5',
  electroPurple300: '#9677F7',
  electroPurple200: '#B8A1F9',
  electroPurple100: '#D9CBFB',
  electroPurple50: '#EDE8FD',

  gray900: '#262626',
  gray800: '#484848',
  gray700: '#676767',
  gray600: '#7b7b7b',
  gray500: '#a5a5a5',
  gray400: '#c3c3c3',
  gray300: '#e6e6e6',
  gray200: '#f1f1f1',
  gray100: '#f6f6f6',
  gray50: '#fbfbfb',

  skyBlue900: '#7566c1',
  skyBlue800: '#8387e3',
  skyBlue700: '#8a99f6',
  skyBlue600: '#93adff',
  skyBlue500: '#9abdff',
  skyBlue400: '#9ec8ff',
  skyBlue300: '#aad4ff',
  skyBlue200: '#bfe1ff',
  skyBlue100: '#d7ebff',
  skyBlue50: '#f0f7ff',

  mauve: '#f6f1ff',
};

export default palette;
