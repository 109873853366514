import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  getMovieDurationWithSuffix,
  replaceNewLineWithComma,
} from 'site/utils';

export default function MovieMeta({ meta, size }) {
  const {
    releaseYear,
    genres,
    duration,
    countries,
    ageAccess,
  } = meta;

  const metaFirst = [
    releaseYear,
    getMovieDurationWithSuffix({ duration }),
  ].filter(Boolean);

  const metaSecond = [
    replaceNewLineWithComma(countries, 2, ' и др.'),
    typeof ageAccess === 'number' && !isNaN(ageAccess) ? ageAccess + '+' : ageAccess,
  ].filter(Boolean);

  const displayGenres = replaceNewLineWithComma(genres);
  const genresTitle = genres.split('\n').length > 1 ? 'Жанры' : 'Жанр';

  return (
    <div className={cx('meta', `_size_${size}`)}>
      <style jsx>{`
        .meta
          font-size 16px
          line-height 21px

          &._size_s
            font-size 12px
            line-height 15px

        .metaFirst
          text-transform lowercase
      `}
      </style>
      <div>{genresTitle}: {displayGenres}</div>
      <div className='metaFirst'>
        {metaFirst.join(', ')}
      </div>
      {metaSecond.join(', ')}
    </div>
  );
}

MovieMeta.propTypes = {
  size: PropTypes.oneOf(['s', 'm']),
  meta: PropTypes.shape({
    releaseYear: PropTypes.string,
    genres: PropTypes.string,
    duration: PropTypes.string,
    countries: PropTypes.string,
    ageAccess: PropTypes.number,
  }),
};

MovieMeta.defaultProps = {
  size: 'm',
};
