import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint } from 'core/components/breakpoint';
import { PageIndent } from 'core/components/Wrappers';

import isNotEmptyReactElementContent from 'core/utils/content';


function WideWrapper(props) {
  const {
    children,
    rightOnly,
    leftOnly,
    isMobile,
  } = props;

  if (!isNotEmptyReactElementContent(children)) return null;

  if (isMobile) {
    return (
      <PageIndent
        {...leftOnly && { right: 0 }}
        {...rightOnly && { left: 0 }}
      >
        {children}
      </PageIndent>
    );
  }

  return (
    <div
      className={cx(
        'wideWrapper',
        leftOnly && '_skipRight',
        rightOnly && '_skipLeft',
      )}
    >
      <style jsx>{`
        .wideWrapper
          margin-left var(--wide-wrapper-margin)
          margin-right var(--wide-wrapper-margin)
          &._skipLeft
            margin-left 0
          &._skipRight
            margin-right 0
      `}</style>
      {children}
    </div>
  );
}

WideWrapper.propTypes = {
  isMobile: PropTypes.bool,
  leftOnly: PropTypes.bool,
  rightOnly: PropTypes.bool,
};

export default withBreakpoint(WideWrapper);
