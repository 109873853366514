import Link from 'core/components/Link';
import Indent from 'core/components/Wrappers/Indent';

import styles from '../index.styl';

function TermsOfUse() {
  return (<>
    <p>
      Вступили в силу 23 мая 2023 года.
    </p>
    <Indent bottom={40} >
      <i>Настоящие условия использования (далее – Условия) определяют общие условия, на которых Провайдер предоставляет Ресурс. Вам, Пользователю, необходимо внимательно ознакомиться с текстом Условий. В случае несогласия с любым из положений, Вы не должны использовать Ресурс.</i>
    </Indent>
    <ol>
      <li>
        <h3 className={styles.subtitle}>Термины</h3>
        <ol>
          <li><b>Провайдер</b> – юридическое лицо, предоставляющее Пользователям Ресурс. Наименование Провайдера размещено на Ресурсе.</li>
          <li><b>Ресурс</b> – сайт, проект, сервис и/или иной продукт в сети Интернет, предоставляемый Провайдером.</li>
          <li><b>Пользователь</b> – физическое лицо, использующее Ресурс и обладающее необходимой дееспособностью для заключения и исполнения Условий.</li>
          <li><b>Контент</b> – дизайн, графика, изображения, фотографии, тексты, статьи, видео, музыка, песни, компьютерные программы, базы данных, товарные знаки, логотипы, любые иные результаты интеллектуальной деятельности и средства индивидуализации, размещенные или размещаемые на Ресурсе.</li>
          <li><b>Документы</b> – совместно Условия, политика конфиденциальности и иные документы, размещенные в соответствующем разделе Ресурса.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Действие условий</h3>
        <ol>
          <li>Условия заключаются в соответствии с нормами законодательства РФ, регулирующими принятие публичной оферты конклюдентными действиями.</li>
          <li>Условия считаются заключенными с момента их принятия Пользователем. Осуществление Пользователем доступа к Ресурсу означает, что Пользователь полностью и безоговорочно принял Условия. Условия обязательны для исполнения Пользователем и Провайдером с момента их заключения. Если Пользователь не согласен с Условиями, он не вправе использовать Ресурс.</li>
          <li>Условия могут быть изменены Провайдером в одностороннем внесудебном порядке. Анонсирование изменений и публикация измененных Условий производится на Ресурсе. Используя Ресурс после изменения Условий, Пользователь принимает Условия полностью и безоговорочно в измененном виде. </li>
          <li>Ресурсом может быть предусмотрено предоставление Пользователю дополнительного функционала, правила использования которого регулируются в соответствии с отдельными документами.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Использование ресурса</h3>
        <ol>
          <li>Предоставляя Ресурс, Провайдер ожидает от Пользователей, что они будут соблюдать права и законные интересы других Пользователей и иных третьих лиц, а также законодательство РФ. Более того, это является необходимым и обязательным условием использования Ресурса.</li>
          <li>Пользователь осознает и соглашается, что Ресурс может содержать рекламу. При этом Пользователь не вправе размещать на Ресурсе рекламу и ссылки на рекламу.</li>
          <li>Ресурс предназначен для личного некоммерческого использования, не связанного с осуществлением предпринимательской деятельности.</li>
          <li>Пользователь самостоятельно несет ответственность за все действия, совершаемые им на Ресурсе, за нарушение законодательства РФ, Условий и иных Документов.</li>
          <li>Ресурс предоставляется «как есть». Провайдер не гарантирует, что Ресурс и результат его использования будут удовлетворять ожиданиям Пользователя. Пользователь принимает все последствия и самостоятельно несёт все возможные риски, связанные с использованием Ресурса.</li>
          <li>Провайдер не несет ответственности за любые виды убытков, произошедшие вследствие использования Ресурса и/или его отдельных функций.</li>
          <li>Провайдер предоставляет Пользователю возможность получать в связи или в результате использования Ресурса сообщения информационного, новостного, рекламного или иного характера от Провайдера и/или ее партнеров. Условия предоставления таких сообщений регулируются <Link to={'/legal/mailing-rules'}>Правилами рассылок</Link>.</li>
          <li>В целях улучшения качества и удобства использования Ресурса, повышения его эффективности и разработки новых продуктов, а также проведения статистических и иных исследований на Ресурсе используются cookies (небольшие фрагменты данных о прошлых посещениях). Пользователь может просмотреть срок действия cookies, а также запретить их сохранение в настройках своего браузера.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Иное</h3>
        <ol>
          <li>Отношения Провайдера и Пользователя, в том числе предусмотренные Условиями, регулируются законодательством РФ.</li>
          <li>Все споры, возникающие из Условий и/или связанные с Ресурсом и Контентом, могут быть переданы на разрешение суда после принятия Сторонами мер по досудебному урегулированию спора по истечении 30 (тридцати) календарных дней со дня направления первой претензии. Если спор не урегулирован в досудебном порядке согласно настоящему пункту, он может быть передан на разрешение суда по месту нахождения Провайдера.</li>
          <li>Юридически значимые сообщения в адрес Провайдера направляются по адресу: <br />
            <i>197374, г. Санкт-Петербург, ул. Савушкина, д. 126 лит. Б, помещ. 71-Н.</i>
          </li>
          <li>По настоящим Условиям Провайдер предоставляет Ресурс безвозмездно, поэтому нормы о защите прав потребителей не применяются.</li>
        </ol>
      </li>
    </ol>
  </>
  );
}


export default TermsOfUse;
