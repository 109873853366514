import PropTypes from 'prop-types';

import { rawAuthorsFetcher } from 'core/fetchers/authorsPage';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import AuthorsPage from 'core/components/AuthorsPage';
import H1 from 'core/components/H1';
import { PageIndent, Indent } from 'core/components/Wrappers';

import AuthorsPageCard from 'site/cards/CardAuthor/AuthorsPageCard';
import Boroda from 'site/components/Boroda';
import { NarrowWrapper } from 'site/components/Wrappers';


function Authors(props) {
  const {
    rawAuthors,
    isMobile,
  } = props;

  return (
    <>
      <PageIndent>
        <NarrowWrapper>
          <Indent top={20} bottom={isMobile ? 20 : 30}>
            <H1>Авторы</H1>
          </Indent>
          <AuthorsPage
            rawAuthors={rawAuthors}
            card={AuthorsPageCard}
            columns={2}
            interitemSpacing={20}
          />
        </NarrowWrapper>
      </PageIndent>
      <Boroda />
    </>
  );
}

Authors.propTypes = {
  rawAuthors: PropTypes.object,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  rawAuthors: rawAuthorsFetcher(),
});

export default withPageHocs(dataProvider)(withBreakpoint(Authors));
