import PropTypes from 'prop-types';
import queryString from 'core/libs/query-string';

import { withRouter } from 'core/libs/router';

import pluralize from 'core/utils/pluralize';
import { buildSearchLink } from 'core/utils/url-helper';

import withTheme from 'core/components/theme';
import Tag from 'core/components/Tag';
import Tags from 'core/components/Tags';

import NothingFound from 'site/components/NothingFound';

import styles from './index.styl';

const foundText = (total) => {
  const foundWord = pluralize(total, [
    'Найден',
    'Найдено',
    'Найдено',
  ]);

  const resultWord = pluralize(total, [
    'результат',
    'результата',
    'результатов',
  ]);

  return `${foundWord} ${total} ${resultWord}`;
};

function SearchFilter({
  theme,
  filters,
  total,
  location: {
    search,
  },
}) {
  const params = queryString.parse(search);
  const { query, ...filterParams } = params;

  const mediaUrlPrefix = '';
  const tagType = 'ghost';
  const tagSize = 'small';
  const tagTypeActive = 'primaryGray';
  const hasFilterParams = Object.keys(filterParams).length > 0;

  const tagButtons = filters.map((item, index) => {
    const link = buildSearchLink(mediaUrlPrefix, query, item.key, item.value);

    const isAllTag = item.key === undefined;
    const isActive = isAllTag
      ? !hasFilterParams
      : params[item.key] === item.value;

    return (
      <Tag
        key={index}
        type={isActive ? tagTypeActive : tagType}
        state={isActive ? 'idle' : 'auto'}
        inactive={isActive}
        link={link}
        minWidth={0}
        size={tagSize}
      >
        {item.title}
      </Tag>
    );
  });

  return (
    <>
      <style jsx>{`
        .heading
          font 400 12px/15px ${theme.fonts.text}
          color ${theme.colors.gray2}
      `}</style>
      <div className={styles.filterWrapper}>
        <div className='heading'>
          {foundText(total)}
        </div>
        {tagButtons.length > 0 && (
          <div className={styles.tags}>
            <Tags >{tagButtons}</Tags>
          </div>
        )}
      </div>
      {total === 0 &&  <NothingFound />}
    </>
  );
}


SearchFilter.propTypes = {
  theme: PropTypes.object,
  /**
   * Объект с фильтрами
   */
  filters: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    title: PropTypes.string.isRequired,
  })),
  /**
   * Число для отображения количества результатов
   */
  total: PropTypes.number,
  /**
   * @ignore
   */
  location: PropTypes.object,
};
export default withRouter(withTheme(SearchFilter));
