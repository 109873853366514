export default {
  afisha: {
    title: 'Хочу в кино',
  },
  freeMovies: {
    title: 'Новое в подписке',
  },
  freeSeries: {
    title: 'Лучшее в подписке',
  },
  shopPopulars: {
    title: 'Магазин популярных фильмов',
  },
  tvChanels: {
    title: 'Телеканалы',
  },
};
