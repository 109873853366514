export const grid = [
  [null, null, null, null, null, null, null, null, null, 2, null, null, null, null],
  [null, null, null, null, null, null, 1, { letter: '', words: [1] }, { letter: '', words: [1] }, { letter: '', words: [1, 2] }, { letter: '', words: [1] }, null, null, null],
  [null, null, null, null, null, null, null, 4, null, { letter: '', words: [2] }, null, null, null, null],
  [null, null, null, null, null, null, 3, { letter: '', words: [3, 4] }, { letter: '', words: [3] }, { letter: '', words: [3, 2] }, { letter: '', words: [3] }, null, null, null],
  [null, null, null, null, null, null, null, { letter: '', words: [4] }, null, { letter: '', words: [2] }, null, null, null, null],
  [null, null, null, 6, null, null, null, { letter: '', words: [4] }, null, null, null, null, null, null],
  [null, 8, 5, { letter: '', words: [5, 6] }, { letter: '', words: [5] }, { letter: '', words: [5] }, { letter: '', words: [5] }, { letter: '', words: [4, 5] }, null, null, null, null, null, null],
  [null, { letter: '', words: [8] }, null, { letter: '', words: [6] }, null, null, null, { letter: '', words: [4] }, null, null, null, null, null, null],
  [7, { letter: '', words: [7, 8] }, { letter: '', words: [7] }, { letter: '', words: [6, 7] }, { letter: '', words: [7] }, null, null, null, null, null, null, null, null, null],
  [null, { letter: '', words: [8] }, null, { letter: '', words: [6] }, null, null, null, 10, null, null, null, null, null, null],
  [null, { letter: '', words: [8] }, null, null, null, null, null, { letter: '', words: [10] }, null, null, null, null, null, null],
  [9, { letter: '', words: [8, 9] }, { letter: '', words: [9] }, { letter: '', words: [9] }, { letter: '', words: [9] }, { letter: '', words: [9] }, { letter: '', words: [9] }, { letter: '', words: [9, 10] }, null, null, null, null, null, null],
  [null, null, null, null, null, 12, null, { letter: '', words: [10] }, null, null, null, null, null, null],
  [null, null, 11, { letter: '', words: [11] }, { letter: '', words: [11] }, { letter: '', words: [11, 12] }, { letter: '', words: [11] }, { letter: '', words: [10, 11] }, null, null, null, null, null, null],
  [null, null, null, null, null, { letter: '', words: [12] }, null, null, null, null, null, null, null, null],
  [null, null, null, null, null, { letter: '', words: [12] }, null, null, null, null, null, null, null, null],
  [null, null, null, null, 13, { letter: '', words: [12, 13] }, { letter: '', words: [13] }, { letter: '', words: [13] }, { letter: '', words: [13] }, { letter: '', words: [13] }, null, null, null, null],
  [null, null, null, null, null, { letter: '', words: [12] }, null, 15, null, null, null, null, null, null],
  [null, null, null, null, null, { letter: '', words: [12] }, null, { letter: '', words: [15] }, null, null, 17, null, null, null],
  [null, 14, { letter: '', words: [14] }, { letter: '', words: [14] }, { letter: '', words: [14] }, { letter: '', words: [12, 14] }, { letter: '', words: [14] }, { letter: '', words: [14, 15] }, null, null, { letter: '', words: [17] }, null, null, null],
  [null, null, null, null, null, 18, null, { letter: '', words: [15] }, null, 19, { letter: '', words: [17, 19] }, { letter: '', words: [19] }, { letter: '', words: [19] }, { letter: '', words: [19] }],
  [null, null, null, null, null, { letter: '', words: [18] }, null, { letter: '', words: [15] }, null, null, { letter: '', words: [17] }, null, null, null],
  [null, null, 16, { letter: '', words: [16] }, { letter: '', words: [16] }, { letter: '', words: [16, 18] }, { letter: '', words: [16] }, { letter: '', words: [15, 16] }, { letter: '', words: [16] }, { letter: '', words: [16] }, { letter: '', words: [16, 17] }, { letter: '', words: [16] }, null, null],
  [null, null, null, null, null, { letter: '', words: [18] }, null, { letter: '', words: [15] }, null, null, { letter: '', words: [17] }, null, null, null],
  [null, null, null, null, null, { letter: '', words: [18] }, null, { letter: '', words: [15] }, null, 20, { letter: '', words: [17, 20] }, { letter: '', words: [20] }, { letter: '', words: [20] }, { letter: '', words: [20] }],
  [null, null, null, null, null, { letter: '', words: [18] }, null, null, null, null, null, null, null, null],
  [null, null, null, null, null, { letter: '', words: [18] }, null, null, null, null, null, null, null, null],
  [null, null, null, null, null, { letter: '', words: [18] }, null, null, null, null, null, null, null, null],
];

export const data = {
  1: {
    question: 'Хватит ___ точить, все равно ни о чем разговариваете, пойдемте лучше поможете',
    answer: 'лясы',
    direction: 'horizontal',
    start: { row: 1, col: 7 },
  },
  2: {
    direction: 'vertical',
    start: { row: 1, col: 9 },
    question: 'Да это точно ___, они пытаются развести тебя на деньги',
    answer: 'скам',
  },
  3: {
    question: 'Этот актер такой красивый, он мой ___',
    answer: 'краш',
    direction: 'horizontal',
    start: { row: 3, col: 7 },
  },
  4: {
    question: 'Сегодня произошел такой ___ - я упал в лужу лицом',
    answer: 'кринж',
    direction: 'vertical',
    start: { row: 3, col: 7 },
  },
  5: {
    question: 'О, смотри, он постригся, сейчас ему ___ поставим',
    answer: 'фофан',
    direction: 'horizontal',
    start: { row: 6, col: 3 },
  },
  6: {
    question: 'Он создал ___ моего аккаунта и теперь пишет друзьям и просит занять деньги!',
    answer: 'фейк',
    direction: 'vertical',
    start: { row: 6, col: 3 },
  },
  7: {
    question: 'Сегодня супер-день, настроение +___ ',
    answer: 'вайб',
    direction: 'horizontal',
    start: { row: 8, col: 1 },
  },

  8: {
    question: 'Чтобы разблокировать телефон, тебе нужно сделать ___ пальцем снизу вверх',
    answer: 'свайп',
    direction: 'vertical',
    start: { row: 7, col: 1 },
  },
  9: {
    question: 'Мне с работы наконец пришла ___, куплю себе колонку',
    answer: 'получка',
    direction: 'horizontal',
    start: { row: 11, col: 1 },
  },
  10: {
    question: 'Вот это ___ ты себе сделала! Прическа, как у панка',
    answer: 'хаер',
    direction: 'vertical',
    start: { row: 10, col: 7 },
  },
  11: {
    question: '___! Они идут, прячься!',
    answer: 'шухер',
    direction: 'horizontal',
    start: { row: 13, col: 3 },
  },
  12: {
    question: 'Это конечно не полноценная работа, но хоть какая-то ___, денег смогу получить',
    answer: 'халтура',
    direction: 'vertical',
    start: { row: 13, col: 5 },
  },
  13: {
    question: 'Состояние - полный ___, мне грустно и меня просто бесит эта ситуация ',
    answer: 'тильт',
    direction: 'horizontal',
    start: { row: 16, col: 5 },
  },
  14: {
    question: 'Рано тебе еще, ты пока ___, учись у старших!',
    answer: 'салага',
    direction: 'horizontal',
    start: { row: 19, col: 2 },
  },
  15: {
    question: 'Вместо того, чтобы ___ бить, лучше бы делом занялся ',
    answer: 'баклуши',
    direction: 'vertical',
    start: { row: 18, col: 7 },
  },
  16: {
    question: 'То есть он купил подержанную машину, а теперь перепродает ее тебе дороже? Так он же ___! ',
    answer: 'спекулянт',
    direction: 'horizontal',
    start: { row: 22, col: 3 },
  },
  17: {
    question: 'Видишь, на рабочем столе находится ___ браузера?',
    answer: 'иконка',
    direction: 'vertical',
    start: { row: 19, col: 10 },
  },
  18: {
    question: 'Она тебе не отвечает? Попробуй ___ ее в нашем общем чате',
    answer: 'тегнуть',
    direction: 'vertical',
    start: { row: 21, col: 5 },
  },
  19: {
    question: 'Смотри, чтобы открыть эту папку, тебе нужно сделать двойной ___',
    answer: 'клик',
    direction: 'horizontal',
    start: { row: 20, col: 10 },
  },
  20: {
    question: '___ со мной гулять',
    answer: 'айда',
    direction: 'horizontal',
    start: { row: 24, col: 10 },
  },
};
