import PropTypes from 'prop-types';
import ListPage from 'core/components/ListPage';

import { rawTopicsFetcher } from 'core/fetchers/listPage';

import resolve from 'core/resolver/resolve';

import H1 from 'core/components/H1';
import Page from 'core/components/Page';
import { Indent, PageIndent } from 'core/components/Wrappers';
import { withBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import RubricTopics from 'site/components/RubricTopics';
import { NarrowWrapper } from 'site/components/Wrappers';
import Pagination from 'site/components/Pagination';

import { mamontyPromoMap } from 'site/constants';

const limit = 10;

const PaginationWithIndents = (props) => {
  return (
    <Indent top={20}>
      <Pagination {...props} />
    </Indent>
  );
};

function NewsPage(props) {
  const {
    rawData,
    isDesktop,
  } = props;

  return (
    <Page>
      <PageIndent>
        <NarrowWrapper>
          <Indent top={isDesktop ? 0 : 20} bottom={isDesktop ? 20 : 10}>
            <H1>Новости</H1>
          </Indent>
          <ListPage
            rawData={rawData}
            title='Последние новости мира кино'
            description='Последние новости мира кино! Каждый день наше СМИ работает, чтобы вы получали свежие новости из мира кино и факты из жизни любимых актеров.'
            limit={limit}
            pagination={PaginationWithIndents}
            skipRcm
          >
            {({ content }) => (
              <RubricTopics
                topics={content}
                topicType='news'
                firstTopicPromo={mamontyPromoMap['/news']}
              />
            )}
          </ListPage>
        </NarrowWrapper>
      </PageIndent>
    </Page>
  );
}

NewsPage.propTypes = {
  rawData: PropTypes.object,
  isDesktop: PropTypes.bool,
};

const dataProvider = resolve({
  rawData: rawTopicsFetcher({
    limit,
    topic_type: 'news',
    include: 'tags',
    fields: 'link,list_headline,headline,published_at',
  }),
});

export default withPageHocs(dataProvider)(withBreakpoint(NewsPage));
