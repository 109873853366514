import PropTypes from 'prop-types';
import cx from 'classnames';
import getImageUrl from 'core/utils/smart-image';
import { NarrowWrapper } from 'site/components/Wrappers';

import styles from './index.styl';

function QuizWrapper(props, { webdavHost, thumborHost }) {
  const {
    children,
    backgroundImage,
  } = props;

  const backgroundUrl = backgroundImage ?
    getImageUrl({
      src: backgroundImage,
      dpr: 2,
      smart: true,
      maxWidth: 2000,
      maxHeight: 2000,
      webdavHost,
      thumborHost,
    }) :
    null;

  return (
    <div className={cx(styles.quiz, { [styles.customBg]: !!backgroundUrl })}>
      <style jsx>{`
        .${styles.quiz}
          &:after
            background-image url('/images/quiz-bg.png')

          &.${styles.customBg}:after
            background-image url('${backgroundUrl}')
      `}</style>
      <NarrowWrapper>
        {children}
      </NarrowWrapper>
    </div>
  );
}

QuizWrapper.propTypes = {
  backgroundImage: PropTypes.object,
};

QuizWrapper.contextTypes = {
  webdavHost: PropTypes.string,
  thumborHost: PropTypes.string,
};

export default QuizWrapper;
