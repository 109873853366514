import PropTypes from 'prop-types';
import { useRef, useEffect, useState } from 'react';
import cx from 'classnames';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';
import skip from 'core/resolver/skip';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import title from '../images/title.png';
import about from '../images/about.png';
import Arrow from '../images/Arrow';
import Okko from 'site/icons/Okko';

import TextBlock from '../TextBlock';
import Button from '../Button';

import styles from './index.styl';

const WatchButton = withBreakpoint(props => {
  const {
    isMobile,
    link,
  } = props;

  const OkkoIcon = (
    <Okko
      width={isMobile ? 47 : 74}
      height={isMobile ? 17 : 27}
      className={styles.okkoIcon}
    />
  );

  const ArrowIcon = (
    <Arrow
      width={isMobile ? '13.6' : '17'}
      height={isMobile ? '16' : '20'}
    />
  );

  return (
    <Button
      link={link}
      iconBefore={ArrowIcon}
      iconAfter={OkkoIcon}
    >
      Смотреть в
    </Button>
  );
});

WatchButton.propTypes = {
  isMobile: PropTypes.bool,
  link: PropTypes.string,
};


function AboutSeries({ isMobile, theme }) {
  const buttonRef = useRef(null);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    const currentButton = buttonRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsButtonVisible(!entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    if (currentButton) {
      observer.observe(currentButton);
    }

    return () => {
      if (currentButton) {
        observer.unobserve(currentButton);
      }
    };
  }, []);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.toLook}
          font-family ${theme.fonts.likhie}
      `}</style>
    </scope>
  );

  const iframe = (
    <div className={styles.iframe}>
      <iframe
        src='https://vk.com/video_ext.php?oid=-47393707&id=456244538&hash=3f3ab30fb26373ae'
        width={isMobile ? '100%' : '670'}
        frameBorder='0'
        allowFullScreen='1'
        allow='autoplay; encrypted-media; fullscreen; picture-in-picture'
      />
    </div>
  );

  const id = !isMobile && 'about-series-desktop';

  return (
    <div {...id && { id }} className={cx(styles.aboutSeries, isButtonVisible && styles._isButtonVisible)}>
      <div className={styles.leftSide}>
        <img
          src={title}
          className={styles.title}
          alt='Лихие'
        />

        {isMobile && iframe}

        <div className={styles.button} ref={buttonRef}>
          <WatchButton link='https://okko.tv/serial/likhie?utm_source=blog&utm_medium=creative&utm_campaign=landing-likhie-header-button' />
        </div>

        <img
          src={about}
          className={styles.about}
          alt='about'
        />

        <TextBlock className={styles.description}>
          <p>
            Хабаровский край. Павел Лиховцев, старший егерь и охотник на медведей, держит в тайге пасеку и воспитывает старшего сына Женьку настоящим мужиком. Но с приходом девяностых пасека разоряется. Павел с семьёй вынужден ехать в Хабаровск в поисках лучшей жизни.
          </p>
          <p>
            В это время в Хабаровске вся власть и все ресурсы переходят к ОПГ «Общак» — банде, которая вскоре станет крупнейшей криминальной группировкой в мире.
          </p>
          <p>
            Устроившись на работу водителем к Юре Крабу, бывшему соседу по посёлку и крёстному Женьки, Павел быстро попадает в эпицентр бандитских разборок.
          </p>
          <p>
            Двенадцатилетний Женя восхищается отцом, полностью ему доверяет и с открытым ртом глядит на крутых хозяев жизни — братков. Взрослея, Женя вливается в ОПГ Краба, который решился бросить вызов всесильному лидеру «Общака», и готов снова начать криминальную войну на Дальнем Востоке.
          </p>
        </TextBlock>
      </div>

      {!isMobile && (
        <div className={styles.rightSide}>
          {!isMobile && iframe}
        </div>
      )}

      <div className={styles.animatedBtn}>
        <WatchButton link='https://okko.tv/serial/likhie?utm_source=blog&utm_medium=creative&utm_campaign=landing-likhie-fixed-button' />
      </div>
      <scope.styles />
    </div>
  );
}

AboutSeries.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(skip(AboutSeries)));
