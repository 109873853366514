import PropTypes from 'prop-types';

import Link from 'site/components/Link';
import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import GradientButton from 'site/components/GradientButton';
import ArrowRightThin from 'site/icons/ArrowRightThin.svg';

import { OKKO_TV_URL } from 'site/constants';

import Popcorn from './Popcorn.svg';

import styles from './index.styl';

function PopcornButton({ className, theme, isSimple }) {
  const { popcornOffset } = theme.controls.popcornButton;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.popcorn}
          top ${popcornOffset}

        .link
          display flex
          text-decoration none
          flex-direction column

        .content
          display flex
          align-items center
          justify-content space-between

        .text
          margin-right 8px
          font-size 15px
          display flex
          align-items center
      `}</style>
    </scope>
  );

  if (isSimple) {
    return (
      <Link
        to={OKKO_TV_URL}
        type='blank'
        className={scope.wrapClassNames(className, styles.popcorn, styles._isSimple)}
      />
    );
  }

  return (
    <Link
      to={OKKO_TV_URL}
      type='blank'
      className={scope.wrapClassNames(className, styles.link)}
    >
      <GradientButton>
        <div className={scope.wrapClassNames('content')}>
          <div className={scope.wrapClassNames('text')}>
            <Popcorn
              width={45}
              height={42}
              className={scope.wrapClassNames(styles.popcorn)}
            />
            Онлайн-кинотеатр
          </div>
          <ArrowRightThin />
        </div>
      </GradientButton>
      <scope.styles />
    </Link>
  );
}

PopcornButton.propTypes = {
  /** Класс для дополнительной стилизации */
  className: PropTypes.string,
  /**
   * Можно отрисовать простой контрол, из иконки в PNG.
   */
  isSimple: PropTypes.bool,
  /** @ignore */
  theme: themePropTypes(`{
    controls: {
      popcornButton: {
        popcornOffset,
      }
    }
  }`),
};


const PopcornButtonWithHOCs = withTheme(PopcornButton);
PopcornButtonWithHOCs.displayName = 'PopcornButton';

export default PopcornButtonWithHOCs;

export { PopcornButton as StorybookComponent };
