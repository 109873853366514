import configResolver from './shared-app-config';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'core/libs/router';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';

import Layout from 'site/components/Layout';

import Main from 'site/pages/main';
import Tag from 'site/pages/tag';
import Author from 'site/pages/author';
import Authors from 'site/pages/authors';
import Legal from 'site/pages/legal';
import Search from 'site/pages/search';
import Topic from 'site/pages/topic';
import Rubric from 'site/pages/rubric';
import News from 'site/pages/news';
import LeaderBoard from './pages/leaderBoard';
import NotFound from 'site/pages/notFound';
import About from 'site/pages/about';
import Redirect from 'site/pages/Redirect';
import Likhie from 'site/pages/likhie';
import Mamonty from 'site/pages/mamonty';

import { basicSlug, topicSlug, SELECTIONS_SLUG } from 'site/constants';

// eslint-disable-next-line no-unused-vars
import globals from './global-vars.styl';

function Site(props) {
  const {
    location: {
      pathname,
    },
  } = props;

  const selectionsPath = '/' + SELECTIONS_SLUG;
  const disableAmp = !(pathname.indexOf(selectionsPath) === 0 && pathname !== selectionsPath);

  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver(disableAmp);

  return (
    <App {...config}>
      <Layout>
        <BaseRoutes
          authors={Authors}
          author={Author}
          tags={Tag}
          main={Main}
          search={Search}
          legal={Legal}
          leaderBoard={LeaderBoard}
          notFound={NotFound}
        >
          <Route
            path='/mamonty'
            component={Mamonty}
          />
          <Route
            path='/likhie'
            component={Likhie}
          />
          <Route
            path='/about-us'
            component={About}
            exact
          />
          <Route
            path='/news'
            component={News}
            exact
          />
          <Route
            path={`/:level_1(${basicSlug})`}
            component={Rubric}
            exact
          />
          <Route
            path={`(/preview)?/:topic_link(${topicSlug})`}
            component={Topic}
            exact
          />
          <Route
            path='/redirect/:link'
            component={Redirect}
          />
          <Route
            path={`/:topic_link(${topicSlug})/leaderboard`}
            component={LeaderBoard}
            exact
          />
        </BaseRoutes>
      </Layout>
    </App>
  );
}

Site.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Site);
