import PropTypes from 'prop-types';

import themePropTypes from 'core/utils/prop-types/theme';
import isNotEmptyReactElementContent from 'core/utils/content';

import withTheme from 'core/components/theme';

import styles from './index.styl';


function NarrowWrapper(props) {
  const {
    children,
    theme,
    isMobile,
  } = props;

  if (!isNotEmptyReactElementContent(children)) return null;

  if (isMobile) return children;

  const {
    contentColumnMaxWidth,
  } = theme.layout;

  return (
    <div className={styles.wrapper}>
      <style jsx>{`
        .${styles.wrapper}
          max-width ${contentColumnMaxWidth}
          @media (max-width: 1440px)
            max-width ${parseFloat(contentColumnMaxWidth) - 100}px
        `}</style>
      {children}
    </div>
  );
}

NarrowWrapper.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: themePropTypes(`
    {
      layout: {
        contentColumnMaxWidth,
      },
    }
  `),
};


export default withTheme(NarrowWrapper);
