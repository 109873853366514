import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import withMenu from 'core/components/withMenu';

import { menuPropTypes } from 'core/utils/prop-types/relationships';

import ShapkaMobile from './Mobile';
import ShapkaCustom from './Custom';

function Shlyapa({ isMobile, menu, isErrorPage }) {
  if (isErrorPage) return <ShapkaCustom menu={menu} isErrorPage />;

  const BreakpointShapka = isMobile ? ShapkaMobile : ShapkaCustom;

  return (
    <BreakpointShapka menu={menu}  />
  );
}

Shlyapa.propTypes = {
  isMobile: PropTypes.bool,
  menu: menuPropTypes(),
  isErrorPage: PropTypes.bool,
};

export default withBreakpoint(withMenu('main')(Shlyapa));
