import PropTypes from 'prop-types';
import { incut as coreIncut } from 'core/components/TopicContent/blocks';
import IncutIcon from 'site/icons/Incut';

import styles from './index.styl';

export default function Incut(props) {
  const {
    block,
    parentProps,
    ctx,
  } = props;

  if (!block) return null;

  return (
    <div className={styles.wrapper}>
      <IncutIcon className={styles.icon} />
      {coreIncut(block, parentProps, ctx)}
    </div>
  );
}

Incut.propTypes = {
  parentProps: PropTypes.object,
  block: PropTypes.object,
  ctx: PropTypes.object,
};
