import PropTypes from 'prop-types';
import color from 'color';

import SmartImage from 'core/components/SmartImage';
import Link from 'site/components/Link';

import bindProps from 'core/components/bindProps';
import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';
import resolveRelationships from 'core/utils/relationships';

import { OKKO_TV_URL } from 'site/constants';
import { replaceNewLineWithComma } from 'site/utils';

import MovieMeta from 'site/components/MovieMeta';

import styles from './index.styl';

const requiredPayloadImports = ['image', 'movie_meta'];

const requiredPayloadFields = [
  'headline',
  'source_url',
];

const relationships = resolveRelationships(
  requiredPayloadImports,
  {},
  {
    image: { versions: {} },
    movie_meta: {},
  },
);

function CardMovie(props) {
  const {
    content,
    theme,
    imageMinWidth,
    imageMaxWidth,
    aspectRatio,
    highPriorityImage,
  } = props;

  const {
    attributes: {
      headline,
      source_url: sourceUrl,
    },
  } = content;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    movie_meta: {
      release_year: releaseYear,
      duration,
      genres,
      directors,
      actors,
      age_access: ageAccess,
      countries,
    },
  } = relationships(content);

  const {
    colors,
    fonts: {
      display,
    },
    animations,
  } = theme;

  const meta = {
    releaseYear,
    genres,
    duration,
    countries,
    ageAccess,
  };

  const hasPersons = directors || actors;
  const directorsFormatted = replaceNewLineWithComma(directors, 3, ' и др.');
  const actorsFormatted = replaceNewLineWithComma(actors, 3, ' и др.');

  return (
    <Link
      to={sourceUrl || OKKO_TV_URL}
      type='primary'
      className={styles.cardMovie}
    >
      <style jsx>{`
        .${styles.image}
          min-width ${imageMinWidth ? imageMinWidth + 'px' : 0}
          transition ${animations.scale}
          background ${colors.content}

        .${styles.title}
          font-family ${display}

        .${styles.persons}
          color ${color(colors.primary).alpha(0.4).string()}
      `}</style>
      <div className={styles.image}>
        <SmartImage
          src={cover}
          previewSrc={previewCover}
          maxWidth={imageMaxWidth}
          aspectRatio={aspectRatio}
          {...highPriorityImage && { fetchPriority: 'high', instant: true }}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{headline}</div>
        {Object.keys(meta).length > 0 && (
          <div className={styles.info}>
            <MovieMeta meta={meta} size='s' />
          </div>
        )}
        {hasPersons && (
          <div className={styles.persons}>
            {directorsFormatted && <div>Режиссер: {directorsFormatted}</div>}
            {actorsFormatted && <div>Главные роли: {actorsFormatted}</div>}
          </div>
        )}
      </div>
    </Link>
  );
}

CardMovie.propTypes = {
  /**
   * Данные для карточки
   */
  content: PropTypes.object,
  /** @ignore */
  theme: themePropTypes(`{
    fonts: {
      display,
    },
    colors: {
      content,
    },
    animations: {
      scale,
    }
  }`),

  /**
   * Ограничиваем размер картинки по ширине.
   */
  imageMaxWidth: PropTypes.number.isRequired,

  /**
   * Ограничиваем минимальный размер картинки по ширине.
   * Может быть использовано, в grid контейнере обернутым в overflow: scroll.
   */
  imageMinWidth: PropTypes.number,

  /**
   * Соотношение сторон для картинки
   */
  aspectRatio: PropTypes.number.isRequired,

  /**
   * Использование fetchPriority для загрузки картинки
   */
  highPriorityImage: PropTypes.bool,
};

CardMovie.defaultProps = {
  imageMaxWidth: 362,
  aspectRatio: 216 / 121,
};

const Card = withTheme(CardMovie);

export const CardMovieSizeMobile = bindProps({ imageMaxWidth: 362, imageMinWidth: 280, aspectRatio: 280 / 168 })(Card);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { CardMovie as StorybookComponent };
