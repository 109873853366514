import styles from './index.styl';

function TheEnd() {
  return (
    <div className={styles.theEnd}>
      Совсем скоро здесь появятся советы о подарках, частушки про кибербез и многое другое!
    </div>
  );
}

export default TheEnd;
