export const host = 'blog.okko.tv';
export const siteUrl = 'https://' + host;

export const basicSlug = '[a-z0-9\-_]+';
export const topicSlug = '[a-z0-9\-_.]+\/[a-z0-9\-_.]+';

export const OKKO_TV_URL = 'https://okko.tv/';

// indents
export const SIDE_INDENT = 40;
export const SIDE_INDENT_MOBILE = 20;
export const VERTICAL_INDENT = 30;
export const VERTICAL_INDENT_MOBILE = 20;

export const datePattern = 'dd MMMM yyyy HH:mm';

export const SELECTIONS_SLUG = 'selections';
export const TABLE_OF_CONTENTS_TITLE = 'Содержание';

export const mamontyPromoMap = {
  'mainRead': {
    headline: 'Правила современной заботы от сериала «Мамонты»',
    coverType: 'square',
    image: require('site/images/mamonty/mamonty_taxi_square.jpg'),
  },
  '/reviews': {
    headline: '«Мамонты»: Забота сегодня',
    image: require('site/images/mamonty/mamonty_taxi_horisontal.jpg'),
  },
  '/redquiz': {
    headline: 'Научись правилам современной заботы и узнай, насколько хорошо ты понимаешь своих сверстников и людей из другого поколения',
    image: require('site/images/mamonty/mamonty_table_horisontal.jpg'),
  },
  '/articles': {
    headline: 'Спецпроект «Что такое забота сегодня?» от сериала «Мамонты»',
    image: require('site/images/mamonty/mamonty_poster_horisontal.jpg'),
  },
  '/selections': {
    headline: 'Лучшие советы о том, как позаботиться о близких сегодня от сериала «Мамонты»',
    image: require('site/images/mamonty/mamonty_poster_horisontal.jpg'),
  },
  '/news': {
    headline: 'Секрет современной заботы раскрыт!',
  },
};
