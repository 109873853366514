import PropTypes from 'prop-types';

import GridFeed from 'core/components/GridFeed';
import Divider from 'core/components/Divider';

import { Indent } from 'core/components/Wrappers';

import bindProps from 'core/components/bindProps';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import H2Main from 'site/components/H2Main';
import WideWrapper from 'site/components/Wrappers/WideWrapper';

import CardVertical from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';

import styles from './index.styl';


const CardMobile = bindProps({
  size: 's',
  imageMaxWidth: 480,
  imageWrapperWidth: '480px',
  lockImageWidth: true,
})(CardVertical);

const CardDesktop = bindProps({
  size: 's',
  imageMaxWidth: 240,
  imageWrapperWidth: '240px',
  imageRatio: 3 / 2,
  lockImageWidth: true,
})(CardHorizontal);


export default function Topical(props) {
  const {
    topics,
    listTitle,
    isMobile,
  } = props;

  if (!topics.length) return null;

  return (
    <>
      <Divider className={styles.topDivider} />
      <H2Main>{listTitle}</H2Main>
      <Indent bottom={isMobile ? 20 : 30} />
      <WideWrapper>
        <GridFeed
          content={topics}
          card={isMobile ? CardMobile : CardDesktop}
          gap={isMobile ? '20px' : '30px'}
          {...!isMobile && { gridTemplateColumns: 'repeat(2, 1fr)' }}
        />
      </WideWrapper>
    </>
  );
}

Topical.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  listTitle: PropTypes.string,
  isMobile: PropTypes.bool,
};
