import PropTypes from 'prop-types';
import cx from 'classnames';

import palette from 'site/themes/palette';

import styles from './index.styl';

export default function Background({ isActive, children }) {
  return (
    <div className={cx(styles.background, isActive && styles._isActive)}>
      <style jsx>{`
        .${styles.background}
          &:before
            background-image radial-gradient(ellipse at bottom center, #fff, ${palette.electroPurple600} 0%, transparent 60%),
              radial-gradient(ellipse at center, #fff, ${palette.electroPurple600} 0px, transparent 75%),
              radial-gradient(ellipse at top right, #fff, ${palette.electroPurple700} 0px, transparent 73%)
      `}</style>
      {children}
    </div>
  );
}

Background.propTypes = {
  isActive: PropTypes.bool,
};
