import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/components/theme';
import Link from 'core/components/Link';

import styles from './index.styl';


function WantToWatchWrapper({ children, theme }) {
  const {
    controls: {
      wantToWatch: styleAtoms,
    },
  } = theme;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.kandinsky}
          font 400 16px/1.25 ${theme.fonts.display}
          color ${theme.colors.content}

          &:visited
            color ${theme.colors.content}

          &:hover
            color ${theme.colors.grey1}

          :global(.mobile) &
            font-size 13px
      `}</style>
    </scope>
  );


  return (
    <div className={styles.wrapper}>
      <style jsx>{`
        .${styles.headline}
          color ${styleAtoms.headline.color}
          font ${styleAtoms.headline.font}
          padding ${styleAtoms.headline.padding}
      `}</style>
      <div className={styles.headline}>
        Выберите фильм под настроение
      </div>
      {children}
      <Link
        className={scope.wrapClassNames(styles.kandinsky)}
        to='//www.sberbank.com/promo/kandinsky/'
        type='primary'
      >
        Иллюстрации созданы нейросетью&nbsp;Kandinsky
      </Link>
      <scope.styles />
    </div>
  );
}

WantToWatchWrapper.propTypes = {
  theme: themePropTypes(`{
    colors: {
      primary,
    },
  }`),
};

export default withTheme(WantToWatchWrapper);
