import { useEffect, useState, useCallback, useRef } from 'react';
import { throttle } from 'core/libs/lodash';
import cx from 'classnames';

import Link from 'core/components/Link';
import withoutSSR from 'core/components/withoutSSR';

import Mascot from 'site/components/Mascot';

import MiniLogo from './MiniLogo.svg';

import styles from './index.styl';


function TopicMascot() {
  const mascotRef = useRef(null);

  const [wasAnimate, setWasAnimate] = useState(false);
  const [isAnimating, toggleAnimation] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAnimation = useCallback(
    throttle(() => {
      const mascotCoords = mascotRef.current.getBoundingClientRect();
      const windowOffset = document.documentElement.scrollTop;

      if (windowOffset > mascotCoords.top) {
        toggleAnimation(true);
        setWasAnimate(true);
      }

      if (wasAnimate && windowOffset <= mascotCoords.top) {
        toggleAnimation(false);
      }
    }, 200),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wasAnimate]
  );

  useEffect(() => {
    handleAnimation();
    window.addEventListener('scroll', handleAnimation);
    window.addEventListener('resize', handleAnimation);

    return () => {
      window.removeEventListener('scroll', handleAnimation);
      window.removeEventListener('resize', handleAnimation);
    };
  }, [handleAnimation]);

  return (
    <div className={styles.stickyContainer}>
      <div ref={mascotRef} className={cx(styles.mascot, isAnimating && styles._isAnimating, wasAnimate && styles._wasAnimate)}>
        <Link
          to='/'
          title='На главную'
          className={styles.link}
        >
          <MiniLogo />
          <Mascot
            className={styles.popcorn}
            type='hanging'
            width={91}
          />
        </Link>
      </div>
    </div>
  );
}

export default withoutSSR(TopicMascot);
