import { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withFullscreen from 'core/components/withFullscreen';
import { Block, Section } from 'core/components/Grid';
import SmartImage from 'core/components/SmartImage';
import EmptyWrapper from 'core/components/EmptyWrapper';
import Link from 'core/components/Link';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import styles from './index.styl';


const IMAGE_MAX_WIDTH = 400;
const MOBILE_IMAGE_MAX_WIDTH = 320;

function WidgetSideWrapper(props) {
  const {
    children,
    imageVersions,
    placement,
    alt,
    url,
    isMobile,
    onImageClick,
    caption,
    credits,
  } = props;

  const ImageWrapper = url ? Link : EmptyWrapper;

  const handleImageClick = useCallback(() => {
    onImageClick({
      cover: imageVersions.original,
      caption,
      credits,
    });
  }, [imageVersions, caption, credits, onImageClick]);

  return (
    <div className={cx(styles.wrapper, styles[`_${placement}`])}>
      <style jsx>{`
        .${styles.image}
          :global(.mobile) &
            max-width ${MOBILE_IMAGE_MAX_WIDTH}px
      `}</style>
      <Section>
        <Block mobile={12} reorderDesktop={placement === 'right' && 1}>
          <div className={styles.image}>
            <ImageWrapper to={url} type='blank'>
              <SmartImage
                versions={imageVersions}
                alt={alt}
                maxWidth={isMobile ? MOBILE_IMAGE_MAX_WIDTH : IMAGE_MAX_WIDTH}
                onClick={handleImageClick}
              />
            </ImageWrapper>
          </div>
        </Block>
        <Block mobile={12}>
          <div className={styles.content}>
            {children}
          </div>
        </Block>
      </Section>
    </div>
  );
}

WidgetSideWrapper.propTypes = {
  imageVersions: PropTypes.object,
  placement: PropTypes.string,
  alt: PropTypes.string,
  url: PropTypes.string,
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
  caption: PropTypes.string,
  credits: PropTypes.string,
  onImageClick: PropTypes.func,
};

export default withBreakpoint(withFullscreen(WidgetSideWrapper));
