import PropTypes from 'prop-types';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';
import withTheme from 'core/components/theme';
import Link from 'core/components/Link';
import skip from 'core/resolver/skip';

const requiredPayloadFields = [
  'name',
  'slug',
  'job_title',
];

function AuthorsPageCard(props) {
  const {
    content,
    author,
    theme: {
      texts: textsAtoms,
      colors: colorsAtoms,
    },
  } = props;

  if (!(content || author)) return null;

  const {
    attributes: {
      name,
      slug,
      job_title: jobTitle,
    },
  } = content || author;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .name
          font ${textsAtoms.body.font}

        .job
          font ${textsAtoms.bodySmall.font}
          color ${colorsAtoms.primary}
      `}</style>
    </scope>
  );

  return (
    <div className='authorCard'>
      <Link to={`/authors/${slug}`} type='secondary'>
        <div className={scope.wrapClassNames('name')}>{name}</div>
        {!!jobTitle && <div className={scope.wrapClassNames('job')}>{jobTitle}</div>}
      </Link>
      <scope.styles />
    </div>
  );
}

AuthorsPageCard.propTypes = {
  /** Данные автора, соответствующие модели `person` swagger-схемы. */
  content: modelPropTypes(personAttributes),
  /** Тип карточки */
  author: modelPropTypes(personAttributes),
  /** @ignore */
  theme: PropTypes.object,
};

AuthorsPageCard.requiredPayloadFields = requiredPayloadFields;

export { AuthorsPageCard as StorybookComponent };

export default skip(withTheme(AuthorsPageCard));
