import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { rawContentFetcher } from 'core/fetchers/searchPage';

import bindProps from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import Page from 'core/components/Page';
import SearchPage from 'core/components/SearchPage';
import { Indent, PageIndent } from 'core/components/Wrappers';

import Pagination from 'site/components/Pagination';
import SearchFilter from 'site/components/SearchFilter';
import ListDivider from 'site/components/ListDivider';
import { NarrowWrapper } from 'site/components/Wrappers';

import CardHorizontal from 'site/cards/CardHorizontal';

import filters from './filters';


const Card = bindProps({ size: 'l', imageMaxWidth: 0 })(CardHorizontal);

const PaginationWithIndents = (props) => {
  return (
    <Indent top={20}>
      <Pagination {...props} />
    </Indent>
  );
};

const MobileCard = (props) => (
  <>
    <Indent top={10} bottom={10}>
      <Card {...props} />
    </Indent>
    <ListDivider />
  </>
);


const DesktopCard = (props) => (
  <>
    <Indent top={20} bottom={20}>
      <Card {...props} />
    </Indent>
    <ListDivider />
  </>
);

MobileCard.requiredPayloadImports = ['tags', 'rubric', 'search_result'];
DesktopCard.requiredPayloadImports = ['tags', 'rubric', 'search_result'];
/**
 * Результаты поиска на стейджах не будут соответствовать действительности,
 * потому что апишка поиска работает только с продовыми данными.
 * Для стейджей будут выдаваться рандомный список топиков.
 */
function Search({ rawContent, isMobile }) {
  return (
    <Page
      description='Воспользуйтесь удобным поиском, чтобы найти нужную новость, подборку, статью или рецензию на OKKOLOKINO.'
      skipRcm
    >
      <PageIndent>
        <NarrowWrapper>
          <SearchPage
            rawContent={rawContent}
            card={isMobile ? MobileCard : DesktopCard}
            foundTextBlock={({ total }) => (
              <SearchFilter
                total={total}
                filters={filters}
              />)}
            interitemSpacing={0}
            pagination={PaginationWithIndents}
          />
        </NarrowWrapper>
      </PageIndent>
    </Page>
  );
}

Search.propTypes = {
  rawContent: PropTypes.array,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  rawContent: rawContentFetcher({ card: CardHorizontal }),
});

export default withPageHocs(dataProvider)(withBreakpoint(Search));
