import { Desktop, Mobile } from 'core/components/breakpoint';
import {
  Inpage as InpageDesktop,
  Fullscreen as FullscreenDesktop,
} from './desktop';
import {
  Inpage as InpageMobile,
  Fullscreen as FullscreenMobile,
} from './mobile';

export function Inpage() {
  return (
    <>
      <Desktop>
        <InpageDesktop />
      </Desktop>
      <Mobile>
        <InpageMobile />
      </Mobile>
    </>
  );
}

export function Fullscreen() {
  return (
    <>
      <Desktop>
        <FullscreenDesktop />
      </Desktop>
      <Mobile>
        <FullscreenMobile />
      </Mobile>
    </>
  );
}
