import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint, Desktop } from 'core/components/breakpoint';

import Swipe, { Slide } from 'core/components/Swipe';

import ArrowRight from '../../images/ArrowRight';
import image1 from '../../images/image1.png';
import image2 from '../../images/image2.png';
import image3 from '../../images/image3.png';
import image4 from '../../images/image4.png';
import image5 from '../../images/image5.png';
import okko from '../../images/okko.png';
import afisha from '../../images/afisha.png';
import zvuk from '../../images/zvuk.png';
import samokat from '../../images/samokat.png';
import gis from '../../images/gis.png';

import styles from './index.styl';

const data = [
  // {
  //   title: 'Советы от сериала “Мамонты”',
  //   description: 'Разобраться в обновляющемся мире технологий — задача не из простых. Мы знаем, что с такими трудностями сталкиваются практически все представители старшего поколения, поэтому хотим помочь преодолеть этот барьер с помощью небольшого пособия, в котором расскажем про полезные функции смартфона и дадим несколько полезных рекомендаций по кибербезопасности.',
  // },
  {
    title: 'Советы от сервисов Сбера',
    description: 'По случаю выхода сериала «Мамонты» сервисы Сбера объединились и решили дать несколько полезных советов старшему поколению',
    images: [zvuk, afisha, okko, samokat, gis],
  },
  {
    title: 'Как не стать жертвой телефонных мошенников',
    description: 'Простые правила от Кибрария, которые помогут себя защитить. И главные схемы развода',
    images: [image1, image2, image3, image4, image5],
  },
];


function Builder({ isMobile }) {
  const swipeRefs = useRef([]);

  const nextHandler = useCallback((index) => {
    swipeRefs.current[index]?.next();
  }, []);

  const prevHandler = useCallback((index) => {
    swipeRefs.current[index]?.prev();
  }, []);

  return (
    <div className={styles.builder}>
      {data.map((block, index) => {
        const {
          title,
          description,
          images,
        } = block;

        return (
          <div key={index} className={styles.adviceBlock}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>

            <div className={styles.swipeWrapper}>
              <Swipe
                loop
                ref={(el) => (swipeRefs.current[index] = el)}
                width='100%'
                {...isMobile && { slidesPerView: '1.15', centeredSlides: true }}
              >
                {images.map((advice, i) => (
                  <Slide key={i} {...!isMobile && { width: '100%' }}>
                    <div className={styles.adviceCard}>
                      <img
                        src={advice}
                        className={styles.image}
                        alt='advice'
                      />
                    </div>
                  </Slide>
                ))}
              </Swipe>

              <Desktop>
                <div className={styles.arrow} onClick={() => prevHandler(index)}>
                  <ArrowRight width={isMobile ? 32 : 64} height={isMobile ? 32 : 64} />
                </div>
                <div className={styles.arrow} onClick={() => nextHandler(index)}>
                  <ArrowRight width={isMobile ? 32 : 64} height={isMobile ? 32 : 64} />
                </div>
              </Desktop>

            </div>
          </div>
        );
      })}
    </div>
  );
}

Builder.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Builder);
