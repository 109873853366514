import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import Swipe, { Slide } from 'core/components/Swipe';

import HeroCard from './HeroCard';
import heroesData from './heroesData';

import ArowLeft from 'site/icons/ArrowLeft1';
import ArowRight from 'site/icons/ArrowRight1';

import styles from './index.styl';


function Heroes({ isMobile, theme }) {
  const swipeEl = useRef(null);

  const nextHandler = useCallback(() => swipeEl.current.next(), []);
  const prevHandler = useCallback(() => swipeEl.current.prev(), []);

  return (
    <div className={styles.heroes}>
      <style jsx>{`
        .${styles.title}
          font-family ${theme.fonts.likhie}
      `}</style>
      <div className={styles.title}>
        Истории главных <br />
        <span className={styles.black}>героев сериала</span>
      </div>

      <Swipe loop ref={swipeEl}>
        {heroesData.map((hero) => (
          <Slide width='100%' key={hero.id}>
            <HeroCard hero={hero} />
          </Slide>
        ))}
      </Swipe>

      <>
        <div className={styles.arrow} onClick={prevHandler}>
          <ArowLeft width={isMobile ? 32 : 64} height={isMobile ? 32 : 64} />
        </div>
        <div className={styles.arrow} onClick={nextHandler}>
          <ArowRight width={isMobile ? 32 : 64} height={isMobile ? 32 : 64} />
        </div>
      </>
    </div>
  );
}

Heroes.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(Heroes));
