import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import { Mobile, Desktop, withBreakpoint } from 'core/components/breakpoint';
import ThemeSwitch from 'core/components/ThemeSwitch';

import Page from 'core/components/Page';

import { Indent } from 'core/components/Wrappers';
import withPageHocs from 'core/components/withPageHocs';

import WideWrapper from 'site/components/Wrappers/WideWrapper';

import ReadAndWatch from './ReadAndWatch';
import Facts from './Facts';
import WatchSuggestions from 'site/components/WatchSuggestions';
import WantToWatch from './WantToWatch';
import ReadingNow from './ReadingNow';
import Topical from './Topical';
import Tasty from './Tasty';
import Playlist from './Playlist';
import MainGameCompare from './GameCompare';
import MainSkeleton from './Skeleton';

import dataProvider from './mainTopics';


function MainPage(props) {
  const {
    facts,
    gameCompare,
    readingNow,
    tasty,
    tastyListTitle,
    topical,
    topicalListTitle,
    wantToWatchSelections,
    watchSelections,
    watchMovies,
    readNews,
    readTopics,
    isMobile,
  } = props;

  const readAndWatchContent = {
    selections: watchSelections,
    movies: watchMovies,
    news: readNews,
    topics: readTopics,
  };

  return (
    <Page>
      <Helmet>
        <meta name='yandex-verification' content='d9235ee4227173ba' />
        <meta name='google-site-verification' content='IyuRk89af7WV28iQx9I2H6RrCse5YN7IYJcJpAiCOU4' />
      </Helmet>
      <Desktop>
        <ReadAndWatch {...readAndWatchContent} />
      </Desktop>
      <WideWrapper>
        <Mobile>
          <ReadAndWatch {...readAndWatchContent} />
        </Mobile>
        <Indent top={isMobile ? 18 : 50} />
        <WantToWatch selections={wantToWatchSelections} />
        <Indent top={isMobile ? 30 : 40} />
        <Facts facts={facts} />
      </WideWrapper>
      <Indent top={isMobile ? 31 : 61} />
      <ReadingNow topics={readingNow} isMobile={isMobile} />
      <Indent top={isMobile ? 20 : 40} />
      <Topical
        topics={topical}
        listTitle={topicalListTitle}
        isMobile={isMobile}
      />
      <Indent top={isMobile ? 30 : 40} />
      <ThemeSwitch name='dark'>
        <WatchSuggestions />
      </ThemeSwitch>
      <Indent top={isMobile ? 30 : 40} />
      <Tasty
        topics={tasty}
        listTitle={tastyListTitle}
        isMobile={isMobile}
      />
      <Indent top={isMobile ? 30 : 40} />
      <MainGameCompare topics={gameCompare} />
      <Indent top={isMobile ? 50 : 40} />
      <Playlist />
    </Page>
  );
}

MainPage.propTypes = {
  facts: PropTypes.array,
  gameCompare: PropTypes.array,
  readingNow: PropTypes.array,
  tasty: PropTypes.array,
  tastyListTitle: PropTypes.string,
  topical: PropTypes.array,
  topicalListTitle: PropTypes.string,
  wantToWatchSelections: PropTypes.array,
  watchSelections: PropTypes.array,
  watchMovies: PropTypes.array,
  readNews: PropTypes.array,
  readTopics: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default withPageHocs(dataProvider, MainSkeleton)(withBreakpoint(MainPage));
