import color from 'core/libs/color';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import { modes } from '../../constants';

import styles from './index.styl';

function Overlay({ children, type, isActive, theme, handleClick }) {
  const { content: contentColor } = theme.colors;

  return (
    <div
      className={cx(
        styles.overlay,
        styles['_' + type],
        isActive && styles._isActive
      )}
      onClick={() => !isActive && handleClick()}
    >
      <style jsx>{`
        .${styles._read}
          &:before
            background ${color(contentColor).alpha(0.7).string()}
      `}</style>
      {children}
    </div>
  );
}

Overlay.propTypes = {
  type: PropTypes.oneOf([modes.read, modes.watch]).isRequired,
  isActive: PropTypes.bool,
  theme: PropTypes.object,
  handleClick: PropTypes.func,
};

export default withTheme(Overlay);
