import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Modal from 'core/components/Modal';

import { Desktop, Mobile } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import styles from './index.styl';


function HeroCard({ theme, hero }) {
  const {
    image,
    fullName,
    aboutHero,
  } = hero;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const scopedStyles = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .modalWindow
          background-color rgba(0, 0, 0, 0.5)
          width 100%
          height 100%
          color #fff
      `}</style>
    </scope>
  );

  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : '';

    const handleClickOutside = (event) => {
      if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    const handleEscapeKeyDown = (event) => {
      if (isModalOpen && event.key === 'Escape') {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKeyDown);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKeyDown);
    };
  }, [isModalOpen]);

  return (
    <div className={styles.heroCard}>
      <style jsx>{`
        .${styles.fullName}
          font-family ${theme.fonts.likhie}
      `}</style>
      <Mobile><div className={styles.fullName}>{fullName}</div></Mobile>
      <div className={styles.container}>
        <img
          src={image}
          alt='hero'
          className={styles.image}
          onClick={handleOpen}
        />
      </div>
      <div className={styles.textWrapper}>
        <Desktop><div className={styles.fullName}>{fullName}</div></Desktop>
        <div className={styles.aboutHero}>{aboutHero}</div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleClose}
        scopedStyles={scopedStyles}
      >
        <div className={styles.imageWrapper}>
          <img
            src={image}
            alt='hero fullscreen'
            className={styles.modalImage}
            ref={modalRef}
          />
        </div>
      </Modal>
    </div>
  );
}

HeroCard.propTypes = {
  theme: PropTypes.object,
  hero: PropTypes.object,
};

export default withTheme(HeroCard);
