import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';

import TopicToplineSkeleton from 'core/components/TopicTopline/Skeleton';
import TopicHeaderTextsSkeleton from 'core/components/TopicHeaderTexts/Skeleton';
import CoreGameCompareSkeleton from 'core/components/GameCompare/Skeleton';

import styles from './index.styl';


function Story({ isMobile }) {
  const indent = isMobile ? 30 : 24;

  return (
    <Fragment>
      <Indent top={20} />
      <TopicToplineSkeleton height={isMobile ? 22 : 26} />
      <Indent top={indent} />
      <TopicHeaderTextsSkeleton
        titleHeight={isMobile ? 50 : 80}
        leadHeight={isMobile ? 100 : 150}
      />
      <Indent top={indent} />
      <div className={cx('lb-skeleton', styles.question)} />
      <Indent top={20} />
      <CoreGameCompareSkeleton />
    </Fragment>
  );
}

Story.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Story);
