import PropTypes from 'prop-types';

import GameCompare from 'core/components/TopicContent/blocks/smartTeaser/GameCompare';

import Movie from './Movie';

const componentMap = {
  movie: Movie,
  game_compare: GameCompare,
  pair_compare: GameCompare,
};


export default function SmartTeaser(props) {
  const {
    block,
    parentProps,
  } = props;

  const { teaser_type: teaserType } = block.attributes;

  const TeaserComponent = componentMap[teaserType];

  if (!TeaserComponent) return null;

  return (
    <TeaserComponent
      block={block}
      parentProps={parentProps}
    />
  );
}

SmartTeaser.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
