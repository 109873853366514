import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Button from '../Button';

import Symbol from '../images/Symbol';
import footbal from '../images/footbal.jpg';
import cup from '../images/cup.jpg';

import styles from './index.styl';


function TwoBlocks({ theme, isDesktop }) {
  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.title}
        .${styles.toRead}
          font-family ${theme.fonts.likhie}
    `}</style>
    </scope>
  );

  const description = (
    <div className={styles.description}>
      <div className={styles.text}>Авторы Чемпионата вспомнили, как российские и мировые футбольные клубы находились под влиянием преступного мира</div>
      <div className={styles.link}>
        <Button
          link='https://www.championat.com/football/article-5756050-futbol-rossii-v-90-h-oleg-shishkanov-v-saturne-ubijstvo-larisy-nechaevoj-iz-spartaka-istoriya-asmarala-i-dogovornye-matchi.html'
          size='s'
          className={styles.link}
        >
          Читать материал
        </Button>
      </div>
    </div>
  );

  return (
    <div className={styles.twoBlock}>
      <div className={styles.imageWrapper}>
        <img
          src={cup}
          className={styles.image}
          alt='criminal'
        />
        <div className={styles.symbol}><Symbol /></div>
      </div>

      <div className={styles.imageWrapper}>
        <img
          src={footbal}
          className={styles.image}
          alt='footbal'
        />
        <div className={scope.wrapClassNames(styles.title)}>
          <span className={styles.footbal}>
            Фут{isDesktop && <br />}бол {!isDesktop && <br />}
          </span>
          и&nbsp;{isDesktop && <br />}крими{isDesktop && <br />}нал
        </div>
        {isDesktop && description}
      </div>
      {!isDesktop && description}
      <scope.styles />
    </div>
  );
}

TwoBlocks.propTypes = {
  theme: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(withTheme(TwoBlocks));
