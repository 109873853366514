import PropTypes from 'prop-types';

import modelPropTypes, { readMoreAttributes } from 'core/utils/prop-types/model';

import readMore from 'core/components/TopicContent/blocks/readMore';
import bindProps from 'core/components/bindProps';

import CardHorizontal from 'site/cards/CardHorizontal';
import { CardVerticalXS } from 'site/cards/CardVertical';

import ListDivider from 'site/components/ListDivider';

const CardHorizontalFixedDesktop = bindProps({ size: 'xs', lockImageWidth: true, imageMaxWidth: 120 })(CardHorizontal);
const CardHorizontalFixedMobile = bindProps({ size: 'xs', lockImageWidth: true, imageMaxWidth: 60 })(CardHorizontal);
const CardForFeedDesktop = bindProps({ card: CardHorizontalFixedDesktop, indent: 15 })(ListDivider);

const CardVertical = bindProps({ imageMaxWidth: 250, noScale: true })(CardVerticalXS);

export default function ReadMore(props) {
  const {
    block,
    parentProps: {
      isDesktop,
    },
  } = props;

  return readMore(
    block,
    {
      title: 'Читайте также',
      singleCard: isDesktop ? CardForFeedDesktop : CardHorizontalFixedMobile,
      feedCard: isDesktop ? CardForFeedDesktop : CardHorizontalFixedMobile,
      drumCard: CardVertical,
    }
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
  parentProps: PropTypes.object,
};
