import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import Builder from './Builder';

import Okko from 'site/icons/Okko';
import Sber from '../images/Sber';
import arrow from '../images/arrow.png';

import styles from './index.styl';


function Advice({ isMobile }) {
  return (
    <div className={styles.advice}>
      <div className={styles.blockHeader}>
        <div className={styles.titleWrapper}>
          <div className={styles.num}>3</div>
          {!isMobile && <img src={arrow} className={styles.arrow} />}
          <div className={styles.container}>
            <div className={styles.title}>Изучайте наш меняющийся мир</div>
            <div className={styles.desc}>Позвольте нам рассказать вашим близким полезную информацию.</div>
          </div>
        </div>

        <div className={styles.icons}>
          <Okko width={isMobile ? 33 : 66} height={isMobile ? 11 : 23} />
          <span className={styles.vs}>x</span>
          <Sber width={isMobile ? 44 : 89} height={isMobile ? 11 : 23} />
        </div>
      </div>

      <Builder />
    </div>
  );
}

Advice.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Advice);
