import { filterRequiredParams, denormalizeData } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';

import resolve from 'core/resolver/resolve';

import CardMovie from 'site/cards/CardMovie';


const requiredPayloadImports = [
  'image',
  'read_more',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  read_more: {
    topic_ids: [],
  },
});


export default resolve({
  facts: ({ bebopApi }) => {
    return bebopApi
      .getTopics({
        list: 'facts',
        sort: 'list',
        topic_type: 'okko_fact',
        limit: 30,
        fields: 'announce,source',
        include: 'image',
      })
      .then(denormalizeData)
      .catch(() => []);
  },

  gameCompare: ({ bebopApi, consoleError }) => {
    return bebopApi
      .getTopics({
        limit: 1,
        list: 'contest',
        sort: 'list',
        include: 'content',
        fields: 'headline,list_headline,link,topic_type',
      })
      .then(denormalizeData)
      .catch(consoleError('gameCompare block', []));
  },

  readingNow: ({ bebopApi }) => {
    return bebopApi
      .getTopics({
        sort: '-views_count',
        limit: 6,
        include: 'image,tags',
        fields: 'link,headline,published_at',
        visibility: 'main_page',
        days_period: 30,
      })
      .then(denormalizeData)
      .catch(() => []);
  },

  tasty: ({ bebopApi }) => {
    return bebopApi
      .getTopics({
        list: 'tasty',
        sort: 'list',
        limit: 2,
        include: 'image',
        fields: 'link,headline,source_url',
      })
      .then(denormalizeData)
      .catch(() => []);
  },

  tastyListTitle: ({ bebopApi }) => {
    return bebopApi
      .getList({
        list_slug: 'tasty',
      })
      .then(data => denormalizeData(data).attributes.title)
      .catch(() => null);
  },

  topical: ({ bebopApi }) => {
    return bebopApi
      .getTopics({
        list: 'topical',
        sort: 'list',
        limit: 2,
        include: 'image,tags',
        fields: 'link,headline,published_at,topic_type',
      })
      .then(denormalizeData)
      .catch(() => []);
  },

  topicalListTitle: ({ bebopApi }) => {
    return bebopApi
      .getList({
        list_slug: 'topical',
      })
      .then(data => denormalizeData(data).attributes.title)
      .catch(() => null);
  },

  wantToWatchSelections: async ({ bebopApi, consoleError }) => {
    const selectionTopics = await bebopApi
      .getTopics({
        list: 'recommendations',
        include: 'read_more,image',
        topic_type: 'selection',
        sort: 'list',
        fields: 'headline',
      })
      .then(denormalizeData)
      .catch(consoleError('WantToWatch"selection" topics', []));

    const selectionsData = selectionTopics.map(selection => relationships(selection));

    const topicsBySelection = selection => {
      return bebopApi
        .getTopics({
          ids: selection.read_more.topic_ids,
          topic_type: 'movie',
          include: 'image',
          fields: 'source_url',
        })
        .then(denormalizeData)
        .catch(consoleError('WantToWatch "read_more" topics', []));
    };

    const getSettledValue = settledResults => settledResults.map(({ value }) => value);

    const topicsBySelections = await Promise
      .allSettled(selectionsData.map(topicsBySelection))
      .then(getSettledValue);

    return selectionTopics.map((selection, index) => ({
      ...selection,
      topics: topicsBySelections[index],
    }));
  },

  watchMovies: ({ movies, bebopApi, consoleError }) => (
    movies || bebopApi
      .getTopics({
        list: 'watch_movies',
        sort: 'list',
        limit: 4,
        fields: filterRequiredParams([CardMovie], 'fields'),
        include: filterRequiredParams([CardMovie], 'include'),
      })
      .then(denormalizeData)
      .catch(consoleError('Main page "watch_movies" list', []))
  ),

  /**
   * Данные для подборок.
   * Запрашиваем лист `watch_selections`, получаем топики.
   * В каждом топике берем виджет `smartTeaser` и запрашиваем фильмы.
   * В фильме забираем постеры `portrait`.
   */
  watchSelections: async ({ bebopApi, consoleError }) => {
    const selections = await bebopApi
      .getTopics({
        limit: 2,
        fields: 'headline,source_url',
        include: 'read_more',
        list: 'watch_selections',
        sort: 'list',
      })
      .then(denormalizeData)
      .catch(consoleError('Main page "watch_selections" list', []));

    const requestPosters = selection => {
      const { topic_ids: topicIds } = relationships(selection).read_more;

      if (!topicIds.length) return null;

      return bebopApi
        .getTopics({
          ids: topicIds,
          topic_type: 'movie',
          fields: 'headline',
          include: 'image',
        })
        .then(denormalizeData)
        .then(topics => topics.map(topic => relationships(topic).image.versions.portrait))
        .catch(consoleError('Main page movies in "watch_selections" list', []));
    };

    const posters = await Promise
      .allSettled(selections.map(requestPosters))
      .then(settledResults => settledResults.map(({ value }) => value));

    return selections.map((selection, index) => ({
      ...selection,
      posters: posters[index],
    }));
  },

  readNews: ({ bebopApi }) => {
    return bebopApi
      .getTopics({
        topic_type: 'news',
        sort: '-published_at',
        limit: 6,
        fields: 'link,list_headline,headline,published_at',
        include: 'tags',
      })
      .then(denormalizeData)
      .catch(() => []);
  },

  readTopics: ({ bebopApi }) => {
    return bebopApi
      .getTopics({
        list: 'read',
        sort: 'list',
        limit: 2,
        fields: 'link,list_headline,headline,published_at',
        include: 'image,rubric',
      })
      .then(denormalizeData)
      .catch(() => []);
  },
});
