import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { Indent } from 'core/components/Wrappers';
import { withBreakpoint } from 'core/components/breakpoint';

import WideWrapper from 'site/components/Wrappers/WideWrapper';

import FactsSkeleton from 'site/pages/main/Facts/Skeleton';
import WantToWatchSkeleton from 'site/pages/main/WantToWatch/Skeleton';
import ReadAndWatchSkeleton from 'site/pages/main/ReadAndWatch/Skeleton';

import WantToWatchWrapper from 'site/pages/main/WantToWatch/Wrapper';

import styles from '../Facts/index.styl';


function Skeleton({ breakpoint, isMobile }) {
  return (
    <Fragment>
      <ReadAndWatchSkeleton breakpoint={breakpoint} />
      <Indent top={isMobile ? 18 : 50} />
      <WideWrapper>
        <WantToWatchWrapper>
          <WantToWatchSkeleton />
        </WantToWatchWrapper>
        <Indent top={isMobile ? 30 : 40} />
        <div className={styles.facts}>
          <FactsSkeleton />
        </div>
      </WideWrapper>
      <Indent top={isMobile ? 31 : 61} />
    </Fragment>
  );
}

Skeleton.propTypes = {
  breakpoint: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Skeleton);
