// Получаем слово с пересеченными буквами
export const getCurrentWord = (currentQuestion, crosswordData) => {
  if (!currentQuestion) return [];

  const { start: { row, col }, answer, direction } = currentQuestion;

  const word = Array.from({ length: answer.length }, (_, i) =>
    direction === 'horizontal'
      ? crosswordData[row][col + i]?.letter || ''
      : crosswordData[row + i][col]?.letter || ''
  );

  return word;
};

// Проверяем все ли ответы заполнены
export const checkCrosswordCompleted = (crosswordData) => {
  return crosswordData.every((row) =>
    row.every((cell) => {
      if (cell && typeof cell === 'object' && 'letter' in cell) {
        return cell.letter.trim() !== '';
      }
      return true;
    })
  );
};

