import cx from 'classnames';

import skeletonStyles  from './index.styl';

function FactsSkeleton() {
  return (
    <div className={skeletonStyles.wrapper}>
      <div className={cx(skeletonStyles.img, 'lb-skeleton')} />
      <div className={cx(skeletonStyles.announce, 'lb-skeleton')} />
      <div className={cx(skeletonStyles.source, 'lb-skeleton')} />
    </div>
  );
}

export default FactsSkeleton;
