import skip from 'core/resolver/skip';

import Hero from './Hero';
import AboutSeries from './AboutSeries';
import Dictionary from './Dictionary';
import Crossword from './Crossword';
import TheEnd from './TheEnd';
import Advice from './Advice';

import styles from './index.styl';

function Mamonty() {
  return (
    <div className={styles.container}>
      <Hero />
      <AboutSeries />
      <Dictionary />
      <Crossword />
      <Advice />
      <TheEnd />
    </div>
  );
}

export default skip(Mamonty);
