import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withTheme from 'core/components/theme';

import Divider from 'core/components/Divider';
import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';

import WideWrapper from 'site/components/Wrappers/WideWrapper';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';
import { getPair } from 'core/components/GameCompare/utils';

import Vs from 'site/icons/Vs.svg';

import styles from './index.styl';

const relationships = resolveRelationships(['content'], {});


function MainGameCompare(props) {
  const {
    topics,
    isMobile,
    theme,
  } = props;

  if (!topics || !topics.length) return null;

  const content = topics[0];

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
    topic_type: topicType,
  } = content.attributes;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  const pair = getPair(widgets, topicType);

  return (
    <>
      <Divider />
      <WideWrapper>
        <div className={styles.gameCompare}>
          <style jsx>{`
            .${styles.subtitle}
              :global(.mobile) &
                color ${theme.colors.grey2}
          `}</style>
          <Link
            to={link}
            type='primary'
            className={styles.link}
          >
            <div className={styles.header}>
              <h2 className={styles.title}>{listHeadline || headline}</h2>
              {altHeadline && <p className={styles.subtitle}>{altHeadline}</p>}
            </div>
            <div className={styles.vs}>
              {pair.map((couple, i) => {
                const {
                  image: {
                    attributes: {
                      versions: {
                        original: originalCover,
                        thumbnail: previewCover,
                      },
                    },
                  },
                } = couple.attributes;

                return (
                  <Fragment key={couple.id}>
                    <div className={styles[`wrapper_${i}`]}>
                      <SmartImage
                        src={originalCover}
                        previewSrc={previewCover}
                        maxWidth={isMobile ? 120 : 167}
                        aspectRatio={1.5}
                        instant
                      />
                    </div>
                    {!i && (
                      <div className={styles.icon}>
                        <Vs width={isMobile ? 30 : 50} />
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </Link>
          <Link
            to='/ratings'
            type='secondary'
            className={styles.othersLink}
          >
            Другие сравнения
          </Link>
        </div>
      </WideWrapper>
    </>
  );
}

MainGameCompare.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(MainGameCompare));
