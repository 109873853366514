import PropTypes from 'prop-types';
import color from 'core/libs/color';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import styles from './index.styl';

function Title({ children, isActive, theme, handleClick }) {
  const {
    colors: {
      primary,
      active1,
    },
    fonts,
  } = theme;

  return (
    <h2 className={cx(styles.title, isActive && '_isActive')} {...!isActive && { onClick: handleClick }}>
      <style jsx>{`
        .${styles.title}
          font 400 34px/44px ${fonts.display}
          color ${active1}
          border-bottom 1px dotted ${color(active1).alpha(0.4).string()}
          transition border-color ${theme.animations.hover} .15s, color ${theme.animations.hover} .15s

          &._isActive
            color ${primary}
      `}</style>
      <style jsx>{`
        .${styles.title}
          &:hover
          &._isActive
            border-bottom 1px dotted transparent

          &._isActive
            cursor default
      `}</style>
      {children}
    </h2>
  );
}

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  isActive: PropTypes.bool,
  theme: PropTypes.object,
  handleClick: PropTypes.func,
};

export default withTheme(Title);
