import PropTypes from 'prop-types';

import Link from 'site/components/Link';
import Image from 'core/components/SmartImage';
import Button from 'core/components/Button';
import Scroller from 'core/components/Scroller';

import styles from './index.styl';

export default function Collections({ collections }) {
  if (!collections || collections.length === 0) return null;

  return (
    <Scroller listClassName={styles.collections}>
      {collections.map(collection => {
        const { title, link, coverUrls } = collection.attributes;

        return (
          <Link
            key={collection.id}
            type='secondary'
            to={link}
            className={styles.collection}
          >
            <Button type='tab'>
              <Image
                alt={title}
                url={coverUrls.logo}
                width={24}
                height={24}
                instant
              />
              <span className={styles.title}>{title}</span>
            </Button>
          </Link>
        );
      })}
    </Scroller>
  );
}

Collections.propTypes = {
  collections: PropTypes.array,
};
