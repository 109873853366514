import PropTypes from 'prop-types';
import cx from 'classnames';

import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';

function GradientBackground({ theme, children, className }) {
  const { gradient2 } = theme.colors;

  return (
    <div className={cx('gradient', className)}>
      <style jsx>{`
        .gradient
          background-image ${gradient2}
      `}</style>
      {children}
    </div>
  );
}

GradientBackground.propTypes = {
  className: PropTypes.string,
  theme: themePropTypes(`{
    colors: {
      gradient2,
    }
  }`),
};

export default withTheme(GradientBackground);
