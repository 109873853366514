const boomerDic = [{
  word: 'Получка',
  definition: '— то же самое, что и зарплата',
},
{
  word: 'Лайба',
  definition: '— дорогой автомобиль, производное от слова label (бренд), в СССР так назвали иномарки (совр. тачка)',
},
{
  word: 'Шухер',
  definition: '— слово, выражающее опасность / сигнал тревоги / предупреждение (совр. палево)',
},
{
  word: 'Шпана',
  definition: '— уничижительное название мелких жуликов или хулиганов / дети-беспризорники',
},
{
  word: 'Хаер',
  definition: '— волосы, прическа. Обычно неформального внешнего вида',
},
{
  word: 'Фуфайка',
  definition: '— теплая нательная одежда с длинным рукавом, преимущественно стеганая и ватная',
},
{
  word: 'Бить баклуши',
  definition: '— бездельничать / бесполезно проводить время (совр. чиллить)',
},
{
  word: 'Айда',
  definition: '— то же самое, что глагол “пойдем”, выражает призыв к началу движения (совр. погнали / го)',
},
{
  word: 'Атас',
  definition: '— оценка конкретной ситуации, выражающая крайне негативное состояние (совр. жесть / капец)',
},
{
  word: 'Бормотуха',
  definition: '— изначально дешевое низкокачественное вино, позже любой низкокачественный спиртной напиток',
},
{
  word: 'Закатки',
  definition: '— законсервированные в банках продукты. Чаще - овощи',
},
{
  word: 'Спекулянт',
  definition: '— человек продающий товары по завышенной цене (совр. перекупщик / ресейлер)',
},
{
  word: 'Толкучка',
  definition: '— барахолка / место, где торгуют подержанными вещами',
},
{
  word: 'Халтура',
  definition: '— побочный одноразовый мелкий заработок, помимо основного',
},
{
  word: 'Фофан',
  definition: '— сильный щелбан, осуществляемой оттяжкой среднего пальца',
},
{
  word: 'Салага',
  definition: '— обозначение неумелого или неопытного в каком-то вопросе человека (совр. нуб)',
},
{
  word: 'Точить лясы',
  definition: '— заниматься пустыми разговорами / сплетничать / болтать (совр. базарить)',
},
{
  word: 'Драпать',
  definition: '— убегать, спасаться бегством (совр. сваливать)',
},
{
  word: 'Квиток',
  definition: '— то же, что и квитанция',
}];

const zoomerDic =
[{
  word: 'Краш',
  definition: '— человек, который очень нравится / предмет обожания или влюбленности',
},
{
  word: 'Скуф',
  definition: '— пренебрежительное прозвище неуспешных мужчин в возрасте за 35 лет, которые не следят за своим внешним видом, а в свободное время пьют хмельные напитки, смотрят телевизор или играют в компьютерные игры',
},
{
  word: 'Свайп',
  definition: '— протяжный жест пальца при управлении сенсорным экраном',
  mark: 'pink',
},
{
  word: 'Имба',
  definition: '— производное от английского Imbalance (дисбаланс), в сленге означает нечто очень хорошее, впечатляющее',
},
{
  word: 'Чиллить',
  definition: '— отдыхать, расcлабляться, производное от английского chill',
},
{
  word: 'Кринж',
  definition: '— состояние стыда за чужие действия / реакция на неуместные, иногда позорные, действия или шутки других людей',
},
{
  word: 'Чиназес',
  definition: '— слово без перевода, означающее положительную эмоцию, когда что-то получилось или удалось достичь позитивного результата',
},
{
  word: 'Иконка',
  definition: '— небольшая картинка / изображение, обозначающее какой-либо файл, приложение или ярлык',
},
{
  word: 'Тильт',
  definition: '— состояние эмоциональной апатии, грусти и раздражения, вызванной каким-то внешним фактором',
},
{
  word: 'Анк',
  definition: '— сокращение от слова uncle (дядя), означающее человека старшего возраста, который пытается казаться моложе, используя сленг, интересуясь современными трендами молодого поколения, стараясь быть на “одной волне”, что зачастую выглядит нелепо',
},
{
  word: 'Нормис',
  definition: '— человек, поддерживающий стандартные общепринятые взгляды, интересы, мнения. Не выделяется из общей массы людей',
},
{
  word: 'Тегнуть',
  definition: '— отметить человека в чате / посте, с помощью специальной команды с использованием знака “@”',
},
{
  word: 'Буллинг',
  definition: '— публичное издевательство, агрессивное преследование, травля конкретного человека',
  mark: 'green',
},
{
  word: 'Клик',
  definition: '— щелчок / короткое нажатие клавиши компьютерной мыши по определенному элементу',
},
{
  word: 'Фейк',
  definition: '— нечто ложное, недостоверное, фальсифицированное, выдаваемое за правдивое и настоящее',
},
{
  word: 'Вайб',
  definition: '— то же, что и атмосфера / общее настроение',
},
{
  word: 'Скам',
  definition: '— афера / мошенничество в интернете',
},
{
  word: 'Хейтер',
  definition: '— то же, что и ненавистник; зачастую относится к комментаторам, выражающим свое презрение к определенным публичным личностям в сети',
},
{
  word: 'Тап',
  definition: '— нажать на сенсорный экран устройства',
}];


const boomerDicMobile = [{
  word: 'Получка',
  definition: '— то же самое, что и зарплата',
},
{
  word: 'Хаер',
  definition: '— волосы, прическа. Обычно неформального внешнего вида',
},
{
  word: 'Фуфайка',
  definition: '— теплая нательная одежда с длинным рукавом, преимущественно стеганая и ватная',
},
{
  word: 'Бить баклуши',
  definition: '— бездельничать / бесполезно проводить время (совр. чиллить)',
},
{
  word: 'Айда',
  definition: '— то же самое, что глагол “пойдем”, выражает призыв к началу движения (совр. погнали / го)',
},
{
  word: 'Бормотуха',
  definition: '— изначально дешевое низкокачественное вино, позже любой низкокачественный спиртной напиток',
},
{
  word: 'Халтура',
  definition: '— побочный одноразовый мелкий заработок, помимо основного',
},
{
  word: 'Фофан',
  definition: '— сильный щелбан, осуществляемой оттяжкой среднего пальца',
},
{
  word: 'Салага',
  definition: '— обозначение неумелого или неопытного в каком-то вопросе человека (совр. нуб)',
},
{
  word: 'Точить лясы',
  definition: '— заниматься пустыми разговорами / сплетничать / болтать (совр. базарить)',
}];


const zoomerDicMobile = [{
  word: 'Краш',
  definition: '— человек, который очень нравится / предмет обожания или влюбленности',
},
{
  word: 'Свайп',
  definition: '— протяжный жест пальца при управлении сенсорным экраном',
  mark: 'pink',
},
{
  word: 'Кринж',
  definition: '— состояние стыда за чужие действия / реакция на неуместные, иногда позорные, действия или шутки других людей',
},
{
  word: 'Иконка',
  definition: '— небольшая картинка / изображение, обозначающее какой-либо файл, приложение или ярлык',
},
{
  word: 'Тильт',
  definition: '— состояние эмоциональной апатии, грусти и раздражения, вызванной каким-то внешним фактором',
},
{
  word: 'Тегнуть',
  definition: '— отметить человека в чате / посте, с помощью специальной команды с использованием знака “@”',
},
{
  word: 'Клик',
  definition: '— щелчок / короткое нажатие клавиши компьютерной мыши по определенному элементу',
},
{
  word: 'Фейк',
  definition: '— нечто ложное, недостоверное, фальсифицированное, выдаваемое за правдивое и настоящее',
},
{
  word: 'Вайб',
  definition: '— то же, что и атмосфера / общее настроение',
},
{
  word: 'Скам',
  definition: '— афера / мошенничество в интернете',
},
];

export { zoomerDic, boomerDic, boomerDicMobile, zoomerDicMobile };
