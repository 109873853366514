import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import TextBlock from '../TextBlock';

import Location from '../images/Location';

import imageDesktop from '../images/phones_image_desktop.jpg';
import imageMobile from '../images/phones_image_mobile.jpg';

import styles from './index.styl';


function Era({ theme, isMobile }) {
  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
      .${styles.mainTitle}
      .${styles.title}
      .${styles.announce}
        font-family ${theme.fonts.likhie}
    `}</style>
    </scope>
  );

  const mainTitle = (
    <div className={scope.wrapClassNames(styles.mainTitle)}>
      Телефонные <br /> будки, <br /> которые напомнят про <div className={styles.year}>90-Е</div>
    </div>
  );

  return (
    <div className={styles.era}>
      <div className={styles.eraContent}>
        <div className={styles.teaser}>
          <div className={scope.wrapClassNames(styles.title)}>Погрузитесь в эпоху «Лихих»</div>
          <div className={scope.wrapClassNames(styles.announce)}>Проекты, которые напомнят атмосферу 90-х</div>
        </div>

        {isMobile && mainTitle}

        <div className={styles.main}>
          {!isMobile && mainTitle}

          <div className={styles.eraImage}>
            <img src={isMobile ? imageMobile : imageDesktop} alt='' />
          </div>

          <TextBlock className={styles.description}>
            <p>
              На улицах Москвы мы установили телефонные будки, как в 90-х. Любой желающий может прийти и сфотографироваться с ними.
            </p>
            <p>
              Внутри – рассказ об интересных событиях той эпохи. Набирайте любой год с 1990 по 2001-й и слушайте о самых важных моментах десятилетия
            </p>

            <ul className={styles.addresses}>
              <li className={styles.adress}><Location /> Площадь Революции</li>
              <li className={styles.adress}><Location /> Тверская площадь</li>
              <li className={styles.adress}><Location /> Новый Арбат</li>
            </ul>
          </TextBlock>

        </div>
      </div>
      <scope.styles />
    </div>
  );
}

Era.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withTheme(withBreakpoint(Era));
