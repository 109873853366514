import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import Link from 'core/components/Link';
import EmptyWrapper from 'core/components/EmptyWrapper';

import styles from './index.styl';

function Button(props) {
  const {
    link,
    iconBefore,
    iconAfter,
    children,
    theme,
    size,
    onClick,
  } = props;

  const Wrapper = link ? Link : EmptyWrapper;

  return (
    <Wrapper to={link} type='blank'>
      <style jsx>{`
        .${styles.buttonText}
          font-family ${theme.fonts.likhie}
      `}</style>

      <button
        {...onClick && { onClick: onClick }}
        className={cx(
          styles.button,
          size === 's' && styles._size_s
        )}
      >
        <span className={styles.buttonContent}>
          {iconBefore}
          <span className={styles.buttonText}>
            {children}
          </span>
          {iconAfter}
        </span>
      </button>
    </Wrapper>
  );
}

Button.defaultProps = {
  size: 'm',
};

Button.propTypes = {
  theme: PropTypes.object,
  link: PropTypes.string,
  iconBefore: PropTypes.node,
  iconAfter: PropTypes.node,
  size: PropTypes.oneOf(['m', 's']),
  onClick: PropTypes.func,
};

export default withTheme(Button);
