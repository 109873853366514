import PropTypes from 'prop-types';

import MarkdownWrapper from 'core/components/MarkdownWrapper';

import CardAuthorOpinion from 'site/cards/CardAuthorOpinion';

import styles from './index.styl';


export default function Opinion(props) {
  const {
    block,
    parentProps,
  } = props;

  const {
    theme: {
      colors: colorsAtoms,
      texts: {
        body: bodyAtoms,
      },
    },
  } = parentProps;

  if (!block) return null;

  const {
    author,
    body,
  } = block.attributes;

  return (
    <div className={styles.wrapper}>
      <style jsx>{`
      .${styles.wrapper}
        background ${colorsAtoms.accent2}
      .${styles.quote}
        font ${bodyAtoms.font}
    `}
      </style>
      <div className={styles.quote}>
        <MarkdownWrapper>
          {body}
        </MarkdownWrapper>
      </div>
      <CardAuthorOpinion content={author} />
    </div>
  );
}

Opinion.propTypes = {
  parentProps: PropTypes.object,
  block: PropTypes.object,
};
