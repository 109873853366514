import { useCallback } from 'react';
import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import Link from 'site/components/Link';
import Button from 'core/components/Button';

import { callYaMetrikaMethod } from 'core/utils/counters-helper';

const relationships = resolveRelationships(['movie_meta'], {}, {});
const utm = '?utm_medium=smm&utm_source=blog&utm_campaign=movies&_ga=2.236677257.96612354.1688392134-274213479.1670949243';


function MovieAvailabilityButton({ className, movie }, { counterIds }) {
  const sendGoal = useCallback(() => {
    callYaMetrikaMethod(counterIds.yandexMetrika, 'reachGoal', 'GTM_smotret_blog');
  }, [counterIds]);

  if (!movie?.attributes?.source_url) return null;

  const { source_url: sourceUrl } = movie.attributes;

  const {
    movie_meta: {
      availability,
    },
  } = relationships(movie);

  return (
    <Link
      to={sourceUrl + utm}
      onClick={() => availability && sendGoal()}
      className={className}
    >
      <Button>{availability ? 'Смотреть' : 'Подробнее'}</Button>
    </Link>
  );
}

MovieAvailabilityButton.contextTypes = {
  counterIds: PropTypes.object,
};

MovieAvailabilityButton.propTypes = {
  /** Имя класса для дополнительной стилизации */
  className: PropTypes.string,
  /**
   * Топик типа movie
   */
  movie: PropTypes.object,
};

export { MovieAvailabilityButton as StorybookComponent };

export default MovieAvailabilityButton;
